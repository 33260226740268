import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class HelperService {
  private _extensions: any = {
    archive: [
      '7z',
      'a',
      'apk',
      'ar',
      'bz2',
      'cab',
      'cpio',
      'deb',
      'dmg',
      'egg',
      'gz',
      'iso',
      'jar',
      'lha',
      'mar',
      'pea',
      'rar',
      'rpm',
      's7z',
      'shar',
      'tar',
      'tbz2',
      'tgz',
      'tlz',
      'war',
      'whl',
      'xpi',
      'zip',
      'zipx',
      'xz',
      'pak'
    ],
    audio: [
      'aac',
      'aiff',
      'ape',
      'au',
      'flac',
      'gsm',
      'it',
      'm3u',
      'm4a',
      'mid',
      'mod',
      'mp3',
      'mpa',
      'pls',
      'ra',
      's3m',
      'sid',
      'wav',
      'wma',
      'xm'
    ],
    book: [
      'mobi',
      'epub',
      'azw1',
      'azw3',
      'azw4',
      'azw6',
      'azw',
      'cbr',
      'cbz'
    ],
    code: [
      '1.ada',
      '2.ada',
      'ada',
      'adb',
      'ads',
      'asm',
      'bas',
      'bash',
      'bat',
      'c',
      'c++',
      'cbl',
      'cc',
      'class',
      'clj',
      'cob',
      'cpp',
      'cs',
      'csh',
      'cxx',
      'd',
      'diff',
      'e',
      'el',
      'f',
      'f77',
      'f90',
      'fish',
      'for',
      'fth',
      'ftn',
      'go',
      'groovy',
      'h',
      'hh',
      'hpp',
      'hs',
      'html',
      'htm',
      'hxx',
      'java',
      'js',
      'jsx',
      'jsp',
      'ksh',
      'kt',
      'lhs',
      'lisp',
      'lua',
      'm',
      'm4',
      'nim',
      'patch',
      'php',
      'pl',
      'po',
      'pp',
      'py',
      'r',
      'rb',
      'rs',
      's',
      'scala',
      'sh',
      'swg',
      'swift',
      'v',
      'vb',
      'vcxproj',
      'xcodeproj',
      'xml',
      'zsh'
    ],
    exec: [
      'exe',
      'msi',
      'bin',
      'command',
      'sh',
      'bat',
      'crx',
      'bash',
      'csh',
      'fish',
      'ksh',
      'zsh'
    ],
    font: [
      'eot',
      'otf',
      'ttf',
      'woff',
      'woff2'
    ],
    image: [
      '3dm',
      '3ds',
      'max',
      'bmp',
      'dds',
      'gif',
      'jpg',
      'jpeg',
      'png',
      'psd',
      'xcf',
      'tga',
      'thm',
      'tif',
      'tiff',
      'yuv',
      'ai',
      'eps',
      'ps',
      'svg',
      'dwg',
      'dxf',
      'gpx',
      'kml',
      'kmz',
      'webp'
    ],
    sheet: [
      'ods',
      'xls',
      'xlsx',
      'csv',
      'ics',
      'vcf'
    ],
    slide: [
      'ppt',
      'odp'
    ],
    text: [
      'doc',
      'docx',
      'ebook',
      'log',
      'md',
      'msg',
      'odt',
      'org',
      'pages',
      'pdf',
      'rtf',
      'rst',
      'tex',
      'txt',
      'wpd',
      'wps'
    ],
    video: [
      '3g2',
      '3gp',
      'aaf',
      'asf',
      'avchd',
      'avi',
      'drc',
      'flv',
      'm2v',
      'm4p',
      'm4v',
      'mkv',
      'mng',
      'mov',
      'mp2',
      'mp4',
      'mpe',
      'mpeg',
      'mpg',
      'mpv',
      'mxf',
      'nsv',
      'ogg',
      'ogv',
      'ogm',
      'qt',
      'rm',
      'rmvb',
      'roq',
      'srt',
      'svi',
      'vob',
      'webm',
      'wmv',
      'yuv'
    ],
    web: [
      'html',
      'htm',
      'css',
      'js',
      'jsx',
      'less',
      'scss',
      'wasm',
      'php'
    ]
  }

  public get extensions() {
    return this._extensions;
  }

  private _machineType = {
    MACHINE_CLASS_LOADER_EXCAVATOR: 'ft_machine_excavator',
    MACHINE_CLASS_LOADER_WHEELLOADER: 'ft_machine_wheelloader',
    MACHINE_CLASS_TRUCK: 'ft_machine_truck',
    MACHINE_CLASS_TRUCK_LKW_RIGID: 'ft_machine_truck',
    MACHINE_CLASS_TRUCK_DUMPER: 'ft_machine_dumper',
    MACHINE_CLASS_TRUCK_DUMPER_RIGID: 'ft_machine_dumper',
    MACHINE_CLASS_TRUCK_DUMPER_ARTICULATED: 'ft_machine_articulated_truck',
    MACHINE_CLASS_TRUCK_LKW_ARTICULATED: 'ft_machine_articulated_truck',
    // MACHINE_CLASS_MOBICAM: 'ft_machine_crane+cam',
  }

  private _camMachineType = {
    'ft_machine_excavator': 'MACHINE_CLASS_LOADER_EXCAVATOR',
    'ft_machine_excavator+cam': 'MACHINE_CLASS_LOADER_EXCAVATOR',
    'ft_machine_wheelloader': 'MACHINE_CLASS_LOADER_WHEELLOADER',
    'ft_machine_wheelloader+cam': 'MACHINE_CLASS_LOADER_WHEELLOADER',
    'ft_machine_truck': 'MACHINE_CLASS_TRUCK',
    'ft_machine_truck+cam': 'MACHINE_CLASS_TRUCK',
    'ft_machine_dumper': 'MACHINE_CLASS_TRUCK_DUMPER',
    'ft_machine_dumper+cam': 'MACHINE_CLASS_TRUCK_DUMPER',
    'ft_machine_articulated_truck': 'MACHINE_CLASS_TRUCK_DUMPER_ARTICULATED',
    'ft_machine_articulated_truck+cam': 'MACHINE_CLASS_TRUCK_DUMPER_ARTICULATED',
    // 'ft_machine_crane+cam': 'MACHINE_CLASS_MOBICAM',
  }

  public get machineType() {
    return this._machineType;
  }

  public get camMachineType() {
    return this._camMachineType;
  }

  public toAbautDateFormat(date: Date) {
    return date.getUTCFullYear().toString()
      + ((date.getUTCMonth() + 1) < 10 ? '0' + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString())
      + (date.getUTCDate() < 10 ? '0' + date.getUTCDate().toString() : date.getUTCDate().toString())
      + 'T'
      + (date.getUTCHours() < 10 ? '0' + date.getUTCHours().toString() : date.getUTCHours().toString())
      + (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes().toString() : date.getUTCMinutes().toString())
      + (date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds().toString() : date.getUTCSeconds().toString())
      + 'Z';
  }

  public getSiteNameById(sitesWithConfig, siteID) {
    return sitesWithConfig.find(site => site.site_id === siteID).site_name
  }

}
