
<div class="plan-container-large">
    <mat-progress-bar  *ngIf="isLoading" class="red-progress-color"  mode="indeterminate"></mat-progress-bar>
    <!-- <mat-spinner *ngIf="isLoading"  mode="indeterminate"></mat-spinner> -->
<div id="imageplanOL" class="imageplanOLDiv">
</div>
<!-- <div  *ngIf="currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'" id="" class="item2">
    <button (click)="routeToPlanComponent('lageplan')" type="button">
      <img src="assets/flaticon_32x32/015-analytics-1.png">
    </button>
</div> -->

<div  *ngIf="currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'" id="" class="mobile-plan flex-controller control-div-bottom">
  
  <div class="ol-custom1 ol-unselectable ol-control">
    <button (click)="routeToPlanComponent('lageplan')" type="button">
          <mat-icon aria-hidden="false" aria-label="satellite_alt icon">satellite_alt</mat-icon>
    </button>

  </div>
</div>
 </div>

