<div class="pageImage">
  <div *ngIf="isLoading == true" class="progress-item">
    <mat-spinner *ngIf="isLoading == true"  [ngClass]="{'red-progress': currentScreenMode==='desktopScreen', 'red-progress-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}" mode="indeterminate"></mat-spinner>
  </div>

  <!--  Error Outlay-->
  <div *ngIf="isError" class="error-container">
    <div class="center-error">
      <div class="error">
        <div class="number">4</div>
        <div class="illustration">
          <div class="circle"></div>
          <div class="clip">
            <div class="paper">
              <div class="face">
                <div class="eyes">
                  <div class="eye eye-left"></div>
                  <div class="eye eye-right"></div>
                </div>
                <div class="rosyCheeks rosyCheeks-left"></div>
                <div class="rosyCheeks rosyCheeks-right"></div>
                <div class="mouth"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="number">{{errorCode}}</div>
      </div>

      <div class="text"><h2><strong>{{errorText}}</strong></h2></div>
      <!--      <a class="button" href="#">Back Home</a>-->
    </div>
  </div>

  <!--  Image iFRAME -->
  <div *ngIf="!isError"  [ngClass]="{'spinner_overlay': isLoading == true}">


    <div class="wrapper">

      <!--    <div (click)="back()" class="flex-controller control-div-top">-->
      <!--      <div class="ol-custom ol-unselectable ol-control">-->
      <!--        <img class="back" src="./assets/back.png" alt="Back"/>-->
      <!--      </div>-->
      <!--    </div>-->

      <div id="info_cardboard" [ngClass]="'item1'">
        <!-- <div *ngIf="infoBoxVisible" id="info_cardboard" class="item1"> -->
        <!-- <div class="second_element"> -->
        <div [ngClass]="'second_element'">
          <h5>{{ 'txtSV_information_panel_title' | translate }}:</h5>
          <table>
            <tr>
              <td>{{ 'txtSV_information_panel_time' | translate }}:</td>
              &nbsp;
              <td>{{ timestamp_formated }}</td>
            </tr>
            <tr>
              <td>{{ 'txtSV_information_panel_lat' | translate }}:</td>
              &nbsp;
              <td>{{ image_lat }}</td>
            </tr>
            <tr>
              <td>{{ 'txtSV_information_panel_long' | translate }}:</td>
              &nbsp;
              <td>{{ image_lng }}</td>
            </tr>

            <!-- <tr>
                  <td>Site ID:</td>
                  &nbsp;
                  <td>{{ siteID }}</td>
                </tr>


                <tr>
                  <td>Camera ID:</td>
                  &nbsp;
                  <td>{{ camID }}</td>
                </tr> -->


          </table>
        </div>
      </div>

      <div #galleryElement id="galleryElement" class="item0-full">

        <!-- image gallery -->
        <div id="imageMap" class="map"></div>
      </div>
      <div  #sliderElement id="sliderElement"
            [ngClass]="'item1-full'">
        <!-- slider-->
        <app-time-slider
                         [timeStamp]="timeStamp"
                         [ListOfImagesForADay]="ListOfImagesForADay"
                         (currentImageUrlEventForImageGallery)="currentImageUrlEventForImageGallery($event)"
                         (currentImageUrlEventForPanoView)="currentImageUrlEventForPanoView($event)"></app-time-slider>
        <!-- TimeMessage: {{time}} -->
      </div>
      <div  #downloadElement id="downloadElement" class="item2-full">


        <div class="flexed">
          <div class="align-to-bottom ol-custom1 ol-unselectable ol-control">
            <button (click)="downloadImage()" class="button" type="button" title="Download Image">
              <div class="mat-icon">
                <img src="assets/flaticon_32x32/001download.png">
              </div>
            </button>

          </div>

            <app-date-calendar
              [timeStamp]="timeStamp"
              [listOfDatesToHighlight]="listOfDatesToHighlight"
                               (selectedCurrentCalenderDate)="receiveDateFromCalendar($event)">
            </app-date-calendar>

        </div>

        <!-- DateMessage: {{date}} -->
      </div>


<!--      <div *ngIf="currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'"  #downloadElement id="downloadElement"-->
<!--           [ngClass]="'item2-full'">-->


<!--        <div class="stackedButtons">-->
<!--          <app-date-calendar [listOfDatesToHighlight]="listOfDatesToHighlight"-->
<!--                             (selectedCurrentCalenderDate)="receiveDateFromCalendar($event)">-->
<!--          </app-date-calendar>-->
<!--        </div>-->

<!--        &lt;!&ndash; DateMessage: {{date}} &ndash;&gt;-->
<!--      </div>-->


    </div>

  </div>
</div>

