import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SiteAssociatedData {



    private _listImageMarkers = {};
    public get listImageMarkers() {
        return this._listImageMarkers;
    }
    public set listImageMarkers(value) {
        this._listImageMarkers = value;
    }

    private _groupedDataByPositionId = {};
    public get groupedDataByPositionId() {
        return this._groupedDataByPositionId;
    }
    public set groupedDataByPositionId(value) {
        this._groupedDataByPositionId = value;
    }

    private _machinePositions = [];
    public get machinePositions() {
      return this._machinePositions;
    }
    public set machinePositions(value) {
      this._machinePositions = value;
    }


    private _position_ids = [];
    public get position_ids() {
        return this._position_ids;
    }
    public set position_ids(value) {
        this._position_ids = value;
    }

    private _mergedConnectedGraphAllCameras = {};
    public get mergedConnectedGraphAllCameras() {
        return this._mergedConnectedGraphAllCameras;
    }
    public set mergedConnectedGraphAllCameras(value) {
        this._mergedConnectedGraphAllCameras = value;
    }
    private _mergedConnectedGraphPositionIds;
    public get mergedConnectedGraphPositionIds() {
        return this._mergedConnectedGraphPositionIds;
    }
    public set mergedConnectedGraphPositionIds(value) {
        this._mergedConnectedGraphPositionIds = value;
    }

    private _listCameras;
    private _listPlans;
    private _listPositionIds;
    private _listStatcams;
    private _otherCameraTypes;
    private _listPanoramaCams;
    private _listMobiCams;

    private _planMSProject;
    private _planSite;
    private _planTilos;


    private _planMSProjectExists;
    private _planSiteExists;
    private _planTilosExists;

    private _mapDrone;
    private _mapSurvey;
    private _mapShade;

    private _mapDroneExists;
    private _mapSurveyExists;
    private _mapShadeExists;

    private _listProcessingFiles;


    public get planMSProjectExists() {
        return this._planMSProjectExists;
    }
    public set planMSProjectExists(value) {
        this._planMSProjectExists = value;
    }
    public get planSiteExists() {
        return this._planSiteExists;
    }
    public set planSiteExists(value) {
        this._planSiteExists = value;
    }
    public get planTilosExists() {
        return this._planTilosExists;
    }
    public set planTilosExists(value) {
        this._planTilosExists = value;
    }


    public get planMSProject() {
        return this._planMSProject;
    }
    public set planMSProject(value) {
        this._planMSProject = value;
    }
    public get planSite() {
        return this._planSite;
    }
    public set planSite(value) {
        this._planSite = value;
    }
    public get planTilos() {
        return this._planTilos;
    }
    public set planTilos(value) {
        this._planTilos = value;
    }


    public get mapDroneExists() {
      return this._mapDroneExists;
    }
    public set mapDroneExists(value) {
      this._mapDroneExists = value;
    }
    public get mapShadeExists() {
      return this._mapShadeExists;
    }
    public set mapShadeExists(value) {
      this._mapShadeExists = value;
    }
    public get mapSurveyExists() {
      return this._mapSurveyExists;
    }
    public set mapSurveyExists(value) {
      this._mapSurveyExists = value;
    }


    public get mapDrone() {
      return this._mapDrone;
    }
    public set mapDrone(value) {
      this._mapDrone = value;
    }
    public get mapShade() {
      return this._mapShade;
    }
    public set mapShade(value) {
      this._mapShade = value;
    }
    public get mapSurvey() {
      return this._mapSurvey;
    }
    public set mapSurvey(value) {
      this._mapSurvey = value;
    }

    public get listMobiCams() {
        return this._listMobiCams;
    }
    public set listMobiCams(value) {
        this._listMobiCams = value;
    }

    public get listStatcams() {
        return this._listStatcams;
    }
    public set listStatcams(value) {
        this._listStatcams = value;
    }
    public get listPanoramaCams() {
        return this._listPanoramaCams;
    }
    public set listPanoramaCams(value) {
        this._listPanoramaCams = value;
    }

    public get otherCameraTypes() {
        return this._otherCameraTypes;
    }
    public set otherCameraTypes(value) {
        this._otherCameraTypes = value;
    }


    public get listCameras() {
        return this._listCameras;
    }
    public set listCameras(value) {
        this._listCameras = value;
    }


    public get listPlans() {
        return this._listPlans;
    }
    public set listPlans(value) {
        this._listPlans = value;
    }

    public get listPositionIds() {
        return this._listPositionIds;
    }
    public set listPositionIds(value) {
        this._listPositionIds = value;
    }

    public get listProcessingFiles() {
        return this._listProcessingFiles;
    }
    public set listProcessingFiles(value) {
        this._listProcessingFiles = value;
    }



}





















