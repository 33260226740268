import { Component, HostListener, Input } from '@angular/core';
import 'ol/ol.css';
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import Vector from 'ol/source/Vector';
import { getCenter } from 'ol/extent';
import { Icon, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { Feature, View } from 'ol';
import { Point } from 'ol/geom';
import { ActivatedRoute, Router } from '@angular/router';
import { applyToPoint, fromTriangles } from 'transformation-matrix';
import { CurrentUIStateService } from '../_services/current-UI-state.service';
import { defaults as defaultInteractions } from 'ol/interaction.js';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { UserInfoService } from '../_services/user-info.service';
import { environment } from 'src/environments/environment';
import ImageStatic from 'ol/source/ImageStatic';
import * as util from '../_helper/util'


@Component({
  selector: 'app-image-plan1',
  templateUrl: './image-plan1.component.html',
  styleUrls: ['./image-plan1.component.scss'],
})
export class ImagePlan1Component {

  @Input() planTypeInput = ''; // When opened from a parent component this comes from the parent as input.

  markerDataOL = [];
  map;
  imageURL;
  computedCenter;
  value_CurrentTimestamp;
  zoom;
  currentPositionID_currentDay_data: any;
  sub: any;
  planType;
  thePlanConfig;
  currentmapObj;
  specialExtent: any[];
  configDataSitePlan;
  configDataTilosPlan;
  configDataMSProjectPlan;
  computed_extent;
  imageLayer
  isLoading = true
  markerSource = new Vector();
  currentScreenMode


  constructor(private router: Router,
    public activatedRoute: ActivatedRoute,
    public currentUIStateService: CurrentUIStateService,
    public userInfoService: UserInfoService,
    private siteAssociatedData: SiteAssociatedData
  ) {


    this.configDataTilosPlan      = this.siteAssociatedData.planTilos
    this.configDataSitePlan       = this.siteAssociatedData.planSite
    this.configDataMSProjectPlan  = this.siteAssociatedData.planMSProject
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);

  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
  }




  computeCoords_WorldToPlan(computedMatrix, pointWorld) {
    let point_plans = applyToPoint(computedMatrix, pointWorld)
    return point_plans
  }


  computeMatrix(supporting_points) {
    // console.log("computeMatrix METHOD STARTS")

    if (supporting_points.length === 3) {
      let first_supp          = supporting_points[0]
      let sec_supp            = supporting_points[1]
      let third_supp          = supporting_points[2]
      let first_supp_world    = first_supp['world']
      let first_supp_plan     = first_supp['plan']
      let sec_supp_world      = sec_supp['world']
      let sec_supp_plan       = sec_supp['plan']
      let third_supp_world    = third_supp['world']
      let third_supp_plan     = third_supp['plan']
      let triangleWorld       = [first_supp_world, sec_supp_world, third_supp_world]
      let trianglePlan        = [first_supp_plan, sec_supp_plan, third_supp_plan]
      let matrix              = fromTriangles(triangleWorld, trianglePlan)

      return matrix
    }

    // Sometimes for example in case of MS project plan the supporting points do not exist
    else {
      let matrix = ""
      return matrix
    }

  }


  ngAfterViewInit() {
    this.isLoading = true

    if (this.map !== undefined) {
      this.map.updateSize();
    }

    // console.log("Image Plan componenet Starts !!!!!!!!!!!")

    // console.log("ngAfterViewInit was called")
    // Currently, their are two ways this component can be called or opened, One is the siteview opens it in split screen, other is it can be opened in full screen from the
    // side bar.
    // When siteview opens it -> it will send the planType as input param
    // When it is opened from the side bar planType comes from queryParams.
    //Currently, Their are three types of plans types which come as query params from the sidebar selection
    // "lageplan"
    // ""
    // "projectplan"


    // Condition will be triggered in case we read the plan type from the URL query params (we do this in case this component is opened from side bar)
    if (this.planTypeInput === '' || this.planTypeInput === undefined) {
      this.sub = this.activatedRoute
        .queryParams
        .subscribe(params => {
          // Defaults to 0 if no query param provided.
          this.planType = params['planType'];
          this.markerDataOL = []

          // To cleanly remove the old map you need to call map.setTarget(null) on the previous Map object.
          if (this.map !== undefined) {
            //Otherwise map keeps redrawing new layers in bottom
            this.map.setTarget(null)
            this.map.getLayers().getArray().slice().forEach(layer => {
              if (layer) {
                this.map.removeLayer(layer);
              }
            });
          }
          this.changeLayer(this.planType)
        });
    }

    // Condition will be triggered in case we read the plan type from the parent components input (we do this in case this component in Mini-Map)
    else {
      // Defaults to 0 if no query param provided.
      this.planType = this.planTypeInput
      this.markerDataOL = []

      // To cleanly remove the old map you need to call map.setTarget(null) on the previous Map object.
      if (this.map !== undefined) {
        //Otherwise map keeps redrawing new layers in bottom
        this.map.setTarget(null)
        this.map.getLayers().getArray().slice().forEach(layer => {
          if (layer) {
            this.map.removeLayer(layer);
          }
        });
      }
      this.changeLayer(this.planType)
    }
  }


  changeLayer(planTypeParam) {
    // console.log("CHANGE LAYER METHOD STARTS")
    // console.log(planTypeParam)

    switch (planTypeParam) {
      case "lageplan":
        this.thePlanConfig = this.configDataSitePlan
        this.currentUIStateService.currentMapLayer = "lageplan"
        console.log("lageplan")
        break;
      case "wegplan":
        this.thePlanConfig = this.configDataTilosPlan
        this.currentUIStateService.currentMapLayer = "wegplan"
        console.log("wegplan")
        break;
      case "projectplan":
        this.thePlanConfig = this.configDataMSProjectPlan
        this.currentUIStateService.currentMapLayer = "projectplan"
        console.log("projectplan")
        break;
      default:
        console.log("Could be an error : This plan type is not supported yet id: 9812187121")
        return
    }

    this.imageURL = environment.imageServerUrl + "/static_images/signature/" + this.userInfoService.siteId +  "/" + this.thePlanConfig['image_filename_signed']
    this.computePointsandDisplayPlan(planTypeParam)
  }


  computePointsandDisplayPlan(planTypeParam) {

    this.specialExtent = []
    this.map = null
    const img = new Image();
    img.src = this.imageURL;

    let that = this
    img.addEventListener("load", function () {

      that.setImageAsSourceInImageLayer(img, planTypeParam)
    });

  }


  setImageAsSourceInImageLayer(img, planTypeParam) {

    const vw = Math.max(document.documentElement.clientWidth  || 0, window.innerWidth  || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)


    this.computed_extent = [0, 0, img.naturalWidth, img.naturalHeight];
    console.log("🚀 ~ file: image-plan1.component.ts ~ line 235 ~ ImagePlan1Component ~ setImageAsSourceInImageLayer ~  this.computed_extent",  this.computed_extent)


    this.computeListofMarkers()

    // THIS part can be improved....
    const url = this.router.url;

    // this is component is loaded in the MINI map
    if (url.indexOf('siteview') > 0 || url.indexOf('imagegallery') > 0) {
      // console.log("xaaaa")
      if (planTypeParam == "projectplan") {
        // Project plan doesnt have any markers so their is no point in zooming it to a certain position.
        this.zoom = 0
      }
      else {
        this.zoom = 5.5
      }
    }

    // Set the center of the map, in case of a selected camera value set the center to that camera else set it to Camera 0 in list of cameras.
    if (this.userInfoService.currentlySelectedMarkerLon == undefined || this.userInfoService.currentlySelectedMarkerLon == '') {
      this.computedCenter = getCenter(this.computed_extent)
    }

    else {
      // console.log("xeeee")
      for (var i = 0; i < this.markerDataOL.length; i++) {
        if (this.markerDataOL[i]['id'] == this.userInfoService.currentlySelectedMarker) {
          const extent = [0, 0, this.markerDataOL[i]['pixelX'] * 2, this.markerDataOL[i]['pixelY'] * 2];
          this.computedCenter = getCenter(extent)
          break
        }
        else {
          // console.log("This should never be called in siteview url")
          this.computedCenter = getCenter(this.specialExtent)
        }
      }
    }

    this.imageLayer = new ImageLayer()

    //TODO map is missing View.
    // Map views always need a projection.  Here we just want to map image
    // coordinates directly to map coordinates, so we create a projection that uses
    // the image extent in pixels.
    const projection = new Projection({
      code: 'imsaage',
      units: 'ft',
      extent: [0, 0, img.naturalWidth , img.naturalHeight * 2],
      axisOrientation: 'ned'
    });


    let TestExtent
    switch (planTypeParam) {
      case "lageplan":
        TestExtent = [0, -img.naturalHeight, img.naturalWidth , img.naturalHeight * 2]
        // this.computedCenter = getCenter(TestExtent)

        break;
      case "wegplan":
        TestExtent = [0, 0, img.naturalWidth , img.naturalHeight]
        this.computedCenter = getCenter(TestExtent)

        break;
      case "projectplan":
        TestExtent = [0, 0, img.naturalWidth , img.naturalHeight]
        this.computedCenter = getCenter(TestExtent)

        break;
      default:
        console.log("Could be an error : This plan type is not supported yet id: 9812187121")
        return
    }



    this.map = new Map({
      // US: To only keep zoom button we initialize controls with an empty array.
      // controls: [],
      layers: [  this.imageLayer ],
      target: 'imageplanOL',
      view: new View({
        projection: projection,
        center: this.computedCenter,
        zoom: this.zoom,
        maxZoom: 10,
        minZoom: 0,
        // The extent here is responsible for constraining the view.
        extent: TestExtent,
      }),
      interactions: defaultInteractions({
        doubleClickZoom: true,
        dragPan: true,
        mouseWheelZoom: true,
        altShiftDragRotate:false,
        pinchRotate:false
      }),
    });

    this.imageLayer.setSource(
      new ImageStatic({
        url: img.src,
        projection: new Projection({  // Map views always need a projection.  Here we just want to map image coordinates directly to map coordinates, so we create a projection that uses
          code: img.src,
          units: "pixels",
          extent:this.computed_extent,
        }),
        imageExtent: this.computed_extent,
      }),
    );



    // TODO THIS part can be improved and all conditional code should be in the same place that depends on the URL or plantype Param
    // this is component is loaded in the MINI map
    if (url.indexOf('siteview') > 0 || url.indexOf('imagegallery') > 0) {
      console.log("xaaaa")
    }
    else {
      let view = this.map.getView();

      // In case the user had zoomed into a particualr area before, user should see the same area when sliding the image

     view.fit([0, 0, img.naturalWidth - 3, img.naturalHeight], { constrainResolution: false })
     //Important to set the Zoom later after the view ha sbeen git
     if (planTypeParam == "lageplan") {
        this.zoom = 0
        this.map.getView().setZoom(this.zoom);
      }

    }

    // Add Markers layer on Map
    this.map.addLayer(new VectorLayer({
      source: this.markerSource,
      extent: this.computed_extent,
      // style: iconStyle,
    }))


    //US: CSS Solution is not possible apparenlty
    this.map.on('pointermove', evt => {
      if (!evt.dragging) {
        this.map.getTargetElement().style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(evt.originalEvent)) ? 'pointer' : 'grab';
      }
    });


    for (var i = 0; i < this.markerDataOL.length; i++) {
      // Currently we have timestamp differentation in the Tilosplan only, so cannot use the same coniditon here for all plans.
      switch (planTypeParam) {
        case "lageplan":  // siteplan
          if (this.markerDataOL[i]['id'] == this.userInfoService.currentlySelectedMarker ) {
            this.addMarker(this.markerDataOL[i], true);
          }
          else {
            this.addMarker(this.markerDataOL[i], false);
          }
          break;
        case "wegplan": //tilosplan
          if (this.markerDataOL[i]['id'] == this.userInfoService.currentlySelectedMarker && this.markerDataOL[i]['timestamp'] == this.value_CurrentTimestamp) {
            this.addMarker(this.markerDataOL[i], true);
          }
          else {
            this.addMarker(this.markerDataOL[i], false);
          }
          break;
        case "projectplan":  // currently we dont display markers for project plan
          break;
        default:
          break;
      }
    }
    this.isLoading = false


    var that = this
    this.map.on('click', function (evt) {

      // This represents the Point that was clicked
      var feature = that.map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) { return feat;});

      if (feature && feature.get('all_data')['markerType'] == 'PANORAMACAM') {

        let data_AllDays_AllPositionId                            = that.siteAssociatedData.groupedDataByPositionId
        let data_AllDays_CurrentPositionId                        = data_AllDays_AllPositionId[feature.get('id')]
        that.userInfoService.currentlySelectedMarker              =   feature.get('id')
        that.userInfoService.currentlySelectedMarkerLon = feature.get('all_data')['lon']
        that.userInfoService.currentlySelectedMarkerLat = feature.get('all_data')['lat']

        that.userInfoService.currentlySelectedTimestamp           = feature.get('all_data')['timestamp']
        let iterations                                            = data_AllDays_AllPositionId[feature.get('id')].length;

        for (let el of data_AllDays_AllPositionId[feature.get('id')]) {
          if (el['timestamp'].substring(0, 10) === feature.get('all_data')['timestamp'].substring(0, 10)) {
            that.currentPositionID_currentDay_data = el
            break
          }
          // HACK AGAIN because of two differnt type of plans
          else {
            if (!--iterations)
              that.currentPositionID_currentDay_data = el
          }
        }

        //Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
        that.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        that.router.onSameUrlNavigation = 'reload';


        that.router.navigate(['siteview'], {
          state: {
            position_id_: feature.get('id'),
            data_AllDays_CurrentPositionId: data_AllDays_CurrentPositionId,
            data_AllDays_AllPositionId: data_AllDays_AllPositionId,
            currentPositionID_currentDay_data: that.currentPositionID_currentDay_data,
            split_state: true,
          }
        });
      }

    else if (feature && feature.get('all_data')['markerType'] == 'STATCAM' || feature && feature.get('all_data')['markerType'] == 'MOBICAM') {
        that.userInfoService.currentlySelectedMarker = feature.get('all_data')['id']
        that.userInfoService.currentlySelectedMarkerLon = feature.get('all_data')['lon']
        that.userInfoService.currentlySelectedMarkerLat = feature.get('all_data')['lat']
        // In future we will only use currently selected marker

        that.userInfoService.currentlySelectedMarker = feature.get('all_data')['id']
        that.router.navigate(['imagegallery'], {
          state: {}
        });

      }

      else {
        // console.log('it was not clicked on point')
      }
    });

    if (this.map !== undefined  && this.map !== null) {
      this.map.updateSize();
    }

  }


  computeListofMarkers()
  {
    let worldAxis1                = this.thePlanConfig['worldAxis1']
    let worldAxis2                = this.thePlanConfig['worldAxis2']
    let supporting_points         = this.thePlanConfig['supporting_points']
    // this.value_CurrentPositionId  = this.userInfoService.currentlySelectedMarker
    this.value_CurrentTimestamp   = this.userInfoService.currentlySelectedTimestamp

    // The tilos plan has  a time axis which means it can show multiple image markers per position id at different times.
    // However site plan is different. So here we construct differetn list structs appropirate for each plan.

    let imageListForTilosPlan   = []
    let imageListForOtherPlans  = []     // Only contains hte latest image per position ID
    let iterationListToBeUsed   = []
    let data_groupedDataByPositionId = this.siteAssociatedData.groupedDataByPositionId

    if (data_groupedDataByPositionId !== null && data_groupedDataByPositionId !== undefined) {

      // We iterate the data structure data_groupedDataByPositionId and store all the images in a list at the same level.
      Object.keys(data_groupedDataByPositionId).forEach(function (groupedDataKey) {
        imageListForOtherPlans
        var arr = data_groupedDataByPositionId[groupedDataKey]
        arr.forEach(groupedDataElement => {
          var item = groupedDataElement
          imageListForTilosPlan.push(item)
          if (item === arr[arr.length - 1]) {
            imageListForOtherPlans.push(item)
          }
        })
      })
    }

    console.log("The Plan ID that came from the Backend Config files:")
    console.log(this.thePlanConfig['Plan_ID'])
    // console.log("")
    // planSite1_smartphone
    // let IterationList
    if (this.thePlanConfig['Plan_ID'] === "planTilos1") {
      iterationListToBeUsed = imageListForTilosPlan
    }
    else if (this.thePlanConfig['Plan_ID'] === "planSite1" || this.thePlanConfig['Plan_ID'] === "planSite1_smartphone" ) {
      iterationListToBeUsed = imageListForOtherPlans
    }
    else if (this.thePlanConfig['Plan_ID'] === "planMSProject1") {
      iterationListToBeUsed = []
    }
    else {
      console.log("Could be an error : Empty Iteration list id: 981212143")
      iterationListToBeUsed = []
    }


    let computedMatrix = this.computeMatrix(supporting_points)


    console.log("🚀 ~ file: image-plan1.component.ts ~ line 574 ~ ImagePlan1Component ~ iterationListToBeUsed", iterationListToBeUsed)



    // This will compute a list of Markers for the Image Plan using the information we provide it
    iterationListToBeUsed.forEach(element => {

      let first_positon_lat   = element[worldAxis1]
      let first_position_lon  = element[worldAxis2]
      let pointWorld          = [first_positon_lat, first_position_lon]
      let result_func         = this.computeCoords_WorldToPlan(computedMatrix, pointWorld)
      console.log("🚀 ~ file: image-plan1.component.ts ~ line 542 ~ ImagePlan1Component ~ result_func", result_func)
      let myobj =
      {
        'id'          : element['position_id'],
        'pixelX'      : result_func[0],
        'pixelY'      : this.computed_extent[3] - result_func[1],
        'timestamp'   : null,
        'markerType'  :'PANORAMACAM',
        'lat'         : element['lat'],
        'lon'         : element['lon'],
        'timestamp_s' : element['timestamp_s'],
        'route_m'     : element['route_m'],
      }

      console.log("🚀 ~ file: image-plan1.component.ts ~ line 543 ~ ImagePlan1Component ~ myobj", myobj)

      //CONDITION because tilos-plan uses a different structure and site-plan usess a different
      if (element['timestamp'] === undefined) {
        myobj['timestamp'] = element['start_time']
      }
      else {
        myobj['timestamp'] = element['timestamp']
      }





      // Check if the markers lie within the image, In case they are outside the image extent we dont draw them

      if (myobj['pixelX'] >= this.computed_extent[0] && myobj['pixelY'] >= this.computed_extent[1]) {
        if (myobj['pixelX'] <= this.computed_extent[2] && myobj['pixelY'] <= this.computed_extent[3]) {
          this.markerDataOL.push(myobj)
        }
        else{
          console.log("MARKERS LIE OUTSIDE THE IMAGE EXTENT 1111")
          // console.log(myobj)
        }
      }

      else{
        console.log("MARKERS LIE OUTSIDE THE IMAGE EXTENT 222")
        // console.log(myobj)

      }
    });


    if (this.thePlanConfig['Plan_ID'] === "planSite1" || this.thePlanConfig['Plan_ID'] === "planSite1_smartphone") {
      // SitePlan has a special Condition, It will also display some other Markers (statCam, mobiCam etc etc). This information is not contained in the position_id data struct
      // but this comes from  a different place

      let newList = this.siteAssociatedData.listStatcams.concat(this.siteAssociatedData.listMobiCams)

      newList.forEach(element => {
        let first_positon_lat   = element[worldAxis1]
        let first_position_lon  = element[worldAxis2]
        let pointWorld          = [first_positon_lat, first_position_lon]
        let result_func         = this.computeCoords_WorldToPlan(computedMatrix, pointWorld)
        let myobj =
        {
          'id'        : element['cam_id'],
          'pixelX'    : result_func[0],
          'pixelY'    : this.computed_extent[3] - result_func[1],
          'markerType': element['cameraType'],
          'lat'         : element['lat'],
          'lon'         : element['lon'],
          'containsPanoramaImage'    : element['containsPanoramaImage'],
        }

        // Check if the markers lie within the image, Maybe this approach is correct, maybe not!
        if (myobj['pixelX'] >= this.computed_extent[0] && myobj['pixelY'] >= this.computed_extent[1]) {
          if (myobj['pixelX'] <= this.computed_extent[2] && myobj['pixelY'] <= this.computed_extent[3]) {
            this.markerDataOL.push(myobj)
          }
        }

      });
    }

  }


  addMarker(mapPin, boolCurrentlySelectedMarker) {

    let iconImage = ""
    // Our map can have differnt types of marker icons based on the point type. additonally The currently selected position ID will have a special Style.
    switch (mapPin['markerType']) {
      case "STATCAM":
        // console.log('this is element------------------------------>>>',element)
        if (boolCurrentlySelectedMarker == true) {
          // In case of the large Map we dont show the selected icon as red
          iconImage = './assets/flaticon/017-security-camera_red_new_smooth.png'
          //In case the stat cam contains Panorama Images
          if (mapPin['containsPanoramaImage'] == true) {
            iconImage = './assets/flaticon/020_dualcamera_red.png'
          }
        }
        else {
          iconImage = './assets/flaticon/017-security-camera_smooth.png'
          if (mapPin['containsPanoramaImage'] == true) {
            iconImage = './assets/flaticon/020_dualcamera.png'
          }
        }

        break;
      case "PANORAMACAM":
        iconImage = (boolCurrentlySelectedMarker === true) ? "./assets/flaticon/016-360-degrees_red_new_smooth.png" : "./assets/flaticon/016-360-degrees_smooth.png";
        break;
      case "MOBICAM":
        iconImage = (boolCurrentlySelectedMarker === true) ? "./assets/flaticon/018-mobicam_red_new_smooth.png" : "./assets/flaticon/018-mobicam_smooth.png";
        break;
      default:
        console.log("Could be an error : Break case was hit id: 981212121")
        return
    }
    let iconZindex= (boolCurrentlySelectedMarker === true) ? 10 : 5;

    // Create Feature... with coordinates
    var iconFeature = new Feature({
      geometry  : new Point([mapPin['pixelX'], mapPin['pixelY']]),
      id        : mapPin['id'],
      all_data  : mapPin,
    });

    // Create style for your feature...
    const iconStyle = [new Style({
      zIndex: iconZindex,
      image: new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity     : 1,
        src         : iconImage,
        scale       : 1.0,
      }),
    })]

    iconFeature.setStyle(iconStyle);
    this.markerSource.addFeature(iconFeature);
  }


  // This can be called by other components too to adjust map size
  olMapImageViewerUpdateSize() {
    if (this.map !== undefined && this.map !== null) {
      this.map.updateSize();
    }
  }

  routeToPlanComponent(planType)
  {
       // let data_AllDays_CurrentPositionId = that.data_AllDays_AllPositionId[feature.get('associatedData')['position_id']]
        //Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['dashboard'], { queryParams: {planType: planType} });
  }



}
