import { Routes, RouterModule } from '@angular/router';
 import { AuthGuard } from './_services/auth.guard';
import { ReportsComponent } from './reports/reports.component';
import { SiteviewComponent } from './siteview/siteview.component';
import { MapViewerComponent } from './map-viewer/map-viewer.component';
import { ImagePlan1Component } from './image-plan1/image-plan1.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TimelapseComponent } from './components/timelapse/timelapse.component';
import { TimelapseHistoryComponent } from './components/timelapse-history/timelapse-history.component';
import {ImageIframeComponent} from "./image-iframe/image-iframe.component";


const routes: Routes = [

  { path: '',                 component: DashboardComponent,      },
  { path: 'dashboard',        component: DashboardComponent,     },
  { path: 'iframe',           component: ImageIframeComponent    },
  { path: 'reports',          component: ReportsComponent,        canActivate: [AuthGuard]  },
  { path: 'siteview',         component: SiteviewComponent,       canActivate: [AuthGuard]  },
  { path: 'mapviewer',        component: MapViewerComponent,      canActivate: [AuthGuard]  },
  { path: 'imageplan',        component: ImagePlan1Component,     canActivate: [AuthGuard]  },
  { path: 'imagegallery',     component: ImageGalleryComponent,   canActivate: [AuthGuard]  },
  { path: 'top-bar',     component: TopBarComponent,              canActivate: [AuthGuard]  },
  { path: 'timelapse/:site_id/:cam_id',     component: TimelapseComponent,              canActivate: [AuthGuard]  },
  { path: 'timelapse-history/:site_id',     component: TimelapseHistoryComponent,              canActivate: [AuthGuard]  },


  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { enableTracing: false });

