import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserInfoService} from '../../_services/user-info.service';

@Component({
  selector: 'app-analytics-box',
  templateUrl: './analytics-box.component.html',
  styleUrls: ['./analytics-box.component.scss']
})
export class AnalyticsBoxComponent implements OnInit {

  analyticsLogoUrl = './assets/custom_icons/analytics.png'

  @Output() onClose = new EventEmitter<boolean>();

  constructor(private userInfoService: UserInfoService) {

  }

  ngOnInit(): void {
  }

  onCloseClick() {
      this.onClose.emit(false)
  }

  onClickMarketPlace() {
    window.open('https://marketplace.abaut.de/', 'MARKETPLACE');
  }

  onClickInfoAnalytics() {
    window.open('https://www.abaut.de/analytics', 'INFO_ANALYTICS');
  }

}
