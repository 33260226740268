<div [ngClass]="is_upload? 'upload-box':'info-box'" *ngIf="show" [class.upload_style] = "is_upload">
  <ng-container *ngIf="!is_upload">
    <img
      (click)="close()"
      class="close-info"
      src="../../../assets/close.png"
      alt="Schliessen"
    />
    <div class="info-img-container">
      <img class="error-icon" [src]="error ? sideIcon : mainIcon" alt="" />
      <img
        [class]="error ? 'error-icon' : 'erfolg-icon'"
        [src]="error ? mainIcon : sideIcon"
        alt=""
      />
    </div>
    <div class="info-text">
      <h6>{{ text }}</h6>
    </div>
  </ng-container>

  <ng-container *ngIf="is_upload">
    <div>
      <mat-spinner [diameter]="30" style="margin:auto"></mat-spinner> 
      <h6>{{'txtSV_upload_panel_proccessing_upload' | translate }}</h6>
    </div>
  </ng-container>
</div>
