import { TranslateService } from '@ngx-translate/core';
import {Component, ElementRef, OnInit} from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { UserInfoService } from '../_services/user-info.service';

@Component({
  selector: 'app-top-right-profile',
  templateUrl: './top-right-profile.component.html',
  styleUrls: ['./top-right-profile.component.scss'],
  host:  {
    '(document:click)': 'onClick($event)',
  },
})
export class TopRightProfileComponent implements OnInit {
  userProfileBox=false;
  username = ''
  profile = ''
  email = ''

  languages = [
    {flag:'de', code:'de'},
    {flag:'gb', code:'en'},
    {flag:'es', code:'sp'},
    {flag:'fr', code:'fr'},
    {flag:'it', code:'it'},
    {flag:'pt', code:'pr'},
    {flag:'ro', code:'ro'},
    {flag:'no', code:'no'},
    {flag:'pl', code:'pl'},
    {flag:'cz', code:'cz'},
  ]
  constructor(private authService: AuthService,
              private userInfoService: UserInfoService,
              private _eref: ElementRef,
              private translate: TranslateService
  ) {
    // Trims Username If it's more than 20 chars...!
    this.username = this.userInfoService.firstName + ' ' + this.userInfoService.lastName
    this.username = this.username.length > 20 ? this.username.substring(0,20) + '...'
      : this.username
    this.email = this.userInfoService.email
    // this.profile = this.username.split(' ').map(name => name[0]).join('').toUpperCase();
    this.profile = this.userInfoService.firstName[0].toUpperCase() +   this.userInfoService.lastName[0].toUpperCase()

  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
      this.userProfileBox = false
  }

  ngOnInit(): void {
  }

  onUserButtonClick() {
    this.userProfileBox = !this.userProfileBox
  }


  onLogout() {
    console.log('Log out clicked')
      this.authService.singleSignout()
  }

  // changelanguage(code){
  //   window.localStorage.setItem('lang', code);
  //   this.translate.use('siteView_'+code);
  // }

}
