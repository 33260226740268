<div class="flex-controller control-div-top">
  <div class="ol-custom ol-unselectable ol-control">
    <div class="first-group">
      <img (click)="onClickLogo()" src="./assets/custom_icons/abaut_logo.png" class="abaut-icon" alt="abaut-logo">
      <div class="selectContainer">
        <ng-select
          [items]="SITES"
          placeholder="Select Site"
          [(ngModel)]="selectedDevice"
          class="selectComponent"
          (change)="onChange($event)"
          [clearable]="false"
          [searchable]="false"
          bindLabel="name"
          bindValue="id"
        >
          <ng-template ng-option-tmp let-item="item" class="option-temp">
            <img height="17" width="17" [src]="item.id === selectedDevice ? './assets/custom_icons/construction_selected.png' : './assets/custom_icons/construction.png'"/>
            <span class="option-text">{{item.name}}</span>
          </ng-template>
        </ng-select>
    </div>
  </div>

    <div class="top-left-toolbar-separator"></div>
    <div class="second-group">
      <div class="top-left-button-div" (mouseover)="marketplaceLogo= marketplaceLogoRed" (mouseout)="marketplaceLogo= marketplaceLogoUrl" (click)="onClickMarketPlace()">
        <img [src]="marketplaceLogo" class="top-left-toolbar-button-icons">
        {{ 'txtSV_mainPage_button_marketplace' | translate }}
      </div>
      <div class="top-left-button-div" (mouseover)="analyticsLogo= analyticsLogoRed" (mouseout)="analyticsLogo= analyticsLogoUrl"  (click)="onClickAnalytics()">
        <img [src]="analyticsLogo" class="top-left-toolbar-button-icons analytics">
        {{ 'txtSV_mainPage_button_analytics' | translate }}
      </div>
    </div>
  </div>
</div>
