import { TranslateService } from '@ngx-translate/core';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UserInfoService } from '../_services/user-info.service';
import {debounceTime} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-top-left-toolbar',
  templateUrl: './top-left-toolbar.component.html',
  styleUrls: ['./top-left-toolbar.component.scss']
})
export class TopLeftToolbarComponent implements OnInit {

  @Output() analyticsNotExist = new EventEmitter<boolean>();

  SITES = [];
  selectedDevice = {};
  currentSiteID = null;
  specialKibanaAccess = false;
  marketplaceLogo = './assets/custom_icons/marketplace.png'
  marketplaceLogoUrl = './assets/custom_icons/marketplace.png'
  marketplaceLogoRed = './assets/custom_icons/marketplace_red.png'
  analyticsLogo = './assets/custom_icons/analytics.png'
  analyticsLogoUrl = './assets/custom_icons/analytics.png'
  analyticsLogoRed = './assets/custom_icons/analytics_red.png'

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private route: ActivatedRoute,
    private translate : TranslateService
  ) {
    this.SITES = this.buildSiteForContext(userInfoService.sitesWithConfig)
    this.currentSiteID = userInfoService.currentSiteId

    // initialize selected site by params or user info service
    this.route.queryParams.pipe(debounceTime(500)).subscribe(params => {
      console.log('Got Site with Params....!', params)
      if(params.site_id !== null &&  params.site_id !== '' && params.site_id !== undefined) {
        this.selectedDevice = params.site_id
      } else {
        this.selectedDevice = this.userInfoService.currentSiteId
      }
    });
  }

  ngOnInit(): void {}

  onClickMarketPlace() {
    window.open('https://marketplace.abaut.de/', 'MARKETPLACE');
  }

  onChange(event) {
    // console.log('-----------------onChange---------------------', event)
    if(event !== null) {
      this.router.navigate(['/'], {
        queryParams: {site_id: event.id}
      }).then(r => {
        this.currentSiteID = event.id
        this.userInfoService.currentSiteId = event.id
        window.location.reload();
      });
    }
  }

  buildSiteForContext = (sitesWithConfig) => {
    const sites = sitesWithConfig.map((site) => {
      return {
        id: site.site_id,
        name: site.site_name
      }
    })
    return sites.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  onClickLogo() {
    window.open(environment.abautHub, 'ABAUT_HUB');
  }

  onClickAnalytics() {
    // const url = this.getKibanaSpecialUrl()
    let kibanaURL = 'https://analytics-oidc.abaut.de/s/';

    const specialAccounts: Array<string> = this.userInfoService.kibanaSpecialAccounts;
    this.specialKibanaAccess = specialAccounts.includes(this.currentSiteID);
    if(this.specialKibanaAccess){
      const index: number = this.userInfoService.kibanaSpecialAccounts.indexOf(this.currentSiteID);
      const specialURls = this.userInfoService.kibanaSpecialURL;
      kibanaURL = specialURls[index];
    }
    else{
      if(this.currentSiteID === null) {
        kibanaURL = kibanaURL + this.userInfoService.siteId.toLocaleLowerCase();
      } else {

        kibanaURL = kibanaURL + this.currentSiteID.toLocaleLowerCase();
      }
    }
    const url = kibanaURL

    if (this.userInfoService.kibanaAccess){
      console.log('Analytics clicked......', url, this.currentSiteID, this.userInfoService.siteId)
      window.open(url, 'kibana_' + this.currentSiteID);
    }else{
      this.analyticsNotExist.emit(true)
    }


  }

  // getKibanaSpecialUrl(): string{
  //   const specialAccounts: Array<string> = this.userInfoService.kibanaSpecialAccounts;
  //   this.specialKibanaAccess = specialAccounts.includes(this.currentSiteID);
  //   if(this.specialKibanaAccess){
  //     const index: number = this.userInfoService.kibanaSpecialAccounts.indexOf(this.currentSiteID);
  //     const specialURls = this.userInfoService.kibanaSpecialURL;
  //     this.kibanaURL = specialURls[index];
  //   }
  //   else{
  //     if(this.currentSiteID === null) {
  //       this.kibanaURL = this.kibanaURL.concat(this.userInfoService.siteId).toLocaleLowerCase();
  //     } else {
  //       this.kibanaURL = this.kibanaURL.concat(this.currentSiteID).toLocaleLowerCase();
  //     }
  //   }
  //   return this.kibanaURL
  // }

}
