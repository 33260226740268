<div
  id="slidebar"
  [ngClass]="{
    'sidebar-full is-closed': currentScreenMode === 'desktopScreen',
    'sidebar-full-mobile is-closed-mobile':
      currentScreenMode === 'mobileScreenPortrait' ||
      currentScreenMode === 'mobileScreenLandscape'
  }"
>
  <button
    (click)="toggleSidebar()"
    [ngClass]="{
      'sidebar-toggle is-closed': currentScreenMode === 'desktopScreen',
      'sidebar-toggle-mobile is-closed-mobile':
        currentScreenMode === 'mobileScreenPortrait' ||
        currentScreenMode === 'mobileScreenLandscape'
    }"
  >
    <i
      [ngClass]="{
        'three-dots': currentScreenMode === 'desktopScreen',
        'three-dots-mobile':
          currentScreenMode === 'mobileScreenPortrait' ||
          currentScreenMode === 'mobileScreenLandscape'
      }"
    ></i>
  </button>
  <div
    [ngStyle]="{ display: 'inline' }"
    (mouseenter)="mouseEnter()"
    (mouseleave)="mouseLeave()"
  >
  <!-- (click)="goToLink('https://marketplace.abaut.de/Zeitraffer/AM10029')" -->
    <a
      id="Timelapse"
      (click)="goToLink('/timelapse')"
      ><img alt="Timelapse"
    /></a>
    <div class="arrow arrow-left hidden" id="tab">
      <label class="image-label" for="Timelapse">Zeitraffer-video</label>
    </div>
  </div>
  <!-- <div> <a (click)="goToLink('https://marketplace.abaut.de/Zeitraffer/AM10029')"><img src="../../../assets/slidebar_timelapse.png" alt="Timelapse"></a> </div> -->
  <!-- <div> <button>icon</button> </div>
      <div> <button>icon</button> </div>
      <div> <button>icon</button> </div> -->
</div>
