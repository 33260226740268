import { Component, OnInit } from '@angular/core';

interface Site {
  side_id: string,
  location: string
}

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})




export class TopBarComponent implements OnInit {
  
  sites: Site[];

  //new to remove error
    // cities;
    // selectedCity1;

  constructor() { 

    this.sites = [
      {side_id: 'AT0010', location: 'DE'},
      {side_id: 'AT0011', location: 'DE'},
     
  ];


  }

  ngOnInit(): void {
  }

}
