import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defaults as defaultInteractions } from 'ol/interaction.js';
import Map from 'ol/Map';

@Injectable({
  providedIn: 'root'
})

export class CurrentUIStateService {

    private _currentMapViewFrame;
    private _currentMapZoomLevel
    private _currentMapLayer = "satellite_plan";
    public splitState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _listPlanLayersOLMap = [];

    // Imp to define the default values here as map expects a value, for lage plan we zoom more into the map for better view.
    private _olmapTilosPLanCurrentZoom      = 0; 
    private _olmapLagePLanCurrentZoom       = 0;
    private _olmapProjectPLanCurrentZoom    = 0;
    private _infoBoxOpen                    = false;
    private backEvent = new BehaviorSubject(false);
    currentBackEvent = this.backEvent.asObservable();

    // public get getGoBack() {
    //     return this.backEvent;
    // }
    public setGoBack(value) {
        this.backEvent.next(value);
    }

    private _olmapTilosPLanCurrentViewParams;  
    public get olmapTilosPLanCurrentViewParams() {
        return this._olmapTilosPLanCurrentViewParams;
    }
    public set olmapTilosPLanCurrentViewParams(value) {
        this._olmapTilosPLanCurrentViewParams = value;
    }
    private _olmapLagePLanCurrentViewParams;     
    public get olmapLagePLanCurrentViewParams() {
        return this._olmapLagePLanCurrentViewParams;
    }
    public set olmapLagePLanCurrentViewParams(value) {
        this._olmapLagePLanCurrentViewParams = value;
    }
    private _olmapProjectPLanCurrentViewParams;   
    public get olmapProjectPLanCurrentViewParams() {
        return this._olmapProjectPLanCurrentViewParams;
    }
    public set olmapProjectPLanCurrentViewParams(value) {
        this._olmapProjectPLanCurrentViewParams = value;
    }
    

    public get listPlanLayersOLMap() {
        return this._listPlanLayersOLMap;
    }
    public set listPlanLayersOLMap(value) {
        this._listPlanLayersOLMap = value;
    }

    public get olmapTilosPLanCurrentZoom() {
        return this._olmapTilosPLanCurrentZoom;
    }
    public set olmapTilosPLanCurrentZoom(value) {
        this._olmapTilosPLanCurrentZoom = value;
    }
    public get olmapLagePLanCurrentZoom() {
        return this._olmapLagePLanCurrentZoom;
    }
    public set olmapLagePLanCurrentZoom(value) {
        this._olmapLagePLanCurrentZoom = value;
    }
    public get olmapProjectPLanCurrentZoom() {
        return this._olmapProjectPLanCurrentZoom;
    }
    public set olmapProjectPLanCurrentZoom(value) {
        this._olmapProjectPLanCurrentZoom = value;
    }

    private _olmapForPlans = new Map({
        // US: To only keep zoom button we initialize controls with an empty array.
        // controls: [],
        target: 'imageplanOL',
        interactions: defaultInteractions({
          doubleClickZoom: true,
          dragPan: true,
          mouseWheelZoom: true,
          altShiftDragRotate:false, 
          pinchRotate:false
        }),
      });


    public get olmapForPlans() {
        return this._olmapForPlans;
    }
    public set olmapForPlans(value) {
        this._olmapForPlans = value;
    }

    public get currentMapViewFrame() {
        return this._currentMapViewFrame;
    }
    public set currentMapViewFrame(value) {
        this._currentMapViewFrame = value;
    }
    public get currentMapZoomLevel() {
        return this._currentMapZoomLevel;
    }
    public set currentMapZoomLevel(value) {
        this._currentMapZoomLevel = value;
    }
    
    public get currentMapLayer() {
        return this._currentMapLayer;
    }

    // The following string constants are used in the code
    // "tilos_plan"
    // "satellite_plan"
    // and a few more....

    public set currentMapLayer(value) {
        this._currentMapLayer = value;
    }

    public get isInfoBoxOpen() {
        return this._infoBoxOpen;
    }
    public set isInfoBoxOpen(value) {
        this._infoBoxOpen = value;
    }

}
 




















