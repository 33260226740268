<body>
    <div class="main">
        <div class="header">
            <h1 class="title" style="font: 2rem Calibri;">{{ 'txtSV_vg_history_title' | translate}}</h1>
        </div>
        <div class="content">
    
            <mat-table [dataSource]="sampleData" class="mat-elevation-z8">
                <ng-container matColumnDef="Request ID">
                  <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_video_id' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.video_id}} </mat-cell>
                </ng-container>
              
                <!-- <ng-container matColumnDef="Camera ID">
                  <mat-header-cell *matHeaderCellDef> Camera ID </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.camera_id}} </mat-cell>
                </ng-container> -->
              
                <ng-container matColumnDef="Date Range">
                  <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_daterange' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.date_range}} </mat-cell>
                </ng-container>
              
                <ng-container matColumnDef="Time Range">
                  <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_timerange' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.time_range}} </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="User">
                    <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_user' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
                </ng-container>
              
                <ng-container matColumnDef="Date Of Creation">
                  <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_createdat' | translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.created_at}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Details">
                    <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_details' | translate}} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <button style="border: 2px solid white !important" (click)="openDetailDialog(element)">Details</button> </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="Video">
                    <mat-header-cell *matHeaderCellDef> {{ 'txtSV_vg_history_column_video' | translate}} </mat-header-cell>
                    <mat-cell  *matCellDef="let element"> <button [hidden]="!element.filename_signed" style="border: 2px solid white !important" (click)="openVideoDialog(element)">
                      {{ 'txtSV_vg_history_column_video_play' | translate}}</button> <div [hidden]="element.filename_signed">{{ 'txtSV_vg_history_column_video_notavailable' | translate}}</div> </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="Download">
                    <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <a class="download" [href]="element.filename_signed" download [hidden]="!element.filename_signed" style="border: 2px solid white !important">
                      {{ 'txtSV_vg_history_column_video_download' | translate}}</a>  
                      <div [hidden]="element.filename_signed">{{ 'txtSV_vg_history_column_video_inprogress' | translate}}
                        <object data="../../../assets/Processing_Spinner.svg" class="spinner-img"></object>
                      </div></mat-cell>
                </ng-container>
    
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              
    
        </div>
    </div>
</body>