<div>
    <!-- <ul>
        <li>FPS: {{data.fps}}</li>
        <li>Intro Headline 1: {{data.headline1}}</li>
        <li>INtro Headline 2: {{data.headline2}}</li>
        <li>Intro Footer: {{data.footer}}</li>
        <li>Audio: {{data.audio}}</li>
        <li>Watermark Image: {{data.wmimg}}</li>
        <li>Outro Image: {{data.outroimg}}</li>
      </ul> -->

      <table class="details-table" style="width:100%">
        <tr>
          <th class="details-th">{{'txtSV_vg_details_fps' | translate }}:</th>
          <td class="details-td">{{data.fps}}</td>
        </tr>
        <tr>
          <th class="details-th">{{'txtSV_vg_details_headline1' | translate }}:</th>
          <td class="details-td">{{data.header1 || '-'}}</td>
        </tr>
        <tr>
          <th class="details-th">{{'txtSV_vg_details_headline2' | translate }}:</th>
          <td class="details-td">{{data.header2 || '-'}}</td>
        </tr>
        <tr>
          <th class="details-th">{{'txtSV_vg_details_footer' | translate }}:</th>
          <td class="details-td">{{data.footer || '-'}}</td>
        </tr>
        <tr>
          <th class="details-th">{{'txtSV_vg_details_audio' | translate }}:</th>
          <td class="details-td">{{data.audio == 'No Audio' ? ('txtSV_vg_main_form_audio_noaduio' | translate) : data.audio}}</td> 
        </tr>
      </table>
      <div style="display: flex; justify-content: right;" mat-dialog-actions>
        <button style="margin-top: 2%; margin-right: 3%; color:white" mat-button (click)="onNoClick()">{{'txtSV_vg_button_ok' | translate }}</button>
      </div>
</div>