export default class Utils {
    static default: any;
    static doSomething(val: string) { return val; }



    //We currently have 5 screensize modes applied conditionally depending on the value of inner width, The current valid constant strings are :
    // mobileScreenPortrait
    // mobileScreenLandscape
    // tabletScreenPortrait
    // tabletScreenLandscape
    // desktopScreen

    static checkScreenDimensions(innerWidth: Number, innerHeight: Number) {
        // console.log("The func was called")
        // console.log(innerWidth)

        switch (true) {
            // This case represnts our SmartPhones
            case innerWidth < 600:
                // console.log("This is your case 1 mobileScreenPortrait with inner width:" + innerWidth)
                return 'mobileScreenPortrait'
            // This case represnts our Landscape
            case (innerWidth < 1000 && window.matchMedia("(orientation: landscape)").matches):
                // console.log("This is your case 5 tablet/mobileScreenLandscape with inner width:" + innerWidth)
                return 'mobileScreenLandscape'
            // This case represnts our Tablets
            case innerWidth < 700:
                // console.log("This is your case 2 tabletScreenPortrait with inner width:" + innerWidth)
                return 'tabletScreenPortrait'
            // This case represnts our Desktop screens
            case innerWidth < 5000:
                // console.log("This is your case 3 desktopScreen with inner width:" + innerWidth)
                return 'desktopScreen'
            default:
                // console.log("This is your case 4 desktopScreen with inner width:" + innerWidth)
                return 'desktopScreen'
        }
    }
}