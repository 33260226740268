import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { UserInfoService } from "./user-info.service";
import { DataService } from "./data.service";
import { DataServiceLocal } from '../_services/local_data.service';

@Injectable({ providedIn: "root" })

export class AuthService {

    public dataService

    constructor(
    private userInfoService: UserInfoService,
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal
    ) {

        // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
    if (environment['environmentName'] ===  'local-development') {
      this.dataService =  dataServiceLocal;
    }
    else{
      this.dataService =  dataServiceRemote;
    }



  }

  singleSignout() {
    console.log("User logs out: " + this.userInfoService.email)
    this.dataService.endUserSession().subscribe(data => {
      console.log(data)
    })
    this.userInfoService.userLoggedin = false

    let logoutUrl = environment.signUpServerUrl + '/auth/single_sign_out/' + this.userInfoService.email + '/' + environment.subDomain
    console.log(logoutUrl)
    window.location.href = logoutUrl

  }

}
