import { Component, ElementRef, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import 'ol/ol.css';
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import View from 'ol/View';
import { DataService } from '../_services/data.service';
import { DataServiceLocal } from '../_services/local_data.service';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { UserInfoService } from '../_services/user-info.service';
import { defaults } from 'ol/interaction';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrentUIStateService } from '../_services/current-UI-state.service';
import { MapViewerComponent } from '../map-viewer/map-viewer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Static from 'ol/source/ImageStatic';
import * as util from '../_helper/util'
import * as moment from 'moment-timezone';
import {Location} from "@angular/common";
import { TRUE } from 'ol/functions';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})

export class ImageGalleryComponent {
  time: string;
  date: string;
  listOfDatesToHighlight
  currentDisplayedImage = ""
  currentSelectedDate = ""
  currentSelectedCamId = ""
  currentSite = ""
  currentZoomLevel = 0
  currentImageExtent
  imageLayer
  ListOfImagesForADay = []
  map
  width
  height
  extent
  computedExtent
  base64Image: any;
  isLoading: any = true;
  content;
  value;
  panInteraction
  camID
  siteID
  timestamp_formated
  image_lat
  image_lng
  currentScreenMode
  infoBoxVisible = false
  planSiteExists = false
  useAsSingleImageDisplay = false
  // siteDataAdditional
  // sidebarDisplayed = false
  public dataService


  @ViewChild('galleryElement', { static: false }) galleryElement: ElementRef
  @ViewChild('sliderElement', { static: false }) sliderElement: ElementRef
  @ViewChild('downloadElement', { static: false }) downloadElement: ElementRef
  @ViewChild('mapElement', { static: false }) mapElement: ElementRef
  @ViewChild('mapDiv', { static: false })  mapDiv: MapViewerComponent
  fetch_image_api: string;

  //////////
  // @Output() backEvent = new EventEmitter<void>();
 ///////////

  constructor(
    public activatedRoute: ActivatedRoute,
    private dataServiceRemote: DataService,
    private dataServiceLocal: DataServiceLocal ,
    public currentUIState: CurrentUIStateService,
    private siteAssociatedData: SiteAssociatedData,
    private userInfoService: UserInfoService,
    private router: Router,
    private location: Location,
    private translate: TranslateService

  ) {

      // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
      // console.log(environment.environmentName, '----------environment---------')
      if (environment.environmentName ===  'local-development') {
        this.dataService =  dataServiceLocal;
      }
      else{
        this.dataService =  dataServiceRemote;
      }

    this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.camID = params['camera_id'];
        this.siteID = params['site_id'];
      })


    // this.httpStatus.getHttpStatus().subscribe(status => { this.HTTPActivity = status; });
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    this.infoBoxVisible = this.currentUIState.isInfoBoxOpen
    if (this.siteAssociatedData.planSiteExists) this.planSiteExists = true;

    if(this.camID == 'abaut_P1020' || this.camID == 'abaut_P1010' ){
    }
    else{
      this.sendInitialRequestsToBackend(this.userInfoService.siteId, this.userInfoService.currentlySelectedMarker)

    }



  }

    back(): void {
      // this.location.back()
      this.currentUIState.setGoBack(true);
      // this.backEvent.emit();
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    this.map.updateSize();
    // let view = this.map.getView();
    // let center = view.getCenter();
    // this.map.getView().setCenter(center);

    // // var resolution = view.getResolution();
    // // var center = getCenter(this.computedExtent);
    // console.log('EXTENT BEFORE RECOMPUTING', this.currentImageExtent)
    this.currentImageExtent = this.map.getView().calculateExtent(this.map.getSize())
    // console.log('EXTENT BEFORE RECOMPUTING', this.currentImageExtent)

    this.map.getView().fit( this.currentImageExtent, { constrainResolution: false })
    // this.ngOnInit()
    // this.map.getView().setCe
  }

    ngAfterContentInit(){
      this.mapDiv.olMapUpdateSize()
  }

  ngAfterViewInit() {
    this.imageLayer = new ImageLayer()
    this.map = new Map({
      interactions: defaults({
        doubleClickZoom: true,
        dragPan: true,
        mouseWheelZoom: true,
        altShiftDragRotate:false,
        pinchRotate:false
      }),
      layers: [this.imageLayer],
     });

    // https://stackoverflow.com/questions/65892423/openlayers-map-in-angular-is-blank-when-switching-components
    // Important as Map doesnt work properly after Angular 10
     setTimeout(() => {
      if (this.map) {
        this.map.setTarget("imageMap");
      }
    }, 1000);

    this.map.on('rendercomplete', e => {
      // this.isLoading = false
      if (this.map !== undefined) {
        this.map.updateSize();
      }
    })



    // This checks the saved state of the UI, If the user has splitState active then its moved to split screen

    this.currentUIState.splitState$.subscribe((value) => {
      switch (value) {
        case true:
          this.miniMapResize()
          break;
        case false:
          // code block
          break;
        default:
          break;
      }
   });


   if(this.camID == 'abaut_P1020' || this.camID == 'abaut_P1010' ){
    this.useAsSingleImageDisplay = true
    this.fetch_image_api = environment.imageServerUrl  + "/fetch_image/"

    this.userInfoService.currentlySelectedImageMarker['complete_url'] = this.fetch_image_api + this.userInfoService.currentlySelectedImageMarker['image_filename']
    this.isLoading = false

    // this.userInfoService.currentlySelectedImageMarker['complete_url'] = "https://imageserver.abaut.de/fetch_image/mnt/image_data_pipeline/projectdata/AT0004/91_officialimage/abaut_C0334/2022_03_23-2022_03_23/mobicam_R334_20220323T152549.792Z_score004_finalImage.jpg?signature=gXqEU0GFQN5GyDh1ZNKXhN%2BExyA%3D&auth_user=anonymous&valid_until=1672125190.0&extra="
    this.currentImageUrlEventForImageGallery(this.userInfoService.currentlySelectedImageMarker)
  }







  }

  showOrHideInfoBox() {
    if(this.currentScreenMode != 'desktopScreen')
    {
      if (this.infoBoxVisible) {
        this.infoBoxVisible = false
        this.currentUIState.isInfoBoxOpen = false;
      }
      else{
        this.currentUIState.isInfoBoxOpen = true;
        this.infoBoxVisible = true
      }
    }
  }

  sendInitialRequestsToBackend(siteID, camID) {
    this.currentSite = siteID
    this.userInfoService.currentlySelectedMarker = camID
    console.log("REQUEST Backend API Call: Get List of days with images for camera " + this.userInfoService.currentlySelectedMarker)
    this.dataService.getListOfDaysWithImages(this.userInfoService.currentlySelectedMarker, this.currentSite).subscribe(data => {
      // this.dataService.getListOfDaysWithImages("abaut_C0300", "DE0007").subscribe(data => {
      console.log("RESPONSE Backend API Call: Get List of days with images for camera " + this.userInfoService.currentlySelectedMarker)
      console.log(data)
      // Sorting is important as the calender needs to pick the highest date to be showed as default.
      //TODO: Where should this list be sorted and what order should it be sorted? Currently is max to min
      this.listOfDatesToHighlight = data['listDateFolders'].sort().reverse()
      this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_listDatesWithImages"] = this.listOfDatesToHighlight

      this.isLoading = false
    },
      (error: HttpErrorResponse) => {
        this.isLoading = false
        console.log(error.name + ' ' + error.message);
      });



  }

  getListOfImagesForADay(siteID, camID, selectedDate) {

    let tempArray = []
    let tempArray_360 = []

    // Save the user selected date, this will be used later on when the user switches the camera
    this.userInfoService.currentlySelectedDateInImageGallery = selectedDate

    // Reset the current zoom level and current image extent when changing the camera,  as the images are entirely differnt when camera switchdd
    this.currentZoomLevel = 0
    this.currentImageExtent = ''

    // this.isLoading = true
    this.currentSite = siteID
    this.userInfoService.currentlySelectedMarker = camID
    this.currentSelectedDate = selectedDate
    console.log("REQUEST Backend API Call: Get List of Images for day: " + this.currentSelectedDate + " and cameraId: " + this.userInfoService.currentlySelectedMarker)
    this.dataService.getListOfImagesForDay(this.userInfoService.currentlySelectedMarker, this.currentSite, this.currentSelectedDate).subscribe(data => {
      // this.dataService.getListOfImagesForDay("abaut_C0300", "DE0007", "2022_01_13").subscribe(data => {
      console.log("RESPONSE Backend API Call: Get List of days with images for camera " + this.userInfoService.currentlySelectedMarker)
      console.log(data)

      // Store the fetched response into a temporary array and then store his
      Object.values(data['result_connected_graph_360']).forEach(item => {
        // this.fetch_image_api =
        item['url'] = environment.imageServerUrl + "/fetch_image/" + item['filename_signed']
        item['id'] = this.userInfoService.currentlySelectedMarker
        tempArray_360.push(item);
      });


      // Store the fetched response into a temporary array and then store his
      Object.values(data['result_connected_graph']).forEach(item => {
        // this.fetch_image_api =
        item['url'] = environment.imageServerUrl + "/fetch_image/" + item['filename_signed']
        item['id'] = this.userInfoService.currentlySelectedMarker
        tempArray.push(item);
      });


      console.log("Merged Connected Graph for static camer")
      console.log(this.siteAssociatedData.mergedConnectedGraphAllCameras)

      // Store this value in global variable
      this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker] = tempArray
      this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_360images"] = tempArray_360
      this.ListOfImagesForADay = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker]
    },
      (error: HttpErrorResponse) => {
        this.isLoading = false
        console.log(error.name + ' ' + error.message);
      });


  }
  // This block was in one of the NG lifecycle hooks but due to angular update, the life cycle hooks are not called always when using router reuse strategy

  // This value comes as output from the slider component
  currentImageUrlEventForImageGallery(currentImageDataObject) {


    console.log("METHOD currentImageUrlEventForImageGallery Received a image object:  ")
    console.log(currentImageDataObject)


   if (this.mapDiv) {

    this.mapDiv.olMapUpdateSize()
  }


  this.olMapUpdateSize()


    // this.map.getLayers().forEach(layer => {
    //   console.log(layer)
    // });


    // From the Connected Graph Data file we search the obj for this particular image, as we need some additional information such as timestamp and lat,lng
    // let currentImageDataObject = this.ListOfImagesForADay.find(o => o.name === value);

    console.log('------------------kibana data', this.userInfoService.siteTimeZone)
    var datetime = moment(currentImageDataObject['timestamp'])
    
       const options :Intl.DateTimeFormatOptions  = {  hour12: false,
        hour: '2-digit', 
        minute: '2-digit',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      
      };

     var f = new Intl.DateTimeFormat(this.userInfoService.currentLanguageLocale, options);
     this.timestamp_formated = f.format(datetime.clone().tz(this.userInfoService.siteTimeZone).toDate())

     if (this.userInfoService.currentLanguageLocale == 'de') {
      this.timestamp_formated = this.timestamp_formated + ' Uhr'
     }






    if ('location' in currentImageDataObject) {
      this.image_lat = currentImageDataObject['location']['lat']
      this.image_lng = currentImageDataObject['location']['lon']

    }


    if (this.map.getView().getZoom() !== undefined) {
      // Save the current zoom level, Extent before changing the image
      this.currentZoomLevel = this.map.getView().getZoom();
      this.currentImageExtent = this.map.getView().calculateExtent(this.map.getSize())
    }

    this.currentDisplayedImage = currentImageDataObject['complete_url']
    const img = new Image();
    let that = this
    img.src = currentImageDataObject['complete_url'];
    console.log("🚀 ~ file: image-gallery.component.ts ~ line 257 ~ ImageGalleryComponent ~ currentImageUrlEventForImageGallery ~ img.src", img.src)

    img.addEventListener("load", function () {

      that.computedExtent = [0, 0, img.naturalWidth, img.naturalHeight];

      if (that.currentImageExtent == '' || that.currentImageExtent == undefined) {
        // The view sometimes have some padding on the sides always, even if we use the same extent as image, so to avoid that we subtract some (pixels), 5,10 is arbitrary number
        that.currentImageExtent = [0, 0, img.naturalWidth - 3, img.naturalHeight - 7]
      }


      that.imageLayer.setSource(
        new Static({
          url: img.src,
          projection: new Projection({  // Map views always need a projection.  Here we just want to map image coordinates directly to map coordinates, so we create a projection that uses
            code: img.src,
            units: "pixels",
            extent: that.computedExtent,
          }),
          imageExtent: that.computedExtent,
        }),
      );


      // let starting_extent;
      // let third_Extent;
      // let final;
      // if (img.naturalHeight > window.innerHeight) {
      //   starting_extent = -img.naturalWidth
      //   third_Extent = img.naturalWidth * 2
      //   final = img.naturalHeight
      // }
      // else {
      //   starting_extent = 0
      //   third_Extent = img.naturalWidth
      //   final = img.naturalHeight
      //   // .naturalWidth
      // }

      // The extent here shall represent the full image extent
      that.map.setView(
        new View({
          extent: [0, 0, img.naturalWidth, img.naturalHeight],
          showFullExtent: true
          // minZoom: 16
        })
      );

      let view = that.map.getView();

      // In case the user had zoomed into a particualr area before, user should see the same area when sliding the image
      view.fit( that.currentImageExtent, { constrainResolution: false })
      that.imageLayer.getSource().changed();

    });

    this.isLoading = false

    // setTimeout(() => {
    //   this.isLoading = false
    // }, 1000)


  }

  // This value comes as output from the Calendar component
  receiveDateFromCalendar(value) {


    this.date = value
    // console.log("Image  gallery recived a date")
    // console.log(value)
    // TODO improve this
    let calculatedValue = value.replaceAll('-', '_')
    this.getListOfImagesForADay(this.userInfoService.siteId, this.userInfoService.currentlySelectedMarker, calculatedValue)
    // this.httpStatus.setHttpStatus(false)

  }

  toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }


  async downloadImage() {
    const a = document.createElement("a");
    let imageUrl = this.currentDisplayedImage


    // TODO This is temporary, will be fixed once CORS is implmented for the Image server.
    // There is a double slash before "mnt". This does not work well. We get rid of this double slash.
    imageUrl = imageUrl.replace('fetch_image//mnt', 'fetch_image/mnt')

    a.href = await this.toDataURL(imageUrl);
    let imagenameWithSign = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
    let imagename = imagenameWithSign.substring(0, imagenameWithSign.indexOf('.jpg'));
    imagename = imagename + ".jpg"
    a.download = imagename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // This can be called by other components too to adjust map size
  olMapUpdateSize() {
    if (this.map !== undefined) {

      this.map.updateSize();
    }
  }

  miniMapResize() {
    // This should reset all the effect of splitscreen

    this.currentUIState.splitState$.subscribe((value) => {

      if (!value) {
        console.log("CONDITION TRIGGERED: Reset Split Screen 2001 IMAGEGALLERY MINI")
        this.galleryElement.nativeElement.classList.remove('item0-split');
        this.galleryElement.nativeElement.classList.add('item0-full');
        if (this.sliderElement){
          this.sliderElement.nativeElement.classList.remove('item1-split');
          this.sliderElement.nativeElement.classList.add('item1-full');
        }
        if (this.downloadElement){
          this.downloadElement.nativeElement.classList.remove('item2-split');
          this.downloadElement.nativeElement.classList.add('item2-full');
        }


        this.mapElement.nativeElement.classList.remove('item3-split');
        this.mapElement.nativeElement.classList.add('item3-full');
      }
      else {

        console.log("CONDITION TRIGGERED: Apply Split Screen 2002 IMAGEGALLERY SPLIT")
        // This should do a split screen
        this.galleryElement.nativeElement.classList.remove('item0-full');
        this.galleryElement.nativeElement.classList.add('item0-split');
        if (this.sliderElement){
          this.sliderElement.nativeElement.classList.remove('item1-full');
          this.sliderElement.nativeElement.classList.add('item1-split');
        }
        if (this.downloadElement){
          this.downloadElement.nativeElement.classList.remove('item2-full');
          this.downloadElement.nativeElement.classList.add('item2-split');
        }


        this.mapElement.nativeElement.classList.remove('item3-full');
        this.mapElement.nativeElement.classList.add('item3-split');
      }
      this.olMapUpdateSize()
   });


    if (this.mapDiv) {

      this.mapDiv.olMapUpdateSize()
    }
  }

  currentImageUrlEventForPanoView(currentImageDataObject) {

    let elem = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_timesliderObj"]
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['siteview'], { queryParams: { id: elem['id'], site_id: this.userInfoService.siteId, camId: elem['id'] }, state: { markerAssociatedData: elem } });
  }

  routeToPlanComponent(planType) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['imageplan'], { queryParams: { planType: planType } });
  }



  routeToSateliteView() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard']);
  }

}
