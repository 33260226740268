<div class="wrapper">

  <div class="flex-controller control-div-top">
    <div class="ol-custom ol-unselectable ol-control">
      <div>
        <img
          [src]="layersLogo"
          (click)="open('layer')"
          (mouseover)="layersLogo = layersLogoRed"
          (mouseout)="layersLogo = layersLogoWhite"
          class="icon-size"
          matTooltip="{{ 'txtSV_contextMenu_headline_layers' | translate }}"
          matTooltipPosition='left'
          matTooltipClass="grey-tooltip">
      </div>
<!--      <div class="terminplanung-div">-->
<!--        <img-->
<!--          [src]="terminplanungLogo"-->
<!--          (click)="open('terminplanung')"-->
<!--          (mouseover)="terminplanungLogo = terminplanungLogoRed"-->
<!--          (mouseout)="terminplanungLogo = terminplanungLogoWhite"-->
<!--          class="icon-size"-->
<!--          matTooltip="{{ 'txtSV_contextMenu_headline_graph' | translate }}"-->
<!--          matTooltipPosition='left'-->
<!--          matTooltipClass="grey-tooltip"-->
<!--        >-->
<!--      </div>-->
      <div class="ressourcen-div">
        <img
          [src]="ressourcenLogo"
          (click)="open('ressourcen')"
          (mouseover)="ressourcenLogo = ressourcenLogoRed"
          (mouseout)="ressourcenLogo = ressourcenLogoWhite"
          class="icon-size"
          matTooltip="{{ 'txtSV_contextMenu_headline_reality' | translate }}"
          matTooltipPosition='left'
          matTooltipClass="grey-tooltip"
        >
      </div>
    </div>
  </div>

  <div class="layer-menu" [hidden]="layerHidden">
    <a (click)="open('layer')"
      ><img class="close" src="../../../assets/close.png" alt="Schliessen"
    /></a>

    <h6>{{ 'txtSV_contextMenu_headline_layers' | translate }}</h6>
    <div *ngFor="let category of layerCategories" class="radio-div">
      <input
        type="checkbox"
        id="{{category.name}}"
        [id]="category.name"
        (change)="onLayerCheckboxChange($event, category)"
        [checked]="category.name === 'satllite'"
        [disabled]="category.name === 'satllite'"
      />
      <label
        [for]="category.name"
        [ngStyle]="{'color' : (category.selectable) ? 'var(--abaut-white-color)' : 'var(--abaut-disable-grey-color)'}"
      >
      {{ category.display_name | translate }}
      </label>
      <span class="menu-upload-indicators">
        <object data="../../../assets/Processing_Spinner.svg" class="spinner-img" [hidden]="!(category.processing)" *ngIf="category.upload"
          matTooltip="{{ 'txtSV_upload_files_tooltip' | translate }} {{ category.processingFiles }}"
          matTooltipPosition='left'
          matTooltipClass="multiline-tooltip"></object>
        <a
          (click)="upload(category.display_name, 'true', category)"
          *ngIf="category.upload"
          ><img class="upload" src="../../../assets/upload.png" alt=""
        /></a>
      </span>
    </div>
    <!-- <button (click)="print()">print</button> -->
  </div>

<!--  <div class="terminplanung-menu" [hidden]="terminplanungHidden">-->
<!--    <a (click)="open('terminplanung')"-->
<!--      ><img class="close" src="../../../assets/close.png" alt="Schliessen"-->
<!--    /></a>-->

<!--    <h6>{{ 'txtSV_contextMenu_headline_graph' | translate }}</h6>-->
<!--    <div *ngFor="let category of terminplanungCategories" class="radio-div">-->
<!--      <input-->
<!--        *ngIf="category.exists"-->
<!--        routerLinkActive="active-link"-->
<!--        routerLink="/imageplan"-->
<!--        [queryParams]="{-->
<!--          planType: category.name-->
<!--        }"-->
<!--        routerLinkActive="active"-->
<!--        [routerLinkActiveOptions]="{ exact: true }"-->
<!--        type="radio"-->
<!--        [value]="category"-->
<!--        [id]="category.name"-->
<!--        [(ngModel)]="selectedTerminplanungCategory"-->
<!--      />-->
<!--      <label *ngIf="category.exists" [for]="category.name">-->
<!--        {{ category.display_name | translate }}-->
<!--      </label>-->
          <!-- <span class="menu-upload-indicators">
            <object data="../../../assets/Processing_Spinner.svg" class="spinner-img"  [hidden]="!(category.processing)" *ngIf="category.upload"></object>
            <a
              (click)="upload(category.display_name, 'true', category)"
              *ngIf="category.upload && category.exists"
              ><img class="upload" src="../../../assets/upload.png" alt=""
            /></a>
          </span> -->
<!--    </div>-->
<!--    &lt;!&ndash; <button (click)="print()">print</button> &ndash;&gt;-->
<!--  </div>-->

  <div class="ressourcen-menu" [hidden]="ressourcenHidden">
    <a (click)="open('ressourcen')"
      ><img class="close" src="../../../assets/close.png" alt="Schliessen"
    /></a>

    <h6>{{ 'txtSV_contextMenu_headline_reality' | translate }}</h6>
    <div
      [ngStyle]="{
        'column-count': '2',
        'column-rule': '1px solid var(--abaut-dark-grey-color)',
        'column-gap': '0'
      }"
    >
      <div *ngFor="let category of ressourcenCategories" class="radio-div">
        <!-- missing link -->
        <input
          *ngIf="category.exists"
          type="radio"
          [name]="category.name"
          [id]="category.name"
          [checked]="category.checked"
          [disabled]="category.disabled"
          (click)="filterCamera(category)"
        />
        <label *ngIf="category.exists" [for]="category.name"
        [ngStyle]="{'color' : (!category.disabled) ? 'var(--abaut-white-color)' : 'var(--abaut-disable-grey-color)'}"
        >
          {{ category.display_name | translate }}
        </label>
        <span class="menu-upload-indicators">
          <object data="../../../assets/Processing_Spinner.svg" class="spinner-img"  [hidden]="!(category.processing)" *ngIf="category.upload"
            matTooltip="{{ 'txtSV_upload_files_tooltip' | translate }} {{ category.processingFiles }}"
            matTooltipPosition='left'
            matTooltipClass="multiline-tooltip"></object>
          <a
            (click)="upload(category.display_name, 'true', category)"
            *ngIf="category.upload"
            ><img class="upload" src="../../../assets/upload.png" alt=""
          /></a>
        </span>
        <a
          (click)="open('maschinentypen')"
          *ngIf="category.next && category.exists"
          ><!--<img
            class="next"
            src="../../../assets/flaticon_32x32/forward-arrow.png"
            alt="next"
        />--></a>
      </div>
      <!-- <button (click)="print()">print</button> -->
    </div>
  </div>

  <div class="maschinentypen-menu" [hidden]="maschinentypenHidden">
    <a (click)="open('maschinentypen')"
      ><img class="close" src="../../../assets/close.png" alt="Schliessen"
    /></a>

    <h6>Maschinnentypen</h6>
    <div *ngFor="let category of maschinentypenCategories" class="radio-div">
      <!-- missing link -->
      <input
        *ngIf="category.exists"
        type="radio"
        [value]="category"
        [id]="category.name"
        [(ngModel)]="selectedMaschinentypenCategory"
      />
      <label *ngIf="category.exists" [for]="category.name">{{
        category.display_name
      }}</label>
      <span class="menu-upload-indicators">
        <object data="../../../assets/Processing_Spinner.svg" class="spinner-img"  [hidden]="!(category.processing)" *ngIf="category.upload"></object>
        <a
          (click)="upload(category.display_name, 'true', category)"
          *ngIf="category.upload && category.exists"
          ><img class="upload" src="../../../assets/upload.png" alt=""
        /></a>
      </span>
    </div>
    <!-- <button (click)="print()">print</button> -->
  </div>

  <!-- <div class="error-box" *ngIf="errorBoxShow">
    <img (click)="onCloseError()" class="close-error" src="../../../assets/close.png" alt="Schliessen" />
    <div class="error-img-container">
      <img class="error-icon"  [src]="errorIconSide" alt=""/>
      <img class="error-icon" [src]="errorIcon" alt=""/>
    </div>
    <div class="error-text">
      <h6>{{errorTitle}}</h6>
    </div>
  </div> -->

  <!-- <div class="error-box" *ngIf="successfulBoxShown">
    <img (click)="onCloseSuccessful()" class="close-error" src="../../../assets/close.png" alt="Schliessen" />
    <div class="error-img-container">
      <img class="error-icon" [src]="successfulIcon" alt=""/>
      <img class="erfolg-icon" [src]="successfulIconSide" alt=""/>
    </div>
    <div class="error-text">
      <h6>{{successfulText}}</h6>
    </div>
  </div> -->
  <app-info-box [show]="uploadNotification" [is_upload]="true" [parentRef]="_eref" style="position:absolute;  bottom:120px;  right:210px;"></app-info-box>
  <!-- uploadNotification -->
  <app-info-box (closeMyWindow)="onCloseInfo()" (closeWindow)="closeAll()" [text]="errorBoxShow ? (errorTitle | translate) : (successfulText | translate)" [mainIcon]="errorBoxShow? errorIcon:successfulIcon" [sideIcon]="errorBoxShow? errorIconSide:successfulIconSide" [show]="errorBoxShow || successfulBoxShown" [error]="errorBoxShow" [parentRef]="_eref" ></app-info-box>
  <!-- errorBoxShow || successfulBoxShown" [error]="errorBoxShow -->
  <app-upload-window id="upload" (showUploadNotifcation)="receiveUploadNotificationStatus($event)" (showUploadDialog)="receiveShowUploadDialog($event)" (closeWindow)="upload('', $event)" [details]="uploadBoxDetails" [title]="uploadTitle" [hidden]="uploadHidden" [uploadHidden]="uploadHidden" [parentRef]="_eref"></app-upload-window>
</div>
