<div class="main-container">
  <img (click)="onCloseClick()" class="close" src="../../../assets/close.png" alt="Schliessen" />
  <div class="info-img-container">
    <img class="analytics-icon" [src]="analyticsLogoUrl" alt="" />
  </div>
  <div>
    <h2>{{'txtSV_analytics_box_title' | translate}}</h2>
  </div>
  <div class="top-margin">
      <h4>{{'txtSV_analytics_box_info' | translate}}</h4>
      <h4 class="top-margin">{{'txtSV_analytics_box_description' | translate}}</h4>
  </div>
  <div class="btn-container">
    <div class="buttonDiv">
      <div (click)="onClickMarketPlace()" class="marketplaceButton">
        {{'txtSV_analytics_box_marketplace' | translate}}
      </div>
    </div>
    <div class="buttonDiv">
      <div (click)="onClickInfoAnalytics()" class="analyticsButton">
        {{'txtSV_analytics_box_analytics' | translate}}
      </div>
    </div>
  </div>
</div>
