<div [ngClass]="{'bgcolor_bauer': currentSiteID=='AT0011', 'bgcolor':currentSiteID!='AT0011' }">


<div id="sidebar" class='sidebar_child' >
  <mat-sidenav *ngIf="currentScreenMode==='desktopScreen'" opened="true" disableClose="true"  >

    <img  *ngIf="currentSiteID === 'DE0007'"  src="./assets/db_logo_.png" class="icon-display" style="width: 90px; height: 60px; margin-top: 10px">
    <img  *ngIf="currentSiteID === 'AT0011'"  src="./assets/BauerAGLogo.png" class="icon-display" style="width: 60px; height: 60px; margin-top: 10px">
    <img  *ngIf="currentSiteID !== 'DE0007' && currentSiteID !== 'AT0011'" src="./assets/custom_icons/abaut_logo.png" class="icon-display" style="width: 60px; height: 60px; margin-top: 10px">



    <!-- sidenav content -->
    <mat-nav-list >
      <mat-list>
        <ng-container        >
          <mat-expansion-panel  [routerLinkActive]="['active-link']"
            [expanded]="isDashboardOpen"    >
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon mat-list-icon>map</mat-icon><span mat-line>Ansicht</span>
              </mat-list-item>
            </mat-expansion-panel-header>


            <mat-list style="margin-left:30px;">
              <div>

                <mat-list-item routerLinkActive="active-link" routerLink="/dashboard" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Satellitenansicht</mat-list-item>
              </div>
            </mat-list>

            <mat-list style="margin-left:30px;">
              <div>

                <!-- <mat-list-item *ngIf ="!planSiteExists">Lageplan</mat-list-item> -->
                <mat-list-item  *ngIf ="planSiteExists" routerLinkActive="active-link" routerLink="/imageplan"
                  [queryParams]="{ planType: 'lageplan' }" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Lageplan</mat-list-item>

              </div>
            </mat-list>
          </mat-expansion-panel>



          <mat-expansion-panel *ngIf ="planMSProjectExists || planTilosExists" [routerLinkActive]="['active-link']"
            [expanded]="isTilosplanopened">
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon mat-list-icon>schedule</mat-icon><span mat-line>Terminplanung</span>
              </mat-list-item>
            </mat-expansion-panel-header>
            <mat-list style="margin-left:30px;">
              <div>
                <!-- <mat-list-item *ngIf ="!planMSProjectExists">Balkendiagramm</mat-list-item> -->
                <mat-list-item  *ngIf ="planMSProjectExists" routerLink="/imageplan" [queryParams]="{ planType: 'projectplan' }"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"> Balkendiagramm </mat-list-item>
              </div>
            </mat-list>



            <mat-list style="margin-left:30px;">
              <div>
                <!-- <mat-list-item *ngIf ="!planTilosExists"> Weg/Zeit-Diagram </mat-list-item> -->
                <mat-list-item  *ngIf ="planTilosExists" routerLink="/imageplan" [queryParams]="{ planType: 'wegplan' }" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"> Weg/Zeit-Diagramm </mat-list-item>
              </div>
            </mat-list>

          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="kibanaAccess">  
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon mat-list-icon>import_export</mat-icon><span mat-line>Reports/Analysen</span>
              </mat-list-item>
            </mat-expansion-panel-header>

            <mat-list style="margin-left:30px;">
              <div>
                <mat-list-item>
                  <a href={{kibanaURL}} target="_blank" rel="noopener noreferrer">Analytics</a>
                </mat-list-item>
              </div>
            </mat-list>


          </mat-expansion-panel>



          <mat-expansion-panel >
            <mat-expansion-panel-header>
              <mat-list-item>
                <mat-icon mat-list-icon>help</mat-icon><span mat-line>Support</span>
              </mat-list-item>
            </mat-expansion-panel-header>
           
           
            <mat-list *ngIf="currentSiteID === 'DE0007'" style="margin-left:30px;">
              <div>
                <mat-list-item> <a href="mailto:service-s4@abaut.de">service-s4@abaut.de</a>
                </mat-list-item>
              </div>
            </mat-list>

            <mat-list *ngIf="currentSiteID !== 'DE0007'" style="margin-left:30px;">
              <div>
                <mat-list-item> <a href="mailto:support@abaut.de">support@abaut.de</a>
                </mat-list-item>
              </div>
            </mat-list>

            <mat-list style="margin-left:30px;">
              <div>
                <mat-list-item>0800/2943351</mat-list-item>
              </div>
            </mat-list>


          </mat-expansion-panel>



          <!-- <span class="spacer"></span> -->


          <mat-list style="margin-left:30px;">

            <a mat-list-item (click)="logout()" style="margin-top: 30px;">
              <mat-icon mat-list-icon>input </mat-icon><span mat-line>Abmelden</span>
            </a>
          </mat-list>


        </ng-container>
      </mat-list>

    </mat-nav-list>
  </mat-sidenav>

</div>

