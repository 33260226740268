<div class="item" *ngIf="isLoading">
  <!-- //redo!!!!!!!!!!!!!!!!!!!!!!!!! -->
  <mat-spinner></mat-spinner>
  <!-- //redo!!!!!!!!!!!!!!!!!!!!!!!!! -->

</div>


<!-- Map can have 3 states (mini,split,full) -->
<!-- <div  #mapElement id="ol-map" class="map-container-large"> -->
<div class="map-container-large">

  <app-mini-side-bar *ngIf="!inMiniMode" (toggleLagePlanLayer)="toggleLagePlanLayer($event)" (toggleDroneLayer)="toggleDroneLayer($event)" [defaultCamera]="cameraDefaultSelected" (toggleMachineBox)="toggleMachineLayer($event)"></app-mini-side-bar>

  <!-- <div #mapElement id="minimapblock" [ngClass]="{'item2-mini': inMiniMode, 'item2-split': !inMiniMode}"> -->

  <div #mapDiv id="ol-map341" class="show full-size"
    [ngClass]="{'item2-mini': inMiniMode, 'item2-split': !inMiniMode}"> </div>

  <div id="tilosplan_mini" *ngIf="currDiv == 'B'" class="full-size"
    [ngClass]="{'item2-mini': inMiniMode, 'item2-split': !inMiniMode}">
    <app-image-plan1 [planTypeInput]="planType"></app-image-plan1>
  </div>

  <div *ngIf="inMiniMode" class="flex-controller control-div-bottom">
    <div *ngIf="this.currentUIState.currentMapLayer != 'satellite_plan'" class="ol-custom1 ol-unselectable ol-control">
      <button (click)="changetoSatelliteLayer()" class="button" type="button" title="Show Map">
        <mat-icon aria-hidden="false" aria-label="satellite_alt icon">satellite_alt</mat-icon>
      </button>
    </div>

    <div *ngIf="planSiteExists && this.currentUIState.currentMapLayer != 'lageplan'"  class="ol-custom1 ol-unselectable ol-control">
      <button (click)="changetoPlanComponent('lageplan')" class="button" type="button" title="Show SitePlan">
        <div class="mat-icon">
          <img src="assets/flaticon_32x32/blueprint.png">
        </div>
      </button>
    </div>
    <div *ngIf="planMSProjectExists && this.currentUIState.currentMapLayer != 'projectplan'"  class="ol-custom1 ol-unselectable ol-control">
      <button (click)="changetoPlanComponent('projectplan')" class="button" type="button" title="Show ProjectPlan">
        <div class="mat-icon">
          <img src="assets/flaticon_32x32/gantt-chart.png">
        </div>
      </button>
    </div>
    <div *ngIf="planTilosExists  && this.currentUIState.currentMapLayer != 'wegplan'"  class="ol-custom1 ol-unselectable ol-control">
      <button (click)="changetoPlanComponent('wegplan')" class="button" type="button" title="Show TilosPlan">
        <div class="mat-icon">
          <img src="assets/flaticon_32x32/blueprint_2.png">
        </div>
      </button>
    </div>

  </div>

  <div *ngIf="inMiniMode" class="flex-controller control-div-top">
    <div #expandButton class="ol-custom1 ol-unselectable ol-control">
      <button (click)="miniMapResizeButtonClicked()" class="button" type="button" title="Resize">
        <mat-icon *ngIf="!miniMapOriginalState" aria-hidden="false" aria-label="zoom_out_map icon">zoom_out_map
        </mat-icon>
        <mat-icon *ngIf="miniMapOriginalState" aria-hidden="false" aria-label="zoom_out_map icon">zoom_in_map</mat-icon>
      </button>
    </div>
  </div>

  <!-- <app-top-left-toolbar *ngIf="this.currentUIState.currentMapLayer == 'satellite_plan' && !inMiniMode" [SITES]="SITES"></app-top-left-toolbar>
  <app-top-right-profile *ngIf="this.currentUIState.currentMapLayer == 'satellite_plan' && !inMiniMode"></app-top-right-profile>

  <app-mini-side-bar *ngIf="this.currentUIState.currentMapLayer == 'satellite_plan' && !inMiniMode"></app-mini-side-bar> -->

  <div  *ngIf="currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'" id="" class="mobile-plan flex-controller control-div-bottom">

    <div  *ngIf="planSiteExists" class="ol-custom1 ol-unselectable ol-control">
      <button (click)="routeToPlanComponent('lageplan')" type="button">
          <div class="mat-icon">
            <img src="assets/flaticon_32x32/blueprint.png">
          </div>
      </button>

    </div>
  </div>

  <!-- </div> -->




  </div>


