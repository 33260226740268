<div class="pageImage">
  <div *ngIf="isLoading == true" class="progress-item">
    <mat-spinner *ngIf="isLoading == true"  [ngClass]="{'red-progress': currentScreenMode==='desktopScreen', 'red-progress-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}" mode="indeterminate"></mat-spinner>
  </div>

<div [ngClass]="{'spinner_overlay': isLoading == true}">


  <div class="wrapper">

<!--    <div (click)="back()" class="flex-controller control-div-top">-->
<!--      <div class="ol-custom ol-unselectable ol-control">-->
<!--        <img class="back" src="./assets/back.png" alt="Back"/>-->
<!--      </div>-->
<!--    </div>-->

    <div *ngIf="currentScreenMode==='desktopScreen' || infoBoxVisible" id="info_cardboard" (click)="this.showOrHideInfoBox()" [ngClass]="{'item1': currentScreenMode==='desktopScreen', 'item1-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">
      <!-- <div *ngIf="infoBoxVisible" id="info_cardboard" class="item1"> -->
      <!-- <div class="second_element"> -->
      <div [ngClass]="{'second_element': currentScreenMode==='desktopScreen', 'second_element-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">
        <h5>{{ 'txtSV_information_panel_title' | translate }}:</h5>
        <table>
          <tr>
            <td>{{ 'txtSV_information_panel_time' | translate }}:</td>
            &nbsp;
            <td>{{ timestamp_formated }}</td>
          </tr>
          <tr>
            <td>{{ 'txtSV_information_panel_lat' | translate }}:</td>
            &nbsp;
            <td>{{ image_lat }}</td>
          </tr>
          <tr>
            <td>{{ 'txtSV_information_panel_long' | translate }}:</td>
            &nbsp;
            <td>{{ image_lng }}</td>
          </tr>

          <!-- <tr>
                <td>Site ID:</td>
                &nbsp;
                <td>{{ siteID }}</td>
              </tr>


              <tr>
                <td>Camera ID:</td>
                &nbsp;
                <td>{{ camID }}</td>
              </tr> -->


        </table>
      </div>
    </div>

    <div *ngIf="currentScreenMode==='desktopScreen'" class="item2-full-back">
      <div class="flexed">
        <div class="align-to-bottom ol-custom1 ol-unselectable ol-control back-btn">
          <button (click)="back()" class="button-back" type="button" title="Back">
            <div class="mat-icon">
              <img src="./assets/back.png">
            </div>
          </button>
        </div>
      </div>
    </div>

    <div  [hidden]="useAsSingleImageDisplay"  class="sidebar-wrapper">
      <app-slidebar [currentScreenMode]="currentScreenMode"></app-slidebar>
    </div>

    <div #galleryElement id="galleryElement" class="item0-full">

      <!-- image gallery -->
      <div id="imageMap" class="map"></div>
    </div>
    <div  *ngIf="!useAsSingleImageDisplay"  #sliderElement id="sliderElement"
    [ngClass]="{'item1-full': currentScreenMode==='desktopScreen', 'item1-full-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">
      <!-- slider-->
      <app-time-slider [ListOfImagesForADay]="ListOfImagesForADay"
        (currentImageUrlEventForImageGallery)="currentImageUrlEventForImageGallery($event)"
        (currentImageUrlEventForPanoView)="currentImageUrlEventForPanoView($event)"></app-time-slider>
      <!-- TimeMessage: {{time}} -->
    </div>
    <div *ngIf="currentScreenMode==='desktopScreen'"  #downloadElement id="downloadElement" class="item2-full">


      <div class="flexed">
        <div class="align-to-bottom ol-custom1 ol-unselectable ol-control">
          <button (click)="downloadImage()" class="button" type="button" title="Download Image">
            <div class="mat-icon">
              <img src="assets/flaticon_32x32/001download.png">
            </div>
          </button>

        </div>

        <app-date-calendar *ngIf="!useAsSingleImageDisplay"  [listOfDatesToHighlight]="listOfDatesToHighlight"
          (selectedCurrentCalenderDate)="receiveDateFromCalendar($event)">
        </app-date-calendar>

      </div>

      <!-- DateMessage: {{date}} -->
    </div>


    <div *ngIf="currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'"  #downloadElement id="downloadElement"
    [ngClass]="{'item2-full': currentScreenMode==='desktopScreen', 'item2-full-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">


      <div class="stackedButtons">
        <div class="align-to-bottom ol-custom1 ol-unselectable ol-control">

          <button (click)="showOrHideInfoBox()" class="button" type="button" >
            <div class="mat-icon">
              <img src="assets/flaticon_32x32/information-button32.png">
            </div>
          </button>
        </div>

        <div *ngIf="planSiteExists" class="align-to-bottom ol-custom1 ol-unselectable ol-control">

          <button (click)="routeToPlanComponent('lageplan')" type="button" class="ol-custom1 ol-unselectable ol-control">
            <div class="mat-icon">
              <img src="assets/flaticon_32x32/blueprint.png">
            </div>
          </button>
        </div>

        <div class="align-to-bottom ol-custom1 ol-unselectable ol-control">

          <button (click)="routeToSateliteView()"  type="button" class="ol-custom1 ol-unselectable ol-control">
            <mat-icon aria-hidden="false" aria-label="satellite_alt icon">satellite_alt</mat-icon>
          </button>
        </div>
        <app-date-calendar *ngIf="!useAsSingleImageDisplay"  [listOfDatesToHighlight]="listOfDatesToHighlight"
          (selectedCurrentCalenderDate)="receiveDateFromCalendar($event)">
        </app-date-calendar>
      </div>

      <!-- DateMessage: {{date}} -->
    </div>



    <div *ngIf="currentScreenMode==='desktopScreen'" #mapElement id="mapElement" class="item3-full ">
      <!-- minimap  -->
      <app-map-viewer *ngIf="!isLoading" #mapDiv (shrink)="miniMapResize()" (expand)="miniMapResize()"
        [inMiniMode]='true'>
      </app-map-viewer>
    </div>


  </div>

</div>
</div>
