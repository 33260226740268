<!--
This is an invisiblediv and the 360 markers are drawn to the left of it above relative to its position.
TODO:::: Filter out 360 images.
TODO :::: See if it works fine in responsive screens -->


<!-- The style padding div is used to allign the markers -->
<!-- MW20220308: changed padding-left from 5.8%  to 36px -->
<!-- MW20220308: Also, I have added top:15px to push down the PanoMarker such that they touch the time line. -->
<div id="invisiblediv" class="flex-controller">
 </div>







<div id="time_slider_parent" class="flex-controller">



  <div class="ol-custom1 ol-unselectable ol-control">
    <button (click)="ChangeSlider(-1)"  id="mybutton" class="button" type="button" title="Decrement">
      <div class="mat-icon">
        <img src="assets/flaticon_32x32/back-arrow.png">
      </div>
    </button>
  </div>

<!--
  <div class="custom-slider">

  <ngx-slider  class="custom-slider" [(value)]mybutton="value" [options]="options"></ngx-slider>

//</div> -->


  <mat-slider id="scrollWidth" #mySlider class="slider-div ol-custom1"  thumbLabel [displayWith]="formatLabel"
    [step]="step" min={{minDate}} max={{maxDate}}
    (change)="sliderChanged(mySlider.value)"
    (input)="onInputChange(mySlider.value)" >
  </mat-slider>


  <!-- <mat-slider id="scrollWidth" #mySlider class="slider-div ol-custom1"  thumbLabel [displayWith]="formatLabel"
    [step]="step" min={{minDate}} max={{maxDate}}
    (change)="sliderChanged(mySlider.value)"
    (input)="onInputChangeModifyBackground(mySlider.value)" >
  </mat-slider>  -->



  <div  class="ol-custom1 ol-unselectable ol-control">
    <button (click)="ChangeSlider(1)" class="button" type="button" title="Increment">
      <div class="mat-icon">

        <img src="assets/flaticon_32x32/forward-arrow.png">
      </div>
    </button>

  </div>


  <!-- <div style="overflow: hidden;">
        <p>Current Timestamp (Unix): {{sliderValue}} </p>
        <p>Current Timestamp (Date):: {{formatLabel(sliderValue)}}</p>
        <p>Image Name : {{currentImageName}} </p>
        <p>Image URL : {{currentImageUrl}} </p>

    </div> -->
</div>
