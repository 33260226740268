import { Component, OnInit, Renderer2 } from '@angular/core';
import { UserInfoService } from '../../_services/user-info.service';
import { ActivatedRoute } from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators, ValidationErrors, ValidatorFn, FormControl, AbstractControlOptions} from '@angular/forms';
import Control from 'ol/control/Control';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/de';
import { environment } from 'src/environments/environment';
import { DataService } from '../../_services/data.service';
import { DataServiceLocal } from '../../_services/local_data.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TimelapseHistoryComponent } from '../timelapse-history/timelapse-history.component';
import { none } from 'ol/centerconstraint';

function dateRangeValidator(group: AbstractControl): ValidationErrors | null {
  const startDate = group.get('startdateFormCtrl');
  const endDate = group.get('enddateFormCtrl');
  let error = null

  if(new Date(startDate.value) > new Date(endDate.value)){
    error = { message: 'txtSV_vg_input_wrongrange' }
    endDate.setErrors(error)
  }
  else{
    if(!endDate.value){
      error = { message: 'txtSV_vg_input_required' }
    }
    else{
      error = null
      endDate.setErrors(error)
    }
  }
  return error
}

function timeRangeValidator(group: AbstractControl): ValidationErrors | null {
  const startTime = group.get('starttimeFormCtrl');
  const endTime = group.get('endtimeFormCtrl');
  let error = null

  if(startTime.value > endTime.value){
    error = { message: 'txtSV_vg_input_wrongtime' }
    endTime.setErrors(error)
  }
  else{
    if(!endTime.value){
      error = { message: 'txtSV_vg_input_required' }
    }
    // else{
    //   error = null
    //   endTime.setErrors(error)
    // }
  }
  return error
}

// function timePeriodValidator(control: AbstractControl): ValidationErrors | null{

//   const value = control.value;
//   let error = null

//   if (!value) {
//     error = { message: 'This field is required' }
//     control.setErrors(error)
//   }

//   const minValidation = value < this.min_time
//   const maxValidation = value > this.max_time

//   const notValid = minValidation || maxValidation;

//   if(notValid){
//     error = { images: `No images avaliable before ${this.min_time} and after ${this.max_time}` }
//     control.setErrors(error)
//   }
//   else{
//     error = null
//     control.setErrors(error)
//   }

//   return error
// }

function fpsValidator(group: AbstractControl): ValidationErrors | null {
  const fps = group.get('fpsFormCtrl');
  let error = null

  if(fps.value === null){
    error = { message: 'txtSV_vg_input_required' }
    fps.setErrors(error)
  }
  else{
    if(fps.value > 60){
      error = { message: 'txtSV_vg_input_fpsmax' }
      fps.setErrors(error)
    }
    else{
      if(fps.value < 1){
        error = { message: 'txtSV_vg_input_fpszero' }
        fps.setErrors(error)
      }
      else{
        error = null
        fps.setErrors(error)
      }
    }
  }
  return error
}

@Component({
  selector: 'app-timelapse',
  templateUrl: './timelapse.component.html',
  styleUrls: ['./timelapse.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
  ],
})
export class TimelapseComponent implements OnInit {

  public dataService;

  submitted = false;
  info_loading = true;
  thumbnails_loading = true;

  videoSettingGroup: FormGroup;
  introGroup: FormGroup;
  imagesGroup: FormGroup;
  summaryGroup: FormGroup;

  playAudio = false;

  siteID = this.userInfoService.siteId;
  camID = this.userInfoService.currentlySelectedMarker;
  start_date;
  end_date;
  min_date;
  max_date;
  start_date_initial = new FormControl(new Date().setFullYear(2022, 12, 1));
  end_date_initial = new FormControl(new Date().setFullYear(2022, 12, 15));
  start_time = '07:00';
  end_time = '18:00';
  min_time = '00:00';
  max_time = '23:59';
  fps: number = 25

  intro_headline1 = "";
  intro_headline2 = "";
  outro_footer = "";
  audios = ["No Audio", "The Savior"]
  selectedAudio = "No Audio"
  audiosURL = {"No Audio":"No Audio", "The Savior":"../../../assets/Audio/the_savior_terrasound.mp3"}
  audiosBackend = {"No Audio":"No Audio", "The Savior":"the_savior_terrasound.mp3"}
  mp3url = "";
  wmimg_file_store: FileList;
  wmimg_file_list: Array<string> = [];
  outroimg_file_store: FileList;
  outroimg_file_list: Array<string> = [];
  
  images = [];
  image_site = '';
  start_image = [this.images[0]];
  end_image = [this.images[this.images.length-1]];
  removed_images = [];
  removed_images_refs = [];

  number_selected_images = this.images.length

  data = {};

  displayData = [["Request ID", "1"], ["Camera ID", "2"]]
  displayColumns = ["1","2"]

  request_status = ""


  constructor(public activatedRoute: ActivatedRoute,
    private userInfoService: UserInfoService,
    private _formBuilder: FormBuilder,
    private renderer: Renderer2,
    private dataServiceRemote: DataService,
    private dataServiceLocal: DataServiceLocal,
    public dialog: MatDialog) {
      if (environment.environmentName ===  'local-development') {
        this.dataService =  dataServiceLocal;
      }
      else{
        this.dataService =  dataServiceRemote;
      }

      this.activatedRoute.params.subscribe(params => {
        //this.camID = params['cam_id'];
        //this.siteID = params['site_id'];
        //this.userInfoService.currentlySelectedMarker = params['cam_id'];
      })
     }

  ngOnInit(): void {
    this.videoSettingGroup = this._formBuilder.group({
      siteFormCtrl: [{value: this.userInfoService.siteId, disabled:true}],
      camFormCtrl: [{value: this.userInfoService.currentlySelectedMarker, disabled:true}],
      startdateFormCtrl: ['', { validators: [Validators.required] }],
      enddateFormCtrl: ['', { validators: [Validators.required] }],
      starttimeFormCtrl: [this.start_time, [Validators.required]],
      endtimeFormCtrl: [this.end_time, [Validators.required]],
      fpsFormCtrl: [{value: this.fps, disabled:false}, [Validators.required]],
    }, {validators: [dateRangeValidator, timeRangeValidator, fpsValidator]} as AbstractControlOptions);

    this.introGroup = this._formBuilder.group({
      headline1FormCtrl: [''],
      headline2FormCtrl: [''],
      footerFormCtrl: [''],
      audioFormCtrl: [this.selectedAudio],
      wmimgFormCtrl: [''],
      outroimgFormCtrl: [''],
    });

    this.imagesGroup = this._formBuilder.group({
    });

    this.summaryGroup = this._formBuilder.group({
    });

    this.dataService.getTimelapseInfo(this.userInfoService.siteId, this.userInfoService.currentlySelectedMarker ).subscribe(data => {
      let startdatetime = data.camera_info[0].split(", ")
      let enddatetime = data.camera_info[1].split(", ")
      let start_date_split = startdatetime[0].split("/")
      let end_date_split = enddatetime[0].split("/")

      this.start_date = new Date(`${start_date_split[2]}-${start_date_split[1]}-${start_date_split[0]}`)
      this.end_date = new Date(`${end_date_split[2]}-${end_date_split[1]}-${end_date_split[0]}`)

      this.min_date = new Date(`${start_date_split[2]}-${start_date_split[1]}-${start_date_split[0]}`)
      this.max_date = new Date(`${end_date_split[2]}-${end_date_split[1]}-${end_date_split[0]}`)
      
      this.videoSettingGroup.get("startdateFormCtrl").setValue(this.start_date)
      this.videoSettingGroup.get("enddateFormCtrl").setValue(this.end_date)

      //this.videoSettingGroup.get("starttimeFormCtrl").setValue(startdatetime[1])
      //this.videoSettingGroup.get("endtimeFormCtrl").setValue(enddatetime[1])

      this.min_time = this.start_time
      this.max_time = this.end_time
    })

    // this.formArray = [this.videoSettingGroup, this.introGroup, this.imagesGroup, this.summaryGroup];

    // this.formGroup = this._formBuilder.group({
    //   formArray: this._formBuilder.array([
    //     this.videoSettingGroup,
    //     this.introGroup,
    //     this.imagesGroup,
    //     this.summaryGroup
    //   ])
    // });
  }

  //  get formArray(): AbstractControl | null { return this.formGroups.get(['formArray']); }

  goToLink(url: string){
    if(url === "/timelapse-history"){
      url = url + "/" + this.siteID
      this.dataService.getTimelapseHistory(this.siteID, this.camID).subscribe(data => { 
        data.history.forEach(record => {
          if(record.filename_signed !== ""){
            record.filename_signed = environment.imageServerUrl + "/fetch_image/" + record.filename_signed
          }
        })
        this.openDetailDialog(data); 
      })
    }
    else{
      window.open(url, "_blank");
    }
}

changeAudio(){
  this.mp3url = this.audiosURL[this.introGroup.get("audioFormCtrl").value]
}

toggleAudio(){
  if(this.introGroup.get("audioFormCtrl").value != "No Audio"){
    this.playAudio = !this.playAudio;
  }
  else{
    this.playAudio = false
  }

}

handleFileInputChange(l: FileList, file): void {
  const file_type_regex = /(?:\.([^.]+))?$/;
  if(file_type_regex.exec(l[0]?.name)[0] === ".png"){
    if(file==="1"){
      this.wmimg_file_store = l;
    }
    else{
      this.outroimg_file_store = l;
    }
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} files)` : "";
      if(file==="1"){
        this.introGroup.get("wmimgFormCtrl").patchValue(`${f.name}${count}`);
      }
      else{
        this.introGroup.get("outroimgFormCtrl").patchValue(`${f.name}${count}`);
      }
    } else {
      if(file==="1"){
        this.introGroup.get("wmimgFormCtrl").patchValue("");
      }
      else{
        this.introGroup.get("outroimgFormCtrl").patchValue("");
      }
    }
  }
  else{
    if(file==="1"){
      this.introGroup.get("wmimgFormCtrl").patchValue("");
    }
    else{
      this.introGroup.get("outroimgFormCtrl").patchValue("");
    }
  }
}

handleFileSubmit(): any{
  // if(file==="1"){
  //   var fd1 = new FormData();
  //   this.wmimg_file_list = [];
  //   for (let i = 0; i < this.wmimg_file_store.length; i++) {
  //     fd1.append("files", this.wmimg_file_store[i], this.wmimg_file_store[i].name);
  //     this.wmimg_file_list.push(this.wmimg_file_store[i].name);
  // }
  // }
  // else{
  //   var fd2 = new FormData();
  //   this.outroimg_file_list = [];
  //   for (let i = 0; i < this.outroimg_file_store.length; i++) {
  //     fd2.append("files", this.outroimg_file_store[i], this.outroimg_file_store[i].name);
  //     this.outroimg_file_list.push(this.outroimg_file_store[i].name);
  // }
  // }

  var files = {}
  if((this.wmimg_file_store != undefined) || (this.outroimg_file_store != undefined)){
    this.wmimg_file_list = [];
    this.outroimg_file_list = [];
    if((this.wmimg_file_store != undefined)){
      files['wm'] = (this.wmimg_file_store[0])
    }
    if((this.outroimg_file_store != undefined)){
      files['out'] = (this.outroimg_file_store[0])
    }
  }
  return files
}

formatDate(date): string {
  const _date = new Date(date);
  const day = _date.getDate();
  const month = _date.getMonth() + 1;
  const year = _date.getFullYear();
  return `${day}.${month}.${year}`;
}

onSubmit() {
  if(!this.submitted){ 
    this.submitted = true
    let start_date_format = this.formatDate(this.videoSettingGroup.get("startdateFormCtrl").value)
    let end_date_format = this.formatDate(this.videoSettingGroup.get("enddateFormCtrl").value)
    let wanted_images = this.images.slice(this.images.indexOf(this.start_image[0]), (this.images.indexOf(this.end_image[0])+1))
    for(let i = 0; i<this.removed_images.length; i++){
      wanted_images.splice(wanted_images.indexOf(this.removed_images[i]), 1);
    }
    for(let i = 0; i<wanted_images.length; i++){
      let image_string = String(wanted_images[i]).replace(this.image_site, '') 
      let image_no_signature = image_string.split('?')[0]

      let parts = image_no_signature.split("/")
      let filedate = parts[parts.length - 2]
      let filename = parts[parts.length - 1]

      wanted_images[i] = filedate.concat("/", filename)
    }
    let files = this.handleFileSubmit()
  
    this.data = {
      site_id: this.videoSettingGroup.get("siteFormCtrl").value,
      camera_id: this.videoSettingGroup.get("camFormCtrl").value,
      start_date: start_date_format,
      end_date: end_date_format,
      start_time: this.videoSettingGroup.get("starttimeFormCtrl").value,
      end_time: this.videoSettingGroup.get("endtimeFormCtrl").value,
      fps: this.videoSettingGroup.get("fpsFormCtrl").value,
    
      header_1: this.introGroup.get("headline1FormCtrl").value,
      header_2: this.introGroup.get("headline2FormCtrl").value,
      footer: this.introGroup.get("footerFormCtrl").value,
      audio: this.audiosBackend[this.introGroup.get("audioFormCtrl").value],
              
      selected_images: wanted_images
        }
      this.dataService.requestTimelapseVideo(this.data, files).subscribe(data => {
        this.request_status = "txtSV_vg_main_message_success"
        console.log(data)
      }, 
      error => {this.request_status = "Error Occured, please try again."})
      //this.dialog.closeAll()
    //console.log(this.data)
  }
} 

close(){
  this.dialog.closeAll()
}

addEvent(type, event) {
  let startDate;
  let endDate;
  if(type==="start"){
    this.start_date = this.formatDate(event.value)
    startDate = this.formatDate(event.value)
    endDate = this.end_date
  }
  else{
    this.end_date = this.formatDate(event.value)
    startDate = this.start_date
    endDate = this.formatDate(event.value)
  }
}

async getThumbnails(){
  //await new Promise(r => setTimeout(r, 10000));
  this.start_date = this.formatDate(this.videoSettingGroup.get("startdateFormCtrl").value)
  this.end_date = this.formatDate(this.videoSettingGroup.get("enddateFormCtrl").value)
  let start_date_value = this.start_date
  let end_date_value = this.end_date


  let period = {
    "start_date" : start_date_value,
    "end_date" : end_date_value,
    "start_time" : this.videoSettingGroup.get("starttimeFormCtrl").value,
    "end_time" : this.videoSettingGroup.get("endtimeFormCtrl").value
  }
  let nowResponse = new Date().getTime()
  await this.dataService.getTimelapseThumbNails(this.userInfoService.siteId, this.userInfoService.currentlySelectedMarker, period).subscribe(data => {
    let endResponse = new Date().getTime()
    let responseTime = endResponse - nowResponse
    let nowFrontend = new Date().getTime()
    if (environment.environmentName ===  'local-development') {
      this.images = []
      let service_images = data.files;
      service_images.forEach(image =>{
         this.images.push(image)
       })
    }
    else{
      this.images = []

      let backend_images = data.files
      this.image_site = environment.imageServerUrl + "/fetch_image/"
      backend_images.forEach(image => {
        let frontend_format = this.image_site + image
        this.images.push(frontend_format)
      }
      )
    }

    this.start_image = [this.images[0]];
    this.end_image = [this.images[this.images.length-1]];
    this.number_selected_images = this.images.length
    this.thumbnails_loading = false
    let endFrontend = new Date().getTime()
    let frontendTime = endFrontend - nowFrontend

    console.log(responseTime)
    console.log(frontendTime)
  })
}
imageIndex(index:number, el:any): number {
  return index;
}
onStart(num, event){
  if((this.images.indexOf(num) < this.images.indexOf(this.end_image[0])) && (!this.removed_images.includes(num))){
    if(this.start_image.length < 2){
      this.start_image = [num, event.target]
      this.renderer.setStyle(event.target, 'background', 'green !important');
    }
    else{
      this.renderer.setStyle(this.start_image[1], 'background', '');
      this.start_image = [num, event.target]
      this.renderer.setStyle(event.target, 'background', 'green');
  
    }
  }

  let update_remove = []
  let update_remove_ref = []
  for(let i = 0; i<this.removed_images.length; i++){
    if(this.images.indexOf(this.removed_images[i]) < this.images.indexOf(num)){
      this.renderer.setStyle(this.removed_images_refs[i], 'background', '');
      update_remove.push(this.removed_images[i])
      update_remove_ref.push(this.removed_images_refs[i])
    }
  }
  for(let i = 0; i<update_remove.length; i++){
    this.removed_images.splice(this.removed_images.indexOf(update_remove[i]), 1);
    this.removed_images_refs.splice(this.removed_images_refs.indexOf(update_remove_ref[i]), 1);
  }

  this.number_selected_images = this.images.length - (this.images.indexOf(this.start_image[0])) - (this.images.length - this.images.indexOf(this.end_image[0])) - this.removed_images.length + 1
}

onEnd(num, event){
  if((this.images.indexOf(num) > this.images.indexOf(this.start_image[0])) && (!this.removed_images.includes(num))){
    if(this.end_image.length < 2){
      this.end_image = [num, event.target]
      this.renderer.setStyle(event.target, 'background', 'red');
    }
    else{
      this.renderer.setStyle(this.end_image[1], 'background', '');
      this.end_image = [num, event.target]
      this.renderer.setStyle(event.target, 'background', 'red');
    }
  }

  let update_remove = []
  let update_remove_ref = []
  for(let i = 0; i<this.removed_images.length; i++){
    if(this.images.indexOf(this.removed_images[i]) > this.images.indexOf(num)){
      this.renderer.setStyle(this.removed_images_refs[i], 'background', '');
      update_remove.push(this.removed_images[i])
      update_remove_ref.push(this.removed_images_refs[i])
    }
  }
  for(let i = 0; i<update_remove.length; i++){
    this.removed_images.splice(this.removed_images.indexOf(update_remove[i]), 1);
    this.removed_images_refs.splice(this.removed_images_refs.indexOf(update_remove_ref[i]), 1);
  }

  this.number_selected_images = this.images.length - (this.images.indexOf(this.start_image[0])) - (this.images.length - this.images.indexOf(this.end_image[0])) - this.removed_images.length + 1

}

onRemove(num, event){
  if((this.images.indexOf(num) > this.images.indexOf(this.start_image[0])) && (this.images.indexOf(num) < this.images.indexOf(this.end_image[0]))){
    if(event.target.style.backgroundColor === "red"){
      this.removed_images.splice(this.removed_images.indexOf(num), 1);
      this.removed_images_refs.splice(this.removed_images_refs.indexOf(event.target), 1);
      this.renderer.setStyle(event.target, 'background', '');
    }
    else{
      this.removed_images.push(num)
      this.removed_images_refs.push(event.target)
      this.renderer.setStyle(event.target, 'background', 'red');
    }
  }

  this.number_selected_images = this.images.length - (this.images.indexOf(this.start_image[0])) - (this.images.length - this.images.indexOf(this.end_image[0])) - this.removed_images.length + 1
}

openDetailDialog(details): void {
  const dialogRef = this.dialog.open(TimelapseHistoryComponent, {
    width: '55vw',
    height: '25vw',
    maxWidth: '95vw',
    data: details, 
    id: 'history'
  });

  dialogRef.afterClosed().subscribe(() => {
    //this.ngOnInit()
  });
}

}
