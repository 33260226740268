import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { environment } from 'src/environments/environment';
import { SiteAssociatedData } from './site-associated-data.service';
import {endpoints} from '../_models/endpoints';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private REST_API_SERVER;
  HTTPActivity: boolean;


  constructor(private httpClient: HttpClient, public siteAssociatedData: SiteAssociatedData) {
    this.REST_API_SERVER = environment.apiUrl
  }


  /** Get Latest image from the server */
  getLatestImage(cameraId: string): Observable<any> {
    const url = `${this.REST_API_SERVER}/get_latest_image/`;
    return this.httpClient.post(url, { camera_id: cameraId });
  }


  /** Get Latest image from the server */
  getListOfDaysWithImages(cameraId: string, siteId: string, cred: string): Observable<any> {
    const url = `${this.REST_API_SERVER}/get_list_of_days_with_images/`;
    return this.httpClient.post(url, { camera_id: cameraId, site_id: siteId, cred: cred });
  }


  /** Get Latest image from the server */
  getListOfImagesForDay(cameraId: string, siteId: string, date: string, cred: string): Observable<any> {
    const url = `${this.REST_API_SERVER}/get_listimage_for_a_day/`;
    return this.httpClient.post(url, { camera_id: cameraId, site_id: siteId, selected_date: date, cred: cred });
  }

  /** Get Imagedata Connected Graph for Image Markers from the server */
  get_imagemarkers_connectedgraph(cameraIds: string[], site_id: string): Observable<any> {

    const url = `${this.REST_API_SERVER}/fetch_imagemetadata_connectedgraph/`;
    return this.httpClient.post(url, { site_id, camera_ids: cameraIds });
  }


  /** Get Imagedata Connected Graph from the server */
  get_imagemetadata_connectedgraph(cameraIds: string[], site_id: string): Observable<any> {

    const url = `${this.REST_API_SERVER}/fetch_imagemetadata_connectedgraph/`;
    return this.httpClient.post(url, { site_id, camera_ids: cameraIds });
  }


  public getUserSettings(): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/v1/usersettings.json`;
    return this.httpClient.get<any>(url);

  }


  /** Post Upload File for Plan */
  uploadPlan(files: File[], fileNames: string[], siteId: string, planName: string, siteName: string): Observable<any> {

    let url = `${this.REST_API_SERVER}/api/upload-file?`;

    const formData = new FormData();
    for (const index in files) {
      if(files.hasOwnProperty(index)) {
        formData.append('files', files[index], fileNames[index]);
      }
    }

    url = url + 'site_id=' + siteId + '&plan_name=' + planName + '&site_name=' + siteName

    // const req = new HttpRequest('POST', url, formData, options);
    // return this.httpClient.request(req);

    return this.httpClient.post(url, formData);
  }

  /** Get Drone Map */
  getDrohneMap(siteId: string): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/v1/drohnemap/${siteId}`;
    return this.httpClient.get(url);
  }


  /** Terminate Flask session user */
  endUserSession(): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/v1/session/terminate/session.json`;
    return this.httpClient.get(url);
  }


  getAssociatedCameras(site_id: string): Observable<any> {
    const url = this.REST_API_SERVER + endpoints.siteAssociatedCameras(site_id);
    return this.httpClient.get(url);
  }


  getSiteAssociatedData(site_id: string): Observable<any> {
    const url = `${this.REST_API_SERVER}/fetch_site_associated_data/`;
    return this.httpClient.post(url, { site_id });
  }

  authenticateUser(site_id: string, sensor_id: string, cred: string) {
    const url = `${this.REST_API_SERVER}/api/v1/authenticate/`;
    return this.httpClient.post(url, { site_id, sensor_id, cred });
  }

  getSiteConfigData(site_id: string): Observable<any> {
    const url = this.REST_API_SERVER + endpoints.siteConfigFile(site_id);
    return this.httpClient.get(url);
  }

  getSiteID(): Observable<any> {
    const url = `${this.REST_API_SERVER}/fetch_siteID/`;
    return this.httpClient.get(url);
  }

  checkUserLoggedIn(): Observable<any> {
    const url = `${this.REST_API_SERVER}/checkLogin_status/`;
    return this.httpClient.get(url);
  }


  getUserData(): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/v1/users/by_user/userdata.json`;
    return this.httpClient.get(url);
  }

  getUserSites(): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/v1/sites/by_user/sitesforuser.json`;
    return this.httpClient.get(url);
  }

  getPanoramicCameras(site_id, camera_ids: String[]): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/v1/dyn_ids/`;
    return this.httpClient.post(url, { site_id, camera_ids: camera_ids });
  }

  getProcessingFiles(site_id): Observable<any>{
    const url = `${this.REST_API_SERVER}/api/upload_process_status/`;
    return this.httpClient.post(url, { site_id });
  }

  setProcessingFiles(site_id, files, status): Observable<any>{
    const url = `${this.REST_API_SERVER}/api/set_files_status/`;
    return this.httpClient.post(url, { site_id, files, status });
  }

  getMachinePositions(siteId: string) {
    const url = this.REST_API_SERVER + endpoints.siteMachinePositions(siteId);
    return this.httpClient.get(url);
  }

  getTimelapseInfo(site_id, camera_id): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/camera_informations`;
    return this.httpClient.post(url, { site_id, camera_id });
  }

  getTimelapseThumbNails(site_id, camera_id, period): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/camera_thumbnails`;
    return this.httpClient.post(url, { site_id, camera_id, period });
  }

  requestTimelapseVideo(data, files: File[]): Observable<any> {
    const fileNames =  ["watermark", "final_frame_4k"]
    const formData = new FormData();
    if(files['wm'] != undefined){
      formData.append('files', files['wm'], fileNames[0]);
    }
    if(files['out'] != undefined){
      formData.append('files', files['out'], fileNames[1]);
    }



    Object.keys(data).forEach((key)=>{formData.append(key, data[key])});
    console.log("request Timelapse")
    const url = `${this.REST_API_SERVER}/api/request_video`;
    return this.httpClient.post(url, formData);
  }

  getTimelapseHistory(site_id, cam_id): Observable<any> {
    const url = `${this.REST_API_SERVER}/api/video_history`;
    return this.httpClient.post(url, { site_id, cam_id });
  }
}
