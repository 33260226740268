import { Component, Output, EventEmitter, ViewChild, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import 'hammerjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserInfoService } from '../_services/user-info.service';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { CurrentUIStateService } from '../_services/current-UI-state.service';
import * as util from '../_helper/util'
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import {Subject} from "rxjs";


@Component({
  selector: 'app-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.scss']
})

export class TimeSliderComponent   {

  @ViewChild('mySlider',  {static: false}) _matSlider: MatSlider;

  @Input() ListOfImagesForADay : any = [{
    "filename": "",
    "filename_signed": "",
    "timestamp": "",
    "timestamp_s": "",
  }]

  @Output() time = new EventEmitter<string>();
  @Output() currentImageUrlEventForImageGallery = new EventEmitter<object>();
  @Output() currentImageUrlEventForPanoView = new EventEmitter<object>();
  @Output() reRouteSignal = new EventEmitter<object>();
  @Input() timeStamp: Subject<string>;

  step = 1 ;
  // Slider min value
  minDate
  // Slider max value
  maxDate
   currentImageName = "./assets/image3.png"
  currentImageUrl  = ""
  fetch_image_api
  fetch_thumbnail_api
  sliderValue = 0
  currentTimeSliderElementObj
  value
  url
  currentScreenMode
  previousRequestTimestamp
  previousRequestedImage
  currentMarkerId
  camDataGraph
  receivedTimeStamp

  constructor(
    private router: Router,
     private userInfoService: UserInfoService,
     private siteAssociatedData : SiteAssociatedData,
     private cdr: ChangeDetectorRef,
     private currentUIState: CurrentUIStateService,

  ) {
    this.fetch_image_api = environment.imageServerUrl  + "/fetch_image/"
    this.fetch_thumbnail_api = environment.imageServerUrl  + "/fetch_thumbnail/"
    // console.log("see input data")
    // console.log(this.ListOfImagesForADay)
    this.url = this.router.url;
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    this.previousRequestTimestamp = Date.now()
    this.currentMarkerId =  this.userInfoService.currentlySelectedMarker
    this.camDataGraph =  this.siteAssociatedData.mergedConnectedGraphAllCameras
  }

  ngOnInit()
  {
    const delayedClicks = this.currentUIState.splitState$.pipe(delay(100));
    delayedClicks.subscribe({
      next: (file) => {
        // console.log('file', file);
        if (this.ListOfImagesForADay == undefined || this.ListOfImagesForADay  == null ||  this.ListOfImagesForADay.length == 0 )
          {return}
        // console.log('SUBSCRIBED VALUE CHANGE CALL CALLED')
        this.attachMarkerstoTimeSlider()
      }
    });

    this.timeStamp.subscribe(v => {
      console.log('========= timestamp is changing =========>', v);
      this.receivedTimeStamp = v
    });

  }

  ngDoCheck() {
    this.cdr.detectChanges();
    }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    // console.log('ON RESIZE CALLED')
    // console.log('ON RESIZE CALLED')
    if(document.getElementById("scrollWidth"))
    {
      this.attachMarkerstoTimeSlider()
    }

  }

  initiateTumbnailLoader()
{

  document.getElementsByClassName('mat-slider-thumb-label').item(0).setAttribute('id','thumbnailDiv');
  var div = document.createElement("div");
  div.className = "customDiv"
  div.id = "thumbnailLoader"
  document.getElementById("thumbnailDiv").appendChild(div);
}
  removeTumbnailLoader()
{
  var div = document.getElementById('thumbnailDiv');
  document.getElementsByClassName('mat-slider-thumb-label').item(0).setAttribute('id','');
  div.innerHTML= '';
  div.style.backgroundImage = '';
  // var divLoader = document.getElementById('thumbnailLoader');
  // divLoader.classList.add('add-overlay');
  // divLoader.style.visibility = 'visible';
}

  ngOnChanges(){

    // console.log("Time/ slider Detected changes")


    if (this.ListOfImagesForADay == undefined || this.ListOfImagesForADay  == null ||  this.ListOfImagesForADay.length == 0 ){
      console.log("!!!!!!!!! Empty List: ListOfImagesForADay !!!!!!!!!!")
      return
    }
    if (document.getElementById('thumbnailDiv') == null )
    {
      console.log("Initiating thumbnail loader")
      this.initiateTumbnailLoader()
    }

    if (this.ListOfImagesForADay.length <= 1)
    {
      console.log("Removing thumbnail loader")
      this.removeTumbnailLoader()
    }
        // We need to sort the sample data as the timeslider needs sorted data.
        this.ListOfImagesForADay.sort(function(a, b) {
          var keyA = new Date(a.timestamp_s),
            keyB = new Date(b.timestamp_s);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

    const url = this.router.url;

    console.log("⏬  Sorted List of Images: ")
    console.log(this.ListOfImagesForADay)
    console.log("⏬  Complete Data graph for Cameras: ", this.camDataGraph)
    this.camDataGraph[this.currentMarkerId + "_timesliderObj"]  = null


    // Initially set the slider to the first element in the data if our saved global variable is not set yet
    if (this.camDataGraph[this.currentMarkerId + "_timesliderObj"]  !== null && this.camDataGraph[this.currentMarkerId + "_timesliderObj"]  !== undefined){

      this.currentTimeSliderElementObj =   this.camDataGraph[this.currentMarkerId + "_timesliderObj"]
      console.log("⏬  this.currentTimeSliderElementObj['timestamp_s'] " + this.currentTimeSliderElementObj['timestamp_s'])
      this.sliderValue = this.currentTimeSliderElementObj['timestamp_s']
      // let myTimestampStr = this.currentTimeSliderElementObj['timestamp']

      let myTimestampStr = this.userInfoService.globalSelectedTimestamp

      console.log('----------------FROM IMAGEGALLERY OLD-----------------',    this.userInfoService.globalSelectedTimestamp)

      // In case we have a Time slider object saved, we try to find the closest one from list of images, see how the timestring is sliced so that we can compare them and find closest
      let nearestObjectwrtTimeStamp = this.camDataGraph[this.currentMarkerId].reduce(function (r, a, i, aa) {
        return i && Math.abs(aa[r].timestamp.slice(11,19).replaceAll(':','') -myTimestampStr.slice(11,19).replaceAll(':','')) < Math.abs(a.timestamp.slice(11,19).replaceAll(':','') - myTimestampStr.slice(11,19).replaceAll(':','')) ? r : i;
      }, -1);

      this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] = nearestObjectwrtTimeStamp
      var objectFound = this.camDataGraph[this.currentMarkerId][nearestObjectwrtTimeStamp];
      console.log("🚀 ~ file: time-slider.component.ts ~ line 120 ~ TimeSliderComponent ~ ngOnChanges ~ objectFound", objectFound)
      this.camDataGraph[this.currentMarkerId + "_timesliderObj"] = objectFound

      // console.log("Time Slider Constructor")
      this.currentTimeSliderElementObj =   this.camDataGraph[this.currentMarkerId + "_timesliderObj"]
      this.userInfoService.globalSelectedTimestamp = this.currentTimeSliderElementObj.timestamp

    }
    else{
      // console.log("Didnt Find any  saved TimeSlider Obj for this Marker")
      // If there was no timesliderOBJ saved for this camera, we set it to 0 Initially
      var tempDate = new Date(this.ListOfImagesForADay[0]['timestamp'])
      var datetime = moment(tempDate).format("YYYY_MM_DD");
      var currentDate = moment(Date.now()).format("YYYY_MM_DD");
      this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] = 0

        if(this.camDataGraph[this.currentMarkerId + "_listDatesWithImages"][0] === datetime)
          {
            this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] = this.ListOfImagesForADay.length - 1
          }

        if(this.camDataGraph[this.currentMarkerId + "_listDatesWithImages"][0] !== currentDate)
        {
            console.log('____________CURRENT DATE CHECK RAN______________')
            tempDate.setHours(12,0,0,0)
            var temp = this.ListOfImagesForADay.map(function(el)
            {return (Math.abs(tempDate.valueOf() - new Date(el.timestamp).getTime()))})
            var idx = temp.indexOf(Math.min(...temp));
            // console.log('------------closest one',this.ListOfImagesForADay[idx]);
            // console.log("nearestObjectwrtTimeStamp",temp);
            this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] = idx
        }

      if(this.receivedTimeStamp !== undefined && this.receivedTimeStamp !== null && this.receivedTimeStamp !== "")
      {
        console.log('____________CURRENT DATE CHANGE SAME COMPONENT CHECK RAN______________')
        var tempDate = new Date(Number(this.receivedTimeStamp) * 1000);
        var temp = this.ListOfImagesForADay.map(function(el)
        {return (Math.abs(tempDate.valueOf() - new Date(el.timestamp).getTime()))})
        var idx = temp.indexOf(Math.min(...temp));

        this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] = idx

      }
      this.currentTimeSliderElementObj = this.camDataGraph[this.currentMarkerId][ this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] ]
      this.userInfoService.globalSelectedTimestamp = this.currentTimeSliderElementObj.timestamp

      this.camDataGraph[this.currentMarkerId + "_timesliderObj"] =   this.currentTimeSliderElementObj
      this.sliderValue = this.camDataGraph[this.currentMarkerId][0]['timestamp_s']
      this._matSlider.value = this.sliderValue


    }

    console.log("🚀 ~ file: time-slider.component.ts ~ line 173 ~ TimeSliderComponent ~ ngOnChanges ~ this.currentTimeSliderElementObj", this.currentTimeSliderElementObj)
    this.sliderValue = this.currentTimeSliderElementObj['timestamp_s']
    this._matSlider.value = this.sliderValue
    this.currentImageName = this.currentTimeSliderElementObj['filename']
    this.currentImageUrl = this.fetch_image_api + this.currentTimeSliderElementObj['filename_signed']
    this.minDate = this.ListOfImagesForADay[0]['timestamp_s']
    this.maxDate = this.ListOfImagesForADay.slice(-1).pop()['timestamp_s']
    // this._matSlider.value = this.sliderValue

    this.currentTimeSliderElementObj['complete_url'] = this.currentImageUrl
    this.userInfoService.globalSelectedTimestamp = this.currentTimeSliderElementObj['timestamp']
    this.currentImageUrlEventForImageGallery.emit(this.currentTimeSliderElementObj);
    // let start_position = document.getElementById("scrollWidth").getBoundingClientRect().left;
    if(document.getElementById("scrollWidth"))
    {
      // console.log('ON NGCHANGES CALLED')
      this.attachMarkerstoTimeSlider()
    }

  }

  attachMarkerstoTimeSlider()
  {
    // console.log('_________attachMarkerstoTimeSlider________')

    let totalScrollWidth = document.getElementById("scrollWidth").scrollWidth
    let totalScrollWidth_corrected = totalScrollWidth - 2 * 8;    // Remove padding.  see: document.getElementById("scrollWidth").padding
    // totalScrollWidth_corrected = totalScrollWidth_corrected - start_position;    // shifting left for reaching start
    let listLength = this.ListOfImagesForADay.length
    // Explicit variables to represent the time bar.
    //    --> extent and other properties
    let imgFirst                = this.ListOfImagesForADay[ 0 ]
    let imgLast                 = this.ListOfImagesForADay[ this.ListOfImagesForADay.length-1 ]
    let timebarLeft_sec         = imgFirst[ 'timestamp_s' ]
    let timebarRight_sec        = imgLast [ 'timestamp_s' ]

    //Adjust the timebarRight_sec if there is new 360 images
    this.camDataGraph[ this.currentMarkerId + "_360images"].forEach(element => {
        if (timebarRight_sec < element[ 'timestamp_s' ]){
            timebarRight_sec = element[ 'timestamp_s' ];
            this.maxDate = element[ 'timestamp_s' ];
        }
    })

    let timebarInterval_sec     = timebarRight_sec - timebarLeft_sec
    // let timebarSecondsPerPixels = timebarInterval_sec         / totalScrollWidth_corrected
    let timebarPixelsPerSeconds = totalScrollWidth_corrected  / timebarInterval_sec

    var markerContainer = document.getElementById("invisiblediv");
    let scrollerLeft = document.getElementById('scrollWidth').getBoundingClientRect().left;
    let scrollerTop = document.getElementById('scrollWidth').getBoundingClientRect().top;
    let containerLeft = document.getElementById('invisiblediv').getBoundingClientRect().left;
    let scrollerbuttonDistance = scrollerLeft - containerLeft;

    scrollerTop -= 10; // to compensate for height
   // Important to remove the div first, other wise it will always keep on appending markers
   // Create an empy div which will be used for our icons to be attached
   if (document.getElementById("invisiblediv")) {
      document.getElementById("invisiblediv").innerHTML = "";
    }

    markerContainer.setAttribute("style", `position:fixed; left: ${scrollerLeft}px;top: ${scrollerTop}px`);

    let numberOfPreviousPanoMarkers  = 0;
    let MarkersArray360 =  this.camDataGraph[ this.currentMarkerId + "_360images"];

    // Loop over all images and filter the panorama images in order to stick them to
    // a place on top of the time bar.
    MarkersArray360.forEach(element2 => {

        // Variables to represent the position of the panoMarker, both absolute and relative to the time bar.
        let elementRelativeTimestamp_sec = element2[ 'timestamp_s' ] - timebarLeft_sec
        let pixelPositionForThisMarker   = elementRelativeTimestamp_sec * timebarPixelsPerSeconds

        // Insert the div element, which should contain the image.
        var div = document.createElement('div');
        div.setAttribute("style", "height: 50px");
        div.setAttribute("style", "width: 50px");
        div.setAttribute("style", "z-index: 3");
        div.setAttribute("id", "divForImageMarker");

      // Insert the image itself.
      var img = document.createElement('img');
      img.height = 153 * 0.4 // seems to be pixels
      img.width = 113 * 0.4
      img.setAttribute("id", element2['timestamp']);
      img.setAttribute("style", "cursor: pointer");
      img.setAttribute("class", "imageMarkers");
      img.setAttribute("tag2", element2['timestamp_s']);



      // Set the position to be relative to the parent div element.
      let iconWidth = img.width
      let correctedPositionPixel = pixelPositionForThisMarker - (iconWidth * 0.5);
      div.setAttribute("style", `position: absolute; left: ${pixelPositionForThisMarker + 8 }px; margin-left:-${iconWidth * 0.5}px; `);
      numberOfPreviousPanoMarkers += 1

      // Add the event listener to the mouse clicks.
      let that = this
      img.addEventListener('click', function (e) {

        that.currentMarkerId = element2['id']
        that.userInfoService.currentlySelectedMarkerLat = element2['lat']
        that.userInfoService.currentlySelectedMarkerLon = element2['lon']
        that.sliderValue = element2['timestamp_s']

        // Programmatically set the Mat slider to this value
        that._matSlider.value = that.sliderValue

        // Trick: This is used to close the mat slider thumbnail after the user releases the slider
        that._matSlider.blur();
        that.camDataGraph[that.currentMarkerId + "_timesliderObj"] = element2
        that.camDataGraph[that.currentMarkerId + "_indexOfSelectedItem360Marker"] = MarkersArray360.indexOf(element2)
        that.updateMarkersIcons(that, element2['timestamp'])
        that.userInfoService.globalSelectedTimestamp = element2['timestamp']

        that.currentImageUrlEventForPanoView.emit(that.camDataGraph[that.currentMarkerId + "_timesliderObj"]);

      });


      div.appendChild(img);

      var foo = document.getElementById("invisiblediv");
      //Append the element in page (in span).
      foo.appendChild(div);
      var cusid_ele = document.getElementsByClassName('imageMarkers');
      this.updateMarkersIcons(this, element2['timestamp'])

    });
  }



  updateMarkersIcons(context, timestamp) {

    var cusid_ele = document.getElementsByClassName('imageMarkers');

    var arr = [];
    [].push.apply(arr, cusid_ele);

    for (let i = 0; i < cusid_ele.length; i++) {
      var childDiv = cusid_ele[i];
      cusid_ele[i].setAttribute("src", './assets/flaticon/020-panomarker_smooth.png');


      if (this.url.indexOf('siteview') > 0) {

        // Due to SiteViews special behaviour, can be improved in future
        if (this.camDataGraph[this.currentMarkerId + "_timesliderObj"]['timestamp'] == childDiv.id) {
          // console.log("see me")
          console.log(timestamp)
          cusid_ele[i].setAttribute("src", './assets/flaticon/020-panomarker_red_smooth.png');
        }
        else {
          cusid_ele[i].setAttribute("src", './assets/flaticon/020-panomarker_smooth.png');
        }
      }
    }
  }


  public onInputChange(value)
  {
    // console.log('Request sent too soon<----------------------------------')
    if(this.ListOfImagesForADay.length <=1)
    {
      console.log('no more images to scroll throguh!!')
      return;
    }
    let tempTime = this.previousRequestTimestamp
    if((Date.now() - tempTime) < 300)
    {
      return
    }
    this.previousRequestTimestamp = Date.now()
    // console.log(value,'<----------------------------------')


    //TODO:: change the images with smaller size images
    let closestTimestampElement = this.findclosestTimestampElement(this.ListOfImagesForADay, value).closestElement;
    //let imageUrl = this.fetch_image_api     + closestTimestampElement['filename_signed']
    let   imageUrl = this.fetch_thumbnail_api + closestTimestampElement['filename_signed']

    // Convert the image URL into the corresponding thumbnail URL.
    // --> No, this does not work, because the signature is invalid for the changed URL.
    //imageUrl = imageUrl.replace( '/91_officialimage/', '/91_officialimage_width200/' );
    //imageUrl = imageUrl.replace( '_finalImage.jpg',    '_finalImage_width200.jpg'    );

    console.log( 'imageUrl', imageUrl)

    if(this.ListOfImagesForADay.length > 1)
    {
      var div = document.getElementById('thumbnailDiv');

      this.previousRequestedImage = new Image();

      var divLoader = document.getElementById('thumbnailLoader');
      divLoader.classList.add('add-overlay');
      divLoader.style.visibility = 'visible';

      this.previousRequestedImage.addEventListener('load', function() {
        div.style.backgroundImage = 'url(' + imageUrl + ')';
        divLoader.style.visibility = 'hidden';
        divLoader.classList.remove('add-overlay');
      });
    }
   this.previousRequestedImage.src = imageUrl;
    console.log('Setting the --current-thumbnail','url('+this.previousRequestedImage+')');

    this.cdr.detectChanges()

  }


  public sliderChanged(value: any ): void {
    // public sliderChanged(value: any , event): void {

    // optimal for calling less events
    if(this.sliderValue !== value)
    {
      let closestTimestampElement = this.findclosestTimestampElement(this.ListOfImagesForADay, value).closestElement;
      let closestTimestampElementIndex = this.findclosestTimestampElement(this.ListOfImagesForADay, value).closestElementIndex;
      console.log('__________________closestTimestampElement', closestTimestampElement, value)

      this.sliderValue = closestTimestampElement['timestamp_s']
      this.camDataGraph[this.currentMarkerId + "_timesliderObj"] = closestTimestampElement
      this.currentImageName = closestTimestampElement['filename']
      this.currentImageUrl = this.fetch_image_api + closestTimestampElement['filename_signed']
      this.camDataGraph[this.currentMarkerId + "_timesliderObj"]['complete_url'] = this.currentImageUrl
      console.log('__________________currentImageUrl', this.currentImageUrl)
      // console.log('this is the val----------',this.camDataGraph[this.currentMarkerId + "_timesliderObj"])
      this.userInfoService.globalSelectedTimestamp = this.camDataGraph[this.currentMarkerId + "_timesliderObj"]['timestamp']

      this.currentImageUrlEventForImageGallery.emit(this.camDataGraph[this.currentMarkerId + "_timesliderObj"]);

      // Programmatically set the Mat slider to this value
      this._matSlider.value = this.sliderValue

      // Trick: This is used to close the mat slider thumbnail after the user releases the slider
      this._matSlider.blur();
      this.routeToImageGallery()
    }

  }

  public UpdateSlider() {
    this.camDataGraph[this.currentMarkerId + "_timesliderObj"]
    this.sliderValue = this.camDataGraph[this.currentMarkerId + "_timesliderObj"]['timestamp_s']

  }
  public ChangeSlider(value) {


    console.log("Change slider was called")
    console.log(value)
    console.log('_____________ this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"]',this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"])
    console.log('_____________ this.currentMarkerId',this.currentMarkerId)
    console.log('_____________ this.camDataGraph',this.camDataGraph)

    if (this.url.indexOf('siteview') > 0) {

      // Decrement case
      if (value === -1 && this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem360Marker"] === 0) {
        return
      }
      // Increment case
      if (value === 1 && this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem360Marker"] === this.camDataGraph[this.currentMarkerId + "_360images"].length - 1) {

        return
      }

      let nextItemIndex = this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem360Marker"] + value
      this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem360Marker"] = nextItemIndex
      let nextItemElement = this.camDataGraph[this.currentMarkerId + "_360images"][nextItemIndex]
      this.sliderValue = nextItemElement['timestamp_s']
      this.camDataGraph[this.currentMarkerId + "_timesliderObj"] = nextItemElement
      this.currentImageName = nextItemElement['filename']
      this.currentImageUrl = this.fetch_image_api + nextItemElement['filename_signed']
      nextItemElement['complete_url'] = this.currentImageUrl
      this.userInfoService.globalSelectedTimestamp = nextItemElement['timestamp']
      this.currentImageUrlEventForImageGallery.emit(nextItemElement);
      this._matSlider.value = this.sliderValue
      this._matSlider.blur();
      this.camDataGraph[this.currentMarkerId + "_timesliderObj"] = nextItemElement
      // this.routeToImageGallery()
      this.updateMarkersIcons(this, nextItemElement['timestamp'])
      // return
    }

    // For Image Gallery
    else{

       // Decrement case
       if (value === -1 && this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] === 0) {
        return
      }
      // Increment case
      if (value === 1 && this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] === this.camDataGraph[this.currentMarkerId].length - 1) {

        return
      }


    // console.log("Slider Incremented")
    let nextItemIndex = this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] + value
    console.log('_____________ nextItemIndex',nextItemIndex)

    this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] = nextItemIndex
    let nextItemElement = this.camDataGraph[this.currentMarkerId][nextItemIndex]
    this.sliderValue = nextItemElement['timestamp_s']
    this.camDataGraph[this.currentMarkerId + "_timesliderObj"] = nextItemElement
    this.currentImageName = nextItemElement['filename']
    this.currentImageUrl = this.fetch_image_api + nextItemElement['filename_signed']
    nextItemElement['complete_url'] = this.currentImageUrl
    this.userInfoService.globalSelectedTimestamp = nextItemElement['timestamp']
    this.currentImageUrlEventForImageGallery.emit(nextItemElement);
    this._matSlider.value = this.sliderValue
    this._matSlider.blur();
    this.camDataGraph[this.currentMarkerId + "_timesliderObj"] = nextItemElement
    }

  }


  public routeToImageGallery()
{

    // THIS part can be improved....
    const url = this.router.url;
    // this is component is loaded in SiteView so on slider drag we reoute back to image gallery
    if (url.indexOf('siteview') > 0) {

      //Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['imagegallery'], { queryParams: {site_id: this.userInfoService.siteId , camera_id:this.currentMarkerId } });

    }
    // this.ngAfterViewInit();


}
  findclosestTimestampElement(data, target) {
    if (data === undefined || data.length == 0) {
      // array empty or does not exist
      return {}
  }
    // console.log(" Method: Will find the closest value")
    // find the difference between all the numbers and whichever one is closest to zero will be the nearestObjectwrtTimeStamp
    let closestElement = data.reduce((acc, obj) =>
      Math.abs(target - obj.timestamp_s) < Math.abs(target - acc.timestamp_s) ? obj : acc
    );
    // console.log("indexOfSelectedItem timestamp")
    // console.log(data)
    // console.log(closestElement)
    let closestElementIndex = data.indexOf(closestElement)
    this.camDataGraph[this.currentMarkerId + "_indexOfSelectedItem"] =  closestElementIndex
    return {closestElement, closestElementIndex}
  }

  formatLabel = (value: number | null) => {

      if(this.userInfoService && this.userInfoService.siteTimeZone !== undefined)
    {
      var datetime = moment(new Date(value * 1000))
      var timeString = datetime.clone().tz(this.userInfoService.siteTimeZone).format("DD.MM.YYYY HH:mm:ss")
      return timeString.toString().slice(11,19)
    }
  return new Date(value * 1000).toLocaleTimeString("en-GB");

 }



}
