import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { UserInfoService } from '../_services/user-info.service';
import * as util from '../_helper/util'
import { DataServiceLocal } from '../_services/local_data.service';
import { DataService } from '../_services/data.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class SideBarComponent implements OnInit {
  selectedItem = '';

  isExpanded: any;
  innerWidth: Number;
  current_url;
  isDashboardOpen;
  isTilosplanopened;

  planMSProjectExists
  planSiteExists
  planTilosExists
  currentSiteID
  dataService
  kibanaAccess: boolean = false;
  specialKibanaAccess: boolean = false;
  kibanaURL: string = "https://analytics-oidc.abaut.de/s/";

  currentScreenMode



  constructor(
    private router: Router,
    private authService: AuthService,
    private siteAssociatedData : SiteAssociatedData,
    private userInfoService: UserInfoService,
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal
     ) {



    // console.log("Your Environment:")
    // console.log(environment.environmentName)
    // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
    if (environment['environmentName'] ===  'local-development') {
      this.dataService =  dataServiceLocal;
    }
    else{
      this.dataService =  dataServiceRemote;
    }


      this.currentSiteID = this.userInfoService.siteId;
      this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    }



    // TODO this is called everytime a view is checked, is there a better way to hide side bar elements??

    ngAfterViewChecked() {
    if (this.siteAssociatedData.planMSProjectExists) this.planMSProjectExists = true;
    if (this.siteAssociatedData.planSiteExists) this.planSiteExists = true;
    if (this.siteAssociatedData.planTilosExists) this.planTilosExists = true;
    }



    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    }




  ngOnInit() {

    this.getSiteConfigData();
    this.getKibanaSpecialUrl();


    this.current_url = this.router.url
    if (this.current_url  == '/dashboard'){
      this.isDashboardOpen = true;
    }
    else{
      this.isDashboardOpen = true;
    }
    if (this.current_url  == '/tilosplan'){
      this.isTilosplanopened = true;
    }
    else{
      this.isTilosplanopened = true;
    }
    if (window.innerWidth < 768) {
      this.isExpanded = false;
    } else {
      this.isExpanded = true;
    }

  }

  toggle_sidebar() {
    this.isExpanded = !this.isExpanded
  }

  logout() {
    this.authService.singleSignout()


  }

  getSiteConfigData(): void{
    this.dataService.getSiteConfigData(this.userInfoService.siteId).subscribe((data) => {
      this.kibanaAccess = data.SiteView_ActivateAccess_toKibana
      this.userInfoService.siteTimeZone = data.timezone;});
    this.userInfoService.kibanaAccess = this.kibanaAccess;
  }

  getKibanaSpecialUrl(): void{
    let specialAccounts: Array<string> = this.userInfoService.kibanaSpecialAccounts;
    this.specialKibanaAccess = specialAccounts.includes(this.currentSiteID);
    if(this.specialKibanaAccess){
      let index: number = this.userInfoService.kibanaSpecialAccounts.indexOf(this.currentSiteID);
      let specialURls = this.userInfoService.kibanaSpecialURL;
      this.kibanaURL = specialURls[index];
    }
    else{
      this.kibanaURL = this.kibanaURL.concat(this.currentSiteID).toLocaleLowerCase();
    }
    // console.log(`special: ${this.specialKibanaAccess} site ID: ${this.currentSiteID}`);
  }

}
