import { TranslateService } from '@ngx-translate/core';
import { CameraFilterService } from './../../_services/cameraFilter.service';
import {Component, ElementRef, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { SiteAssociatedData } from '../../_services/site-associated-data.service';
import {UserInfoService} from '../../_services/user-info.service';
import { DataServiceLocal } from '../../_services/local_data.service';
import { DataService } from '../../_services/data.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import {element, error} from 'protractor';
import { stringify } from 'querystring';
import { of } from 'rxjs';

@Component({
  selector: 'app-mini-side-bar',
  templateUrl: './mini-side-bar.component.html',
  styleUrls: ['./mini-side-bar.component.scss'],
  host:  {
    '(document:click)': 'onClick($event)',
  },
})
export class MiniSideBarComponent implements OnInit {
  @Input() defaultCamera = []
  @Output() toggleLagePlanLayer = new EventEmitter<string>();
  @Output() toggleMachineBox = new EventEmitter<boolean>();
  @Output() toggleDroneLayer = new EventEmitter<string>();

  layerCategories: any[]
  public dataService

  constructor(private siteAssociatedData: SiteAssociatedData,
              public _eref: ElementRef,
              private router: Router,
              private userInfoService: UserInfoService,
              private cameraFilter : CameraFilterService,
              public translate: TranslateService,
              dataServiceRemote: DataService,
              dataServiceLocal: DataServiceLocal , ) {


    if (environment.environmentName ===  'local-development') {
      this.dataService =  dataServiceLocal;
    }
    else{
      this.dataService =  dataServiceRemote;
    }

    this.layerCategories = [
      {
        display_name: 'txtSV_contextMenu_layers_Satellite',
        name: 'satllite',
        upload: false,
        processing: false,
        processingFiles: [],
        next: false,
        selectable: true,
      },
      {
        display_name: 'txtSV_contextMenu_layers_Lageplan',
        name: 'lageplan',
        upload: true,
        processing: (this.siteAssociatedData.listProcessingFiles.SITEPLAN.length>0),
        processingFiles: this.siteAssociatedData.listProcessingFiles.SITEPLAN,
        next: false,
        selectable: siteAssociatedData.mapSurveyExists === true,
      },
      {
        display_name: 'txtSV_contextMenu_layers_Drone',
        name: 'drohne',
        upload: true,
        processing: (this.siteAssociatedData.listProcessingFiles.DRONE_MAP.length>0),
        processingFiles: this.siteAssociatedData.listProcessingFiles.DRONE_MAP,
        next: false,
        selectable: siteAssociatedData.mapDroneExists === true,
      },
    ];
  }

  // Icons urls

  layersLogo='./assets/layers_white.png'
  layersLogoWhite='./assets/layers_white.png'
  layersLogoRed='./assets/layers_red.png'

  terminplanungLogo='./assets/gantt-diagramm_white.png'
  terminplanungLogoWhite='./assets/gantt-diagramm_white.png'
  terminplanungLogoRed='./assets/gantt-diagramm_red.png'

  ressourcenLogo='./assets/konnektivitat_white.png'
  ressourcenLogoWhite='./assets/konnektivitat_white.png'
  ressourcenLogoRed='./assets/konnektivita_red.png'

  errorIconSide = './assets/error.png'
  errorIcon = ''
  errorBoxShow = false;
  errorTitle = ''
  errorCategory = ''
  errorBoxSide = './assets/error.png'
  errorDrohneIcon = './assets/drohne.png'
  errorDrohneText = 'txtSV_contextMenu_layers_undifined_drone'
  errorPlanIcon = './assets/plan_white.png'
  errorPlanText = 'txtSV_contextMenu_layers_undifined_lagplan'

  successfulBoxShown = false;
  successfulIcon = './assets/plan_white.png';
  successfulIconSide = './assets/erfolg.png'
  successfulText = 'txtSV_upload_panel_upload_success';

  // layer_hover: boolean = false;
  layerHidden = true;
  terminplanungHidden = true;
  ressourcenHidden = true;
  maschinentypenHidden = true;
  uploadHidden = true;
  uploadTitle = '';
  uploadBoxDetails: any = {};
  planSiteExists;
  // Satellit: any = true;
  // Lageplan: any = false;
  // Drohne: any = false;
  selectedLayerCategory: any = null;

  selectedTerminplanungCategory: any = null;
  terminplanungCategories: any[] = [
    {
      display_name: 'txtSV_contextMenu_graph_bardiagram',
      name: 'projectplan',
      exists: true,
      upload: true,
      processing: (this.siteAssociatedData.listProcessingFiles.BAR_CHART.length>0),
      processingFiles: this.siteAssociatedData.listProcessingFiles.BAR_CHART,
      next: false,
    },
    {
      display_name: 'txtSV_contextMenu_graph_timediagram',
      name: 'wegplan',
      exists: true,
      upload: true,
      processing: (this.siteAssociatedData.listProcessingFiles.TIME_DISTANCE_CHART.length>0),
      processingFiles: this.siteAssociatedData.listProcessingFiles.TIME_DISTANCE_CHART,
      next: false,
    },
  ];

  selectedRessourcenCategory: any = null;
  ressourcenCategories: any[] = [
    {
      display_name: 'txtSV_contextMenu_reality_camera',
      name: 'kamera',
      filter_tag : 'ft_cam_static',
      sub_filter_tags:[],
      exists: true,
      upload: false,
      processing: false,
      processingFiles: [],
      checked : true,
      disabled : true,
      next: false,
    },
    {
      display_name: 'txtSV_contextMenu_reality_crane_camera',
      name: 'krankamera',
      filter_tag : 'ft_machine_crane+cam',
      sub_filter_tags:[],
      exists: true,
      upload: false,
      processing: false,
      processingFiles: [],
      checked : true,
      disabled : true,
      next: false,
    },
    {
      display_name: 'txtSV_contextMenu_reality_pan_outdoor',
      name: 'outdoor',
      filter_tag : 'ft_cam_handheld_pano_outdoor',
      sub_filter_tags:[],
      exists: true,
      upload: true,
      processing: (this.siteAssociatedData.listProcessingFiles.PANORAMA_IMAGE_OUTDOOR.length>0),
      processingFiles: this.siteAssociatedData.listProcessingFiles.PANORAMA_IMAGE_OUTDOOR,
      checked : true,
      disabled : true,
      next: false,
    },
    {
      display_name: 'txtSV_contextMenu_reality_drone',
      name: 'drohnenaufnahmen',
      filter_tag : 'ft_cam_mobile_drone',
      sub_filter_tags:[],
      exists: true,
      upload: true,
      processing: (this.siteAssociatedData.listProcessingFiles.DRONE_IMAGE.length>0),
      processingFiles: this.siteAssociatedData.listProcessingFiles.DRONE_IMAGE,
      checked : true,
      disabled : true,
      next: false,
    },
    {
      display_name: 'txtSV_contextMenu_reality_capture',
      name: 'bildaufnahmen',
      filter_tag : 'ft_cam_handheld_normal',
      sub_filter_tags:[],
      exists: true,
      upload: true,
      processing: (this.siteAssociatedData.listProcessingFiles.SMARTPHONE_IMAGE.length>0),
      processingFiles: this.siteAssociatedData.listProcessingFiles.SMARTPHONE_IMAGE,
      checked : true,
      disabled : true,
      next: false,
    },
    {
      display_name: 'txtSV_contextMenu_reality_machine',
      name: 'maschinen',
      filter_tag : 'ft_machine',
      sub_filter_tags: ['ft_machine_excavator+cam', 'ft_machine_wheelloader+cam', 'ft_machine_truck+cam', 'ft_machine_dumper+cam', 'ft_machine_articulated_truck+cam'],
      exists: true,
      upload: false,
      processing: false,
      processingFiles: [],
      checked : true,
      disabled : true,
      next: true,
    },
  ];

  selectedMaschinentypenCategory: any = null;
  maschinentypenCategories: any[] = [
    {
      display_name: 'X',
      name: 'X',
      exists: true
    },
    {
      display_name: 'Y',
      name: 'Y',
      exists: true
    },
    {
      display_name: 'Z',
      name: 'Z',
      exists: true
    }
  ];

  uploadNotification = false;
  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)){
      this.closeAll();
      // console.log("********************************************************");
    } // or some similar check
      // this.onCloseError()
  }

  ngOnInit(): void {
    this.selectedLayerCategory = this.layerCategories[0];
    // $('#Layers').tooltip();
    this.ressourcenCategories.forEach(element => {
      if (this.defaultCamera.includes(element.filter_tag)){
        element.checked = true
        element.disabled = false
      } else if(element.sub_filter_tags.length !== 0 && this.containsSubTag(element, this.defaultCamera)) {
        element.checked = true
        element.disabled = false
      } else{
        element.checked = false
        element.disabled = true
      }
    });
    this.checkMachines()
    let filters = this.ressourcenCategories.filter(e => e.checked).map(e=> e.filter_tag)
    filters = this.addIfMachineCamExist(filters)
    filters.push(null)
    this.cameraFilter.changeCameraTags(filters)
    // this.translate.get('txtSV_upload_panel_upload_success').subscribe((res: string) => { this.successfulText=res });
  }

  containsSubTag(element, cams) {
    let res = false
    element.sub_filter_tags.map(filter_tag => {
      if(cams.includes(filter_tag)) {
        res = true
      }
    })
    return res
  }

  addIfMachineCamExist(filters) {
    let newFilter = filters
    this.ressourcenCategories.map(e => {
      if(e.checked && e.sub_filter_tags.length !== 0) {
        newFilter = newFilter.concat(e.sub_filter_tags)
      }
    })

    return newFilter
  }

  checkMachines() {
    this.dataService.getMachinePositions(this.userInfoService.siteId).subscribe(machines => {
      if(machines !== undefined && machines.machines !==undefined && machines.machines !== null && machines.machines.length !== 0) {
        this.ressourcenCategories[this.ressourcenCategories.length - 1].checked = true
        this.ressourcenCategories[this.ressourcenCategories.length - 1].disabled = false
      }
    })
  }

  ngAfterViewChecked() {
    if (this.siteAssociatedData.planSiteExists)
      this.layerCategories[1].exists = true;
      this.layerCategories[2].exists = true;

      this.layerCategories[1].processing = this.siteAssociatedData.listProcessingFiles.SITEPLAN.length>0
      this.layerCategories[1].processingFiles = this.siteAssociatedData.listProcessingFiles.SITEPLAN
      this.layerCategories[2].processing = this.siteAssociatedData.listProcessingFiles.DRONE_MAP.length>0
      this.layerCategories[2].processingFiles = this.siteAssociatedData.listProcessingFiles.DRONE_MAP

      this.terminplanungCategories[0].processing = this.siteAssociatedData.listProcessingFiles.BAR_CHART.length>0
      this.terminplanungCategories[0].processingFiles = this.siteAssociatedData.listProcessingFiles.BAR_CHART
      this.terminplanungCategories[1].processing = this.siteAssociatedData.listProcessingFiles.TIME_DISTANCE_CHART.length>0
      this.terminplanungCategories[1].processingFiles = this.siteAssociatedData.listProcessingFiles.TIME_DISTANCE_CHART

      this.ressourcenCategories[2].processing = this.siteAssociatedData.listProcessingFiles.PANORAMA_IMAGE_OUTDOOR.length>0
      this.ressourcenCategories[2].processingFiles = this.siteAssociatedData.listProcessingFiles.PANORAMA_IMAGE_OUTDOOR
      this.ressourcenCategories[3].processing = this.siteAssociatedData.listProcessingFiles.DRONE_IMAGE.length>0
      this.ressourcenCategories[3].processingFiles = this.siteAssociatedData.listProcessingFiles.DRONE_IMAGE
      this.ressourcenCategories[4].processing = this.siteAssociatedData.listProcessingFiles.SMARTPHONE_IMAGE.length>0
      this.ressourcenCategories[4].processingFiles = this.siteAssociatedData.listProcessingFiles.SMARTPHONE_IMAGE
  }

  closeAll() {
    this.layerHidden = true;
    this.terminplanungHidden = true;
    this.ressourcenHidden = true;
    this.maschinentypenHidden = true;
    this.errorBoxShow = false;
    this.successfulBoxShown = false;
    document.getElementById('Layers-img').classList.remove('open');
    document.getElementById('Terminplanung-img').classList.remove('open');
    document.getElementById('Ressourcen-img').classList.remove('open');
  }

  onLayerCheckboxChange(event, category) {
    console.log('----check box changed----', event.target.checked, category)
    if(!category.selectable) {
      if(category.name === 'lageplan') {
        this.errorIcon = this.errorPlanIcon
        this.errorTitle = this.errorPlanText
        // this.translate.get(this.errorPlanText).subscribe((res: string) => { this.errorTitle=res });
        event.target.checked = false
        this.errorBoxShow = true
      } else if(category.name === 'drohne') {
        this.errorIcon = this.errorDrohneIcon
        this.errorTitle = this.errorDrohneText
        // this.translate.get(this.errorDrohneText).subscribe((res: string) => { this.errorTitle=res });
        event.target.checked = false
        this.errorBoxShow = true
      }
    } else {
      if(category.name === 'lageplan') {
        this.toggleLagePlanLayer.emit(event.target.checked ? 'show' : 'hide');
      } else if(category.name === 'drohne') {
        this.toggleDroneLayer.emit(event.target.checked ? 'show' : 'hide')
      }
    }
  }

  // onCloseError() {
  //   this.errorBoxShow = false
  // }

  // onCloseSuccessful() {
  //   this.successfulBoxShown = false
  // }

  /// TODO: menu and info box close together
  onCloseInfo(){
    let opened = '';
    if(!this.layerHidden){
      opened = 'layer';
    }
    if(!this.terminplanungHidden){
      opened = 'termin';
    }
    if(!this.ressourcenHidden){
      opened = 'ressourcen';
    }
    if(!this.maschinentypenHidden){
      opened = 'maschinen';
    }

    this.errorBoxShow = false;
    this.successfulBoxShown = false;

    switch(opened){
      case 'layer':
        this.layerHidden = false;
        break;
      case 'termin':
        this.terminplanungHidden = false;
        break;
      case 'ressourcen':
        this.ressourcenHidden = false;
        break;
      case 'maschinen':
        this.maschinentypenHidden = false;
        break;
    }

    console.log(this.layerHidden);
  }

  open(menu: string) {
    if (menu == 'layer') {
      this.layerHidden = !this.layerHidden;
      this.terminplanungHidden = true;
      this.ressourcenHidden = true;
      this.maschinentypenHidden = true;
      document.getElementById('Terminplanung-img').classList.remove('open');
      document.getElementById('Ressourcen-img').classList.remove('open');
      document.getElementById('Layers-tab').classList.add('hidden');
      if (!this.layerHidden) {
        document.getElementById('Layers-img').classList.add('open');
      } else {
        document.getElementById('Layers-img').classList.remove('open');
      }
    }
    if (menu == 'terminplanung') {
      this.terminplanungHidden = !this.terminplanungHidden;
      this.layerHidden = true;
      this.ressourcenHidden = true;
      this.maschinentypenHidden = true;
      document.getElementById('Layers-img').classList.remove('open');
      document.getElementById('Ressourcen-img').classList.remove('open');
      document.getElementById('Terminplanung-tab').classList.add('hidden');
      if (!this.terminplanungHidden) {
        document.getElementById('Terminplanung-img').classList.add('open');
      } else {
        document.getElementById('Terminplanung-img').classList.remove('open');
      }
    }
    if (menu == 'ressourcen') {
      this.ressourcenHidden = !this.ressourcenHidden;
      this.layerHidden = true;
      this.terminplanungHidden = true;
      this.maschinentypenHidden = true;
      document.getElementById('Layers-img').classList.remove('open');
      document.getElementById('Terminplanung-img').classList.remove('open');
      document.getElementById('Ressourcen-tab').classList.add('hidden');
      if (!this.ressourcenHidden) {
        document.getElementById('Ressourcen-img').classList.add('open');
      } else {
        document.getElementById('Ressourcen-img').classList.remove('open');
      }
    }
    if (menu == 'maschinentypen') {
      this.maschinentypenHidden = !this.maschinentypenHidden;
      this.layerHidden = true;
      this.terminplanungHidden = true;
      this.ressourcenHidden = true;
      document.getElementById('Layers-img').classList.remove('open');
      document.getElementById('Terminplanung-img').classList.remove('open');
      document.getElementById('Ressourcen-img').classList.remove('open');
    }
  }

  upload(title: string, state: string, details?: object): void{
    console.log('----------------upload-----------------', state)
    if(state === 'true'){
      this.uploadHidden = false;
    }
    if(state === 'false'){
      this.uploadHidden = true;
    }
    if(state === 'save') {
      this.uploadHidden = true;
      this.uploadTitle = '';
      if(this.uploadBoxDetails !== undefined) {
        switch (this.uploadBoxDetails.name) {
          case 'lageplan':
            this.successfulText = 'txtSV_upload_panel_siteplan_process'
            //this.layerCategories[1].processing = true;
            break;
          case 'drohne':
            this.successfulText = 'txtSV_upload_panel_dronemap_process'
            break;
          case 'projectplan':
            this.successfulText = 'txtSV_upload_panel_barchart_process'
            break;
          case 'wegplan':
            this.successfulText = 'txtSV_upload_panel_timechart_process'
            break;
          case 'outdoor':
            this.successfulText = 'txtSV_upload_panel_panoutdoor_process'
            break;
          case 'drohnenaufnahmen':
            this.successfulText = 'txtSV_upload_panel_droneimg_process'
            break;
          case 'bildaufnahmen':
            this.successfulText = 'txtSV_upload_panel_smartphoneimg_process'
            break;
        }
      }
      console.log('--------------------successful text---------------------', this.successfulText)
      setTimeout( () => {
        this.uploadNotification = false
        this.successfulBoxShown = true;
        console.log('Successfully Uploaded File');
      }, 1000 );

    }

    this.uploadTitle = title;
    this.uploadBoxDetails = details;
  }

  filterCamera(category){

    console.log('-----------category-----------------', category)

    const currentCameraType = this.ressourcenCategories.find(element => category.name == element.name)
    currentCameraType.checked = !currentCameraType.checked
    if(currentCameraType.name === 'maschinen') {
      this.toggleMachineBox.emit(!currentCameraType.checked)
    }
    let filters = this.ressourcenCategories.filter(e => e.checked).map(e=> e.filter_tag)
    filters = this.addIfMachineCamExist(filters)
    filters.push(null)
    this.cameraFilter.changeCameraTags(filters)
  }


  receiveUploadNotificationStatus($event) {
    this.uploadNotification = $event
  }

  receiveShowUploadDialog($event){
    this.uploadHidden = $event;
  }

  processingComplete(files){
    this.dataService.setProcessingFiles(this.userInfoService.currentSiteId, files, "COMPLETED").subscribe( data => {
      console.log(this.siteAssociatedData.listProcessingFiles)
    })
  }
}
