import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { DataServiceLocal } from '../../_services/local_data.service';
import { DataService } from '../../_services/data.service';
import {environment} from '../../../environments/environment';
import {UserInfoService} from '../../_services/user-info.service';
import { SiteAssociatedData } from 'src/app/_services/site-associated-data.service';
import {DomSanitizer} from '@angular/platform-browser';
import {HelperService} from '../../_services/helper.service';

export interface Details {
  name?: string;
  display_name?: string;
  exists?: boolean;
  upload?: boolean;
  next?: boolean;
}

@Component({
  selector: 'app-upload-window',
  templateUrl: './upload-window.component.html',
  styleUrls: ['./upload-window.component.scss'],
  host:  {
    '(document:click)': 'onClick($event)',
  },
})

export class UploadWindowComponent implements OnInit {

  @Input() title: string;
  @Input() details: Details;
  @Input() parentRef: ElementRef;
  @Output() closeWindow = new EventEmitter<string>();
  @Output() closeUpload = new EventEmitter<string>();
  @Output() showUploadNotifcation = new EventEmitter<boolean>();
  @Output() showUploadDialog = new EventEmitter<boolean>();

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  public dataservice;
  @Input() uploadHidden: boolean;
  files: any[] = [];
  savedFiles: any[] = [];
  // clickIceBreaker = 0;
  empty = true;

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if((!this.eRef.nativeElement.contains(event.target))) {
  //     this.close('toggle');
  //   }
  // }

  constructor(
    private eRef: ElementRef,
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal,
    private userInfoService: UserInfoService,
    private siteAssociatedData: SiteAssociatedData,
    private helperService: HelperService,
    private sanitizer: DomSanitizer
  ) {
    console.log('Your Environment:')
    console.log(environment.environmentName)
    // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
    if (environment.environmentName ===  'local-development') {
      this.dataservice =  dataServiceLocal;
    }
    else{
      this.dataservice =  dataServiceRemote;
    }
  }

  onClick(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.parentRef.nativeElement.contains(event.target) && !this.uploadHidden && this.empty){
      this.close('false');
      // console.log("************************uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu********************************");
    } // or some similar check
  }

  ngOnInit(): void {
    console.log('------------------', this.details)
  }

  ngOnChanges(): void{
    // get new data from BE and check
      if(this.savedFiles.length === 0){
        this.files = [];
        this.empty = true;
      }
      else{
        this.files[0] = this.savedFiles[0];
        this.empty = false;
      }
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  prepareFilesList(files: Array<any>) {
    console.log('uploaded files.......', files)
    const re = /(?:\.([^.]+))?$/;
    for (const item of files) {
      console.log('Uploaded file extension...........', re.exec(item.name)[1])
      if(!this.helperService.extensions.exec.includes(re.exec(item.name)[1]) &&
        !this.helperService.extensions.code.includes(re.exec(item.name)[1]) &&
        !this.helperService.extensions.web.includes(re.exec(item.name)[1])
      ) {
        this.files.push(item)
        this.empty = false;
      }
    }
    // this.fileDropEl.nativeElement.value = "";
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    if(this.files.length === 0) {
      this.empty = true;
    }
  }

  deleteAll() {
    this.files = []
    this.empty = true;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  close(status: string) {
    this.deleteAll();
    this.closeWindow.emit(status);
  }

  saveFile() {
    // send new data from BE
    const now = new Date;
    console.log('------------------', this.details)

    const planName = this.details?.name
    // const fileExtension:string = this.files[0].name.split('?')[0].split('.')[1];
    // const fileName = this.files[0].name.split('?')[0].split('.')[0] + '_uploaded_' + now.getTime();
    //
    // const name = fileName + '.' + fileExtension

    console.log('----------all file names-----------', this.getFileNames())
    this.showUploadNotifcation.emit(true)
    this.showUploadDialog.emit(true)

    const siteName = this.helperService.getSiteNameById(this.userInfoService.sitesWithConfig, this.userInfoService.currentSiteId)

    this.dataservice.uploadPlan(this.files, this.getFileNames(), this.userInfoService.currentSiteId, planName, siteName).subscribe(data => {
      console.log('Response from the Backend:');
      console.log(data);
      this.close('save');
      this.getProcessingFiles()
    })
    // if(this.files.length !== 0){
    //   this.savedFiles[0] = this.files[0];
    //   this.close('save'); // add to main function!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // }
    // else{
    //   this.close('false');
    // }
  }

  getFileNames() {
    const fileNames = []
    for (const file in this.files) {
      if(this.files.hasOwnProperty(file)) {
        const now = new Date;
        const fileExtension:string = this.files[file].name.substring(this.files[file].name.lastIndexOf('.')+1, this.files[file].name.length);
        const fileName = this.files[file].name.substring(0, this.files[file].name.lastIndexOf('.')) + '_uploaded_' + this.helperService.toAbautDateFormat(now);

        fileNames.push(fileName + '.' + fileExtension)
      }
    }
    return fileNames
  }

  getImgSrc(file) {
    console.log('-----image to show-----', file)

    if(file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
      return this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
    } else {
      return '../../../assets/plan_white.png'
    }
  }

  getRemainingSize() {
    let  size=0
    for(let i=2;i<this.files.length;i++) {
      size += this.files[i].size
    }
    return this.formatBytes(size)
  }

  getProcessingFiles(){
    this.dataservice.getProcessingFiles(this.userInfoService.currentSiteId).subscribe(data => {
      this.siteAssociatedData.listProcessingFiles = data
    })
  }

}
