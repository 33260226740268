import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CameraFilterService {
  private tagsSource = new BehaviorSubject(null);
  currentTags = this.tagsSource.asObservable();

  constructor() { }

  changeCameraTags(tags: string[]) {
    this.tagsSource.next(tags)
  }

}
