import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { UserInfoService } from './user-info.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService , private userInfoService: UserInfoService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.userInfoService.userLoggedin === true) {
      console.log("Auth Guard intercepted Username not null")
      return true;
    } else {
      console.log("Auth Guard intercepted Username NULL")

      this.authService.singleSignout()

       }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.userInfoService.userLoggedin === true) {
      return true;
    } else {
      this.authService.singleSignout()

    }
  }
}
