import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { appRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { MatDividerModule } from '@angular/material/divider';
import { SidebarModule } from 'ng-sidebar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './_services/auth.interceptor';
import { AppComponent } from './app.component';
import { ReportsComponent } from './reports/reports.component';
import { SiteviewComponent } from './siteview/siteview.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MapViewerComponent } from './map-viewer/map-viewer.component';
import { ImagePlan1Component } from './image-plan1/image-plan1.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { TimeSliderComponent } from './time-slider/time-slider.component';
import { DateCalendarComponent } from './date-calendar/date-calendar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
  import {MatButtonModule, } from '@angular/material/button';
  import { MatButtonToggleModule } from '@angular/material/button-toggle';
  import { MatCardModule } from '@angular/material/card';
  import { MatCheckboxModule } from '@angular/material/checkbox';
  import { MatChipsModule } from '@angular/material/chips';
  import { MatDatepickerModule } from '@angular/material/datepicker';
  import { MatDialogModule } from '@angular/material/dialog';
  import { MatExpansionModule } from '@angular/material/expansion';
  import { MatGridListModule } from '@angular/material/grid-list';
  import { MatIconModule } from '@angular/material/icon';
  import { MatInputModule } from '@angular/material/input';
  import { MatListModule } from '@angular/material/list';
  import { MatMenuModule } from '@angular/material/menu';
  import {MatNativeDateModule} from '@angular/material/core';
  import { MatProgressBarModule } from '@angular/material/progress-bar';
  import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
  import { MatRadioModule } from '@angular/material/radio';
  import { RadioButtonModule } from 'primeng/radiobutton';
  import { MatRippleModule } from '@angular/material/core';
  import { MatSelectModule } from '@angular/material/select';
  import { MatSidenavModule } from '@angular/material/sidenav';
  import { MatSliderModule } from '@angular/material/slider';
  import { MatSlideToggleModule } from '@angular/material/slide-toggle';
  import { MatSnackBarModule } from '@angular/material/snack-bar';
  import { MatStepperModule } from '@angular/material/stepper';
  import { MatTableModule } from '@angular/material/table';
  import { MatTabsModule } from '@angular/material/tabs';
  import { MatToolbarModule } from '@angular/material/toolbar';
  import { MatTooltipModule } from '@angular/material/tooltip';
import { TopLeftToolbarComponent } from './top-left-toolbar/top-left-toolbar.component';
  import {SidebarModule as PSidebarModule} from 'primeng/sidebar';
  import {ButtonModule} from 'primeng/button';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { MapPanelComponent } from './components/map-panel/map-panel.component';
import { SlidebarComponent } from './components/slidebar/slidebar.component';
import { TopRightProfileComponent } from './top-right-profile/top-right-profile.component';
import { MiniSideBarComponent } from './components/mini-side-bar/mini-side-bar.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { UploadWindowComponent } from './components/upload-window/upload-window.component';
import { DndDirective } from './directives/drag_and_drop/dnd.directive';
import { TopRightFutureBarComponent } from './top-right-future-bar/top-right-future-bar.component';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import {MatPaginatorModule} from '@angular/material/paginator';
// import { DropdownModule } from 'primeng/dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { AnalyticsBoxComponent } from './components/analytics-box/analytics-box.component';
import { TimelapseComponent } from './components/timelapse/timelapse.component';
import { TimelapseHistoryComponent, TimelapseHistoryDetailsDialogComponent, TimelapseHistoryVideoDialogComponent } from './components/timelapse-history/timelapse-history.component';
import { ImageIframeComponent } from './image-iframe/image-iframe.component'; 


@NgModule({
  declarations: [
    AppComponent,
    ReportsComponent,
    SiteviewComponent,
    SideBarComponent,
    MapViewerComponent,
    ImagePlan1Component,
    ImageGalleryComponent,
    TimeSliderComponent,
    DateCalendarComponent,
    DashboardComponent,
    TopLeftToolbarComponent,
    TopBarComponent,
    MapPanelComponent,
    SlidebarComponent,
    TopRightProfileComponent,
    MiniSideBarComponent,
    UploadWindowComponent,
    DndDirective,
    TopRightFutureBarComponent,
    LeftSideBarComponent,
    InfoBoxComponent,
    AnalyticsBoxComponent,
    TimelapseComponent,
    TimelapseHistoryComponent,
    TimelapseHistoryDetailsDialogComponent,
    TimelapseHistoryVideoDialogComponent,
    ImageIframeComponent
  ],

  imports: [
    RouterModule.forRoot([], { enableTracing: false }),
    MatSidenavModule,
    appRoutingModule,
    BrowserAnimationsModule,
    SidebarModule.forRoot(),
    PSidebarModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    ButtonModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RadioButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    LazyLoadImagesModule,
    MatPaginatorModule,
    // DropdownModule,
    ScrollingModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB-rQzSyYNDSfmcMWaiQZVSPP3OefqGUWU'
    }),

    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
            // useClass: CustomTranslateLoader,

        }
    })

  ],
  providers: [
    SiteviewComponent,
    // HTTPStatus,
    // HTTPListener,
    // CommunicationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}
