<div *ngIf="loading == true" class="progress-item">
  <mat-spinner *ngIf="loading == true"  [ngClass]="{'red-progress': currentScreenMode==='desktopScreen', 'red-progress-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}" mode="indeterminate"></mat-spinner>
</div>

<div [ngClass]="{'spinner_overlay': loading == true}">
  <div class="wrapper">


    <div #panoDiv class="item0-full">
      <div #pano class="pano" id="pano"></div>
    </div>

    <div *ngIf="currentScreenMode==='desktopScreen' || infoBoxVisible" id="info_cardboard" (click)="this.showOrHideInfoBox()" [ngClass]="{'item1': currentScreenMode==='desktopScreen', 'item1-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">
    <!-- <div *ngIf="infoBoxVisible" id="info_cardboard" class="item1"> -->
      <!-- <div class="second_element"> -->
      <div [ngClass]="{'second_element': currentScreenMode==='desktopScreen', 'second_element-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">
        <h5>{{ 'txtSV_information_panel_title' | translate }}:</h5>
        <table>
          <tr>
            <td>{{ 'txtSV_information_panel_time' | translate }}:</td>
            &nbsp;
            <td>{{ timestamp_formated }}</td>
          </tr>
          <tr>
            <td>{{ 'txtSV_information_panel_lat' | translate }}:</td>
            &nbsp;
            <td>{{ image_lat }}</td>
          </tr>
          <tr>
            <td>{{ 'txtSV_information_panel_long' | translate }}:</td>
            &nbsp;
            <td>{{ image_lng }}</td>
          </tr>

          <!-- <tr>
                <td>Site ID:</td>
                &nbsp;
                <td>{{ siteID }}</td>
              </tr>


              <tr>
                <td>Camera ID:</td>
                &nbsp;
                <td>{{ camID }}</td>
              </tr> -->


        </table>
      </div>
    </div>

    <div *ngIf="currentScreenMode==='desktopScreen'" class="item2-full-back">
      <div class="flexed">
        <div class="align-to-bottom ol-custom1 ol-unselectable ol-control back-btn">
          <button (click)="back()" class="button-back" type="button" title="Back">
            <div class="mat-icon">
              <img src="./assets/back.png">
            </div>
          </button>
        </div>
      </div>
    </div>


    <div *ngIf="currentScreenMode==='desktopScreen'" #mapElement id="mapElement" class="item3-full ">
      <!-- minimap  -->
      <app-map-viewer *ngIf="!boolMapCanBeRendered" (storeCurrentStateEvent)="storeCurrentState($event)" #mapDiv
        (shrink)="miniMapResize()" (expand)="miniMapResize()" [inMiniMode]='true'>
      </app-map-viewer>


    </div>

    <div #sliderElement id="sliderElement" class="item1-full"
    [ngClass]="{'item1-full': currentScreenMode==='desktopScreen', 'item1-full-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">
      <!-- slider-->
      <app-time-slider *ngIf="usercameFromImageGallery == true" [ListOfImagesForADay]="ListOfImagesForADay"
        (currentImageUrlEventForImageGallery)="currentImageUrlEventForImageGallery($event)"
        (currentImageUrlEventForPanoView)="currentImageUrlEventForPanoView($event)"></app-time-slider>
      <!-- TimeMessage: {{time}} -->
    </div>


    <!--
      <div #controlDiv [ngClass]="{'item3-full': isExpanded, 'item3-split': !isExpanded}">

        <div class="flex-controller control-div-bottom control-buttons-pano">
          <div class="ol-custom1 ol-unselectable ol-control">
            <button id="viewUp" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewUp icon">keyboard_arrow_up</mat-icon>
            </button>
          </div>
          <div class="ol-custom1 ol-unselectable ol-control">
            <button id="viewDown" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewDown icon">keyboard_arrow_down</mat-icon>
            </button>
          </div>
          <div class="ol-custom1 ol-unselectable ol-control">
            <button id="viewLeft" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewLeft icon">keyboard_arrow_left</mat-icon>
            </button>
          </div>
          <div class="ol-custom1 ol-unselectable ol-control">
            <button id="viewRight" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewRight icon">keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <div class="ol-custom1 ol-unselectable ol-control">
            <button id="viewIn" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewIn icon">zoom_in</mat-icon>
            </button>
          </div>
          <div class="ol-custom1 ol-unselectable ol-control">
            <button id="viewOut" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewOut icon">zoom_out</mat-icon>
            </button>
          </div>
          <div class="ol-custom1 ol-unselectable ol-control">
            <button (click)="closediv()" id="viewclose" class="button" type="button" title="Show Map">
              <mat-icon aria-hidden="false" aria-label="viewclose icon">close</mat-icon>
            </button>
          </div>
        </div>
        <!-- <a id="date_picked" [ngClass]="{'calendaricon-full': isExpanded, 'calendaricon-split': !isExpanded}">
          <mat-form-field class="invisible example-full-width">
            <input [(ngModel)]="date_fordatepicker" [hidden]="true" (dateChange)="handleDateChange($event)" matInput
              [matDatepicker]="picker">
          </mat-form-field>
          <mat-datepicker-toggle class="calendar-toggle" matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>
              <img class=""
                style="  opacity: 0.7;          width: 3vw;height: 3vw;   background-color: rgb(103,115,131); "
                src="./assets/icon_calendar_wite.png">
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker [startAt]="date_fordatepicker" [dateClass]="dateClass" #picker></mat-datepicker>
        </a> -->



    <div *ngIf="currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'" #downloadElement id="downloadElement"
    [ngClass]="{'item2-full': currentScreenMode==='desktopScreen', 'item2-full-mobile': currentScreenMode==='mobileScreenPortrait' || currentScreenMode==='mobileScreenLandscape'}">

        <div class="stackedButtons">
        <div class="mobile-flex ol-unselectable ol-control">

          <button (click)="showOrHideInfoBox()" class="ol-custom1 ol-unselectable ol-control center-button-image" type="button">
            <div class="mat-icon">
              <img src="assets/flaticon_32x32/information-button32.png">
            </div>
          </button>
        </div>

        <div *ngIf="planSiteExists" class="mobile-flex ol-unselectable ol-control">

          <button (click)="routeToPlanComponent('lageplan')" type="button" class="ol-custom1 ol-unselectable ol-control center-button-image">
            <div class="mat-icon">
              <img src="assets/flaticon_32x32/blueprint.png">
            </div>
          </button>
        </div>

        <div class="mobile-flex   ol-unselectable ol-control">

          <button (click)="routeToSateliteView()" type="button" class="ol-custom1 ol-unselectable ol-control center-button-image">
            <mat-icon aria-hidden="false" aria-label="satellite_alt icon">satellite_alt</mat-icon>
          </button>
        </div>


        <!-- // TODO
        // This code is duplicate, evettually the code should be so dynamic we can apply mutiple conditions in one block -->

        <!-- if the user comes from image gallery they will see the same calendar component they have in image gallery. -->
        <div *ngIf="usercameFromImageGallery == true" #dateDiv>
          <app-date-calendar [listOfDatesToHighlight]="listOfDatesToHighlight"
            (selectedCurrentCalenderDate)="receiveDateFromCalendar($event)">
          </app-date-calendar>
        </div>

        <!-- </div> -->
        <div *ngIf="usercameFromImageGallery ==  false" #dateDiv id="date_picked"
          [ngClass]="{'item4-full': isExpanded, 'item4-split': !isExpanded}">
          <mat-form-field appearance="none">
            <!-- <mat-label>Choose a date</mat-label> -->
            <input [(ngModel)]="date_fordatepicker" [hidden]="true" (dateChange)="handleDateChange($event)" matInput
              [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix class="calendar-toggle" [for]="picker">
              <div class="ol-custom1 ol-unselectable ol-control">
                <!--
              <button (click)="downloadImage()" class="button" type="button" title="Download Image">
                <img src="assets/flaticon_32x32/001download.png">
              </button> -->
              </div>
            </mat-datepicker-toggle>
            <mat-datepicker [startAt]="date_fordatepicker" [dateClass]="dateClass" #picker></mat-datepicker>
          </mat-form-field>
        </div>




      <!-- DateMessage: {{date}} -->
    </div>
    </div>





    <div *ngIf="currentScreenMode==='desktopScreen'" #downloadElement id="downloadElement" class="item2-full">
      <div class="flexed">
        <div style="visibility:hidden" class="ol-custom1 ol-unselectable ol-control">
          <button (click)="downloadImage()" class="button" type="button" title="Download Image">
            <div class="mat-icon">
              <img src="assets/flaticon_32x32/001download.png">
            </div>
          </button>
        </div>


        <!-- if the user comes from image gallery they will see the same calendar component they have in image gallery. -->
        <div *ngIf="usercameFromImageGallery == true" #dateDiv>
          <app-date-calendar [listOfDatesToHighlight]="listOfDatesToHighlight"
            (selectedCurrentCalenderDate)="receiveDateFromCalendar($event)">
          </app-date-calendar>
        </div>

        <!-- </div> -->
        <div *ngIf="usercameFromImageGallery ==  false" #dateDiv id="date_picked"
          [ngClass]="{'item4-full': isExpanded, 'item4-split': !isExpanded}">
          <mat-form-field appearance="none">
            <!-- <mat-label>Choose a date</mat-label> -->
            <input [(ngModel)]="date_fordatepicker" [hidden]="true" (dateChange)="handleDateChange($event)" matInput
              [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix class="calendar-toggle" [for]="picker">
              <div class="ol-custom1 ol-unselectable ol-control">
                <!--
              <button (click)="downloadImage()" class="button" type="button" title="Download Image">
                <img src="assets/flaticon_32x32/001download.png">
              </button> -->
              </div>
            </mat-datepicker-toggle>
            <mat-datepicker [startAt]="date_fordatepicker" [dateClass]="dateClass" #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

    </div>

  </div>

</div>
