import * as Marzipano from 'marzipano'
import { Component, ElementRef, Renderer2, ViewChild, HostListener } from '@angular/core'
import { Router } from '@angular/router';
import * as moment from 'moment';
import Map from 'ol/Map';
import { Geometry } from "ol/geom";
import VectorSource from 'ol/source/Vector';
import Vector from 'ol/layer/Vector';
import { CurrentUIStateService } from '../_services/current-UI-state.service';
import { MapViewerComponent } from '../map-viewer/map-viewer.component';
import { UserInfoService } from '../_services/user-info.service';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { TimeSliderComponent } from '../time-slider/time-slider.component';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { DataServiceLocal } from '../_services/local_data.service';
import { DataService } from '../_services/data.service';
import * as util from '../_helper/util'
import {Location} from "@angular/common";



@Component({
  selector: 'app-siteview',
  templateUrl: './siteview.component.html',
  styleUrls: ['./siteview.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class SiteviewComponent {

   @HostListener('window:beforeunload')
  onBeforeUnload() {
    this.returnUrl = "/dashboard";
    this.router.navigate([this.returnUrl]);
  }

  @ViewChild('mapDiv', { static: false }) mapDiv: MapViewerComponent
  @ViewChild('panoDiv', { static: false }) panoDiv: ElementRef
  @ViewChild('dateDiv', { static: false }) dateDiv: ElementRef
  @ViewChild('mapElement', { static: false }) mapElement: ElementRef
  @ViewChild('pano', { static: false }) pano: ElementRef
  @ViewChild('controlDiv', { static: false }) controlDiv: ElementRef
  @ViewChild('sliderElement', { static: false }) sliderElement: ElementRef
  @ViewChild('downloadElement', { static: false }) downloadElement: ElementRef
  @ViewChild(ImageGalleryComponent, { static: false }) ImageGalleryComponent;
  @ViewChild(TimeSliderComponent, { static: false }) TimeSliderComponent;

  returnUrl;
  panorama_image_url;
   image_lat
  image_lng
  camera_id
  timestamp
  timestamp_formated
  position_id
  dist_to_nearest_position
  date_fordatepicker
  arg;
  viewer;
  view;
  // value_CurrentPositionId;
  data_AllDays_CurrentPositionId
  data_AllDays_AllPositionId
  isExpanded: any;
  innerWidth: any;
  currentPositionID_currentDay_data
  opendatafromImageplan
   miniMapOriginalState = false;
  loading: boolean = true
  ListOfImagesForADay
  listOfDatesToHighlight
  tilsoCurrentDayData
  usercameFromImageGallery = false
  vectorLayer: Vector<VectorSource<Geometry>>;
  map: Map;
  boolMapCanBeRendered : boolean = true
  maxHFoV
  maxVFoV
  maxPitchUp
  maxPitchDown
  theView
  infoBoxVisible = false
  currentScreenMode
  planSiteExists = false;
  public dataService
  options :Intl.DateTimeFormatOptions  = {  hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'

  };




  constructor(
    private siteAssociatedData : SiteAssociatedData,
    public currentUIState: CurrentUIStateService,
    public userInfoService: UserInfoService,
    public renderer: Renderer2,
    public router: Router,
    private location: Location,
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal ,
  ) {

      // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
      if (environment['environmentName'] ===  'local-development') {
        this.dataService =  dataServiceLocal;
      }
      else{
        this.dataService =  dataServiceRemote;
      }

    // this.adapter.setLocale('de-DE');
    // USER came from ImageGallery
    if (this.router.getCurrentNavigation().extras.state.markerAssociatedData != undefined && this.router.getCurrentNavigation().extras.state.markerAssociatedData != null) {
      // this.value_CurrentPositionId                = this.router.getCurrentNavigation().extras.state.position_id_
      this.data_AllDays_CurrentPositionId         = this.router.getCurrentNavigation().extras.state.markerAssociatedData
      this.tilsoCurrentDayData                    = this.router.getCurrentNavigation().extras.state. markerAssociatedData
      this.currentPositionID_currentDay_data      = this.router.getCurrentNavigation().extras.state.markerAssociatedData
      // For consistency this was needed as position Id markers have a slightly different structure.
      this.currentPositionID_currentDay_data.lat  = this.router.getCurrentNavigation().extras.state.markerAssociatedData.location.lat
      this.currentPositionID_currentDay_data.lon  = this.router.getCurrentNavigation().extras.state.markerAssociatedData.location.lon

      // As currently we have a different structure of position ids and stat cam connected graph we can use this condition to set this varible, In future maybe
      // This will needed to be changed once the flow is more consistent.

      this.usercameFromImageGallery = true
    }
    else {
      this.data_AllDays_AllPositionId     = this.siteAssociatedData.groupedDataByPositionId
      // this.value_CurrentPositionId        = this.router.getCurrentNavigation().extras.state.position_id_
      this.data_AllDays_CurrentPositionId = this.data_AllDays_AllPositionId[this.userInfoService.currentlySelectedMarker]
      this.usercameFromImageGallery = false

    }
    // Special case when being redirected from a component (tilos plan for example), user should see the tilos plan instead of minimap
    if (this.router.getCurrentNavigation().extras.state.split_state != undefined && this.router.getCurrentNavigation().extras.state.split_state == true) {
      this.opendatafromImageplan = true
      this.tilsoCurrentDayData  = this.router.getCurrentNavigation().extras.state.currentPositionID_currentDay_data
    }
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    this.infoBoxVisible = this.currentUIState.isInfoBoxOpen;
    if (this.siteAssociatedData.planSiteExists) this.planSiteExists = true;


    console.log("SiteView Constructor finished")

  }

  back(): void {
    this.currentUIState.setGoBack(true);
    // if(this.usercameFromImageGallery) {
    //   window.history.go(-2);
    // } else {
    //   this.location.back()
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    // Legacy code
    if (event.target.innerWidth < 768) {
      this.isExpanded = false;
    } else {
      this.isExpanded = true;
    }


  }






  showOrHideInfoBox() {
    if(this.currentScreenMode != 'desktopScreen')
    {
      if (this.infoBoxVisible) {
        this.infoBoxVisible = false
        this.currentUIState.isInfoBoxOpen = false;
      }
      else{
        this.currentUIState.isInfoBoxOpen = true;
        this.infoBoxVisible = true
      }
    }
  }



  ngAfterViewInit() {

    this.init_and_load_panorama()

  }
  // Apply CSS class on the calendar dates based on their value.
  dateClass = (d: Date) => {
    // Image gallery uses the proper calendar component and this function is not needed
    if (this.usercameFromImageGallery === true){
      return
    }

    let special_date = false;
    for (var i = 0, len = this.data_AllDays_CurrentPositionId.length; i < len; i++) {
      let selected_date = d.getDate()
      let selected_month = (d.getMonth() + 1).toString().padStart(2, "0")
      let selected_year = d.getFullYear()
      let iteration_date = this.data_AllDays_CurrentPositionId[i]['timestamp'].substring(8, 10)
      let iteration_month = this.data_AllDays_CurrentPositionId[i]['timestamp'].substring(5, 7)
      let iteration_year = this.data_AllDays_CurrentPositionId[i]['timestamp'].substring(0, 4)
     if (selected_date == iteration_date && selected_month == iteration_month && selected_year == iteration_year) {
      special_date = true
        break;
      }
    }
       return (special_date === true) ? 'calendar-custom-date-class' : 'calendar-custom-date-class_two';
   }



  init_and_load_panorama(date_change_event = false) {

    this.loading = true
    if (window.innerWidth < 768) {
      this.isExpanded = false;
    } else {
      this.isExpanded = true;
    }
    this.infoBoxVisible = this.currentUIState.isInfoBoxOpen
    // This checks the saved state of the UI, If the user has splitState active then its moved to split screen
    this.currentUIState.splitState$.subscribe((value) => {
      switch (value) {
        case true:
          this.miniMapResize()
          break;
        case false:
          // code block
          break;
        default:
          break;
      }
   });

    // this.siteAssociatedData.mergedConnectedGraphAllCameras[ this.currentSelectedCamId + "_listDatesWithImages"] =  this.listOfDatesToHighlight
    this.listOfDatesToHighlight = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker+ "_listDatesWithImages"]
    this.ListOfImagesForADay =  this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker]
    // User can navigate via different ways, so we have some conditions setup to detect the navigation of user and act accordingly.
    // If this is the navigation (click on a map_marker and open image) we try to find out if the user has last_opened_by_user stored in our local data.
    if (date_change_event === false) {
      // Check if location point represents the last else give it values from the avaiblae dates array according to the last date opened.
      for (var i = 0; i < this.data_AllDays_CurrentPositionId.length; i++) {
        //HACK. This should be dyanamic
        if (this.opendatafromImageplan === true) {
          this.currentPositionID_currentDay_data = this.tilsoCurrentDayData
          break
        }
        let element = this.data_AllDays_CurrentPositionId[i]
        if (element['position_id'] == this.userInfoService.currentlySelectedMarker) {
          if (element['last_opened_by_user'] === true) {
            this.currentPositionID_currentDay_data = element
            break
          }
          else {
            // use the last entry if no previous date was saved
            this.currentPositionID_currentDay_data = this.data_AllDays_CurrentPositionId.slice(-1).pop()
          }
        }
      }
    }
    this.panorama_image_url = this.currentPositionID_currentDay_data.url
    if(this.currentScreenMode === "mobileScreenPortrait" || this.currentScreenMode === "mobileScreenLandscape")
    {
      this.panorama_image_url = this.panorama_image_url.replace('.jpg', '_width4000.jpg').replace('/91_officialimage/', '/91_officialimage_width4000/')

    }
    this.image_lat = this.currentPositionID_currentDay_data.lat
    this.image_lng = this.currentPositionID_currentDay_data.lon
    this.camera_id = this.currentPositionID_currentDay_data.camera_id
    this.timestamp = this.currentPositionID_currentDay_data.timestamp
    this.userInfoService.currentlySelectedTimestamp = this.timestamp
    // console.log(this.connectedGraphService.currentlySelectedTimestamp)
    this.dist_to_nearest_position = this.currentPositionID_currentDay_data.dist_to_nearest_position
    var datetime = moment(this.timestamp)

    var f = new Intl.DateTimeFormat(this.userInfoService.currentLanguageLocale, this.options);
    this.timestamp_formated = f.format(datetime.toDate())


    if (this.userInfoService.currentLanguageLocale == 'de') {
      this.timestamp_formated = this.timestamp_formated + ' Uhr'
    }
    var startTime = performance.now()
    const panoElement = this.pano.nativeElement
    var viewerOpts = {
      stage: { progressive: true },
      controls: {
        mouseViewMode: 'drag'
      }
    }
    this.viewer = new Marzipano.Viewer(panoElement, viewerOpts)



    let limiter
    if( this.usercameFromImageGallery == true ){
      // LivEye cameras:
      // touches black sky: maxHFoV = 100; maxPitchUp = -15
      // touches black sky: maxHFoV =  90; maxPitchUp = -11

      // StatCams:
      this.maxHFoV       =  90
      this.maxVFoV       = 999  // 80
      this.maxPitchUp    = -25
      this.maxPitchDown  =  85

      limiter = Marzipano.util.compose(
        Marzipano.RectilinearView.limit.traditional( 8 * 1024,  this.maxVFoV*Math.PI/180,  this.maxHFoV * Math.PI/180),
        Marzipano.RectilinearView.limit.pitch( - this.maxPitchUp * Math.PI/180,  this.maxPitchDown * Math.PI/180),
      );
    } else {

      let maxHFoV       =  90
      let maxVFoV       = 999  // 80

      limiter = Marzipano.util.compose(
        Marzipano.RectilinearView.limit.traditional( 8 * 1024, maxVFoV*Math.PI/180, maxHFoV * Math.PI/180),
      );
     }

    //this.view = new Marzipano.RectilinearView({ yaw: Math.PI, fov: Math.PI }, limiter);
    this.view = new Marzipano.RectilinearView({ yaw: Math.PI/2, fov: Math.PI/2 }, limiter);

    const geometry = new Marzipano.EquirectGeometry([{ width: 4000 }])
    // For quick testing this template can be used as source
    // const source = Marzipano.ImageUrlSource.fromString("./assets/mtz1_5000.jpg")  //2500x1250 works on mobile
    const source = Marzipano.ImageUrlSource.fromString(this.panorama_image_url)


    var scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: this.view,
      pinFirstLevel: true
    })

    this.theView = scene.view()

    let that = this



    // Marzipano only has an event for view change so we cannot listen to zoom change events exclusively, we use this temp var to limit our viewchange event listener.
    let temp_var

    if (this.usercameFromImageGallery == true) {
      // Marzipano has a single listener for zoom, yaw and pitch change, so it will always be fire when one of these changes.
      this.viewer.addEventListener('viewChange', function () {
        //   // Get Zoom level
        var currentFov = this.view().fov();
        // console.log("🚀 ~ file: siteview.component.ts ~ line 287 ~ SiteviewComponent ~ temp_var", temp_var)

        if (currentFov !== temp_var){

          console.log("🚀 ~ file: siteview.component.ts ~ line 284 ~ SiteviewComponent ~ currentFov", currentFov)
          console.log(this.view().parameters())

          // As we only need to change the pitch up the rest of values will be the same
          var maxHFoV       =  90
          var maxVFoV       = 999  // 80
          var maxPitchUp    = null
          var maxPitchDown  =  85

          console.log("currentlySelectedMarker: ")
          console.log( that.userInfoService.currentlySelectedMarker )

          switch (true) {
            // Special cases for DE0043 and DE0052
            case ( that.userInfoService.currentlySelectedMarker == 'abaut_LTL0025_01'       // DE0043
                  ||  that.userInfoService.currentlySelectedMarker == 'abaut_LTL0029_01'    // DE0052
                  ||  that.userInfoService.currentlySelectedMarker == 'abaut_LTL0031_01' ): // DE0052
              console.log("Special Case for Cameras abaut_LTL0025_01, etc.")
              maxPitchUp = 90
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;

            case (currentFov < 0.2):
              console.log("Case 2") // Fully zoomed in.
              maxPitchUp = -1.5
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.3):
              console.log("Case 3")
              maxPitchUp = -3.49
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.4):
              console.log("Case 4")
              maxPitchUp = -6.8
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.5):
              console.log("Case 5")
              maxPitchUp = -8.5
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.6):
              console.log("Case 6")
              maxPitchUp = -10.73
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.7):
              console.log("Case 7")
              maxPitchUp = -13
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.8):
              console.log("Case 8")
              maxPitchUp = -16
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 0.9):
              console.log("Case 9")
              maxPitchUp = -19
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
            case (currentFov < 1.0):
              console.log("Case 10")  // Fully zoomed out.
              maxPitchUp = -21
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;

            default:
              // alert("none");
              console.log("Case default")
              maxPitchUp = -25
              that.limitMarziPanoView(maxHFoV, maxVFoV ,maxPitchUp,maxPitchDown, that )
              break;
          }
          console.log("maxPitchUp: ", maxPitchUp )
        }
        else{

          // console.log("Else Block was called")

        }

        // console.log("TEST1993")
        // console.log(currentFov)

        temp_var = currentFov

      });



    // scene.view().setParameters(viewParams);



    }




    let viewParams = { pitch: 0, yaw: 0, fov: 90 };
    let savedViewParams = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_ViewParamsOfSelectedItem360Marker"]
   if (this.view != null) {
     if (this.usercameFromImageGallery){
       viewParams = savedViewParams
     }
     else{
       for (var i = 0; i < this.data_AllDays_CurrentPositionId.length; i++) {
         let elem = this.data_AllDays_CurrentPositionId[i]
         if (elem['position_id'] === this.userInfoService.currentlySelectedMarker) {
           if ("viewparams" in elem) {
             viewParams = elem['viewparams']
           }
           else {
             viewParams = this.view.parameters();
           }
         }
       }
     }

   }

   if (savedViewParams === null || savedViewParams === undefined){
       // // HACK: Only for demo in Berlin. 20220310
       if( this.usercameFromImageGallery == true  &&  this.userInfoService.currentlySelectedMarker == "abaut_C0275" )
       {
         viewParams = { yaw: -1.8492103940950848, pitch: 0.4956167951004655, fov: 1.0382922284930458 }
       }
       if( this.usercameFromImageGallery == true  &&  this.userInfoService.currentlySelectedMarker == "abaut_L0153_01" )
       {
         viewParams = { yaw: -1.058589280134104, pitch: 0.4091516524125325, fov: 0.9997067428302389 }
       }
   }


    this.theView.setParameters(viewParams);




    scene.switchTo({
      transitionDuration: 0
    });


    const layer = this.viewer.scene().layer();
    layer.addEventListener('renderComplete', async (stableLayer) => {
      this.boolMapCanBeRendered = false
      if (await stableLayer) {
        if(this.usercameFromImageGallery) {
          setTimeout(() => {
            this.removeLoader()
          }, 3000)
        } else {
          this.removeLoader()
        }
      }
    }, false);


  }

  limitMarziPanoView(maxHFoV, maxVFoV, maxPitchUp, maxPitchDown, this_context) {



    let limiter = Marzipano.util.compose(
      Marzipano.RectilinearView.limit.traditional(8 * 1024, maxVFoV * Math.PI / 180, maxHFoV * Math.PI / 180),
      Marzipano.RectilinearView.limit.pitch(-maxPitchUp * Math.PI / 180, maxPitchDown * Math.PI / 180),
    );
    this_context.theView.setLimiter(limiter)
  }




  removeLoader() {
    this.loading = false
  }

  // checkRenderComplete(viewer) {
  //   var layer = viewer.scene().layer();
  //   var textureStore = layer.textureStore();
  //   var tileList = [];
  //   layer.visibleTiles(tileList);
  //   for (var i = 0; i < tileList.length; i++) {
  //     if (textureStore.query(tileList[i]).hasTexture) {
  //       // console.log('Loaded!!!!!!!!!!!!!')
  //     }
  //     else {
  //       this.loading = false
  //       // console.log('Loading...')
  //     }
  //   }
  // }

  closediv() {
    this.returnUrl = "/dashboard";
    this.router.navigate([this.returnUrl]);
  }


  storeCurrentState360Markers(){

    this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_ViewParamsOfSelectedItem360Marker"] = this.view.parameters()
    console.log("Will be saving these")
    console.log(this.view.parameters())
  }


  storeCurrentState(event){
  for (const key in this.data_AllDays_AllPositionId) {
    let value = this.data_AllDays_AllPositionId[key]
    value.forEach(elem => {
      // save a varibale so we  know the user had this day opened the last time for this position id, leep in mind we save viewparams in both cases
      if (elem['position_id'] == this.userInfoService.currentlySelectedMarker) {
        if (elem['timestamp'] == this.timestamp) {
          // let element =
          elem['viewparams'] = this.view.parameters()
          elem["last_opened_by_user"] = true
        }
        else {
          elem['viewparams'] = this.view.parameters()
          elem["last_opened_by_user"] = false
        }
      }
    });
  }
  for (var i = 0; i < this.data_AllDays_AllPositionId.length; i++) {
    let element = this.data_AllDays_AllPositionId[i]
    element.array.forEach(elem => {
      if (elem['position_id'] == this.userInfoService.currentlySelectedMarker) {
        elem['viewparams'] = this.view.parameters()
        elem["last_opened_by_user"] = this.timestamp
      }
    });
  }
  this.siteAssociatedData.groupedDataByPositionId = this.data_AllDays_AllPositionId
  }

  // sleep time expects milliseconds
  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  handleDateChange(event) {
    //Testcommit
    let dateString = event.value
    let dateString_iso = new Date(dateString)
    dateString_iso.setDate(dateString_iso.getDate() + 1);  // JS month incremenet by 1
    let dateString_iso_substring = dateString_iso.toISOString().substring(0, 10);
    this.userInfoService.currentlySelectedTimestamp = dateString_iso_substring
    this.data_AllDays_AllPositionId[this.userInfoService.currentlySelectedMarker].forEach(element => {
      element['viewparams'] = this.view.parameters()
      if (element['timestamp'].substring(0, 10) === dateString_iso_substring) {
        this.currentPositionID_currentDay_data = element
      }
    });
    this.init_and_load_panorama(true)

  }


  miniMapResize() {
    // This should reset all the effect of splitscreen
    this.currentUIState.splitState$.subscribe((value) => {
      if (!value) {
      console.log("CONDITION TRIGGERED: Reset Split Screen 2001 SITEVIEW MINI")
      this.dateDiv.nativeElement.classList.remove('item4-split')
      this.dateDiv.nativeElement.classList.add('item4-full')
      this.panoDiv.nativeElement.classList.remove('item0-split')
      this.panoDiv.nativeElement.classList.add('item0-full')
      this.sliderElement.nativeElement.classList.remove('item1-split');
      this.sliderElement.nativeElement.classList.add('item1-full');
      this.downloadElement.nativeElement.classList.remove('item2-split');
      this.downloadElement.nativeElement.classList.add('item2-full');
      this.mapElement.nativeElement.classList.remove('item3-split');
      this.mapElement.nativeElement.classList.add('item3-full');
      this.miniMapOriginalState = false
    }
    else {

      console.log("CONDITION TRIGGERED: Apply Split Screen 2002 SITEVIEW SPLIT")
      // This should do a split screen
      this.dateDiv.nativeElement.classList.remove('item4-full')
      this.dateDiv.nativeElement.classList.add('item4-split')
      this.panoDiv.nativeElement.classList.remove('item0-full')
      this.panoDiv.nativeElement.classList.add('item0-split')
      this.sliderElement.nativeElement.classList.remove('item1-full');
      this.sliderElement.nativeElement.classList.add('item1-split');
      this.downloadElement.nativeElement.classList.remove('item2-full');
      this.downloadElement.nativeElement.classList.add('item2-split');
      this.mapElement.nativeElement.classList.remove('item3-full');
      this.mapElement.nativeElement.classList.add('item3-split');
    }
  });
    if (this.mapDiv) {
      this.mapDiv.olMapUpdateSize()
    }
    if (this.viewer !== undefined) {
      this.viewer.updateSize()
    }
  }
  currentImageUrlEventForPanoView(event){
    // currentImageUrlEventForPanoView
    console.log("🚀 ~ file: siteview.component.ts ~ line 425 ~ SiteviewComponent ~ currentImageUrlEventForImageGallery ~ currentImageUrlEventForImageGallery", "currentImageUrlEventForImageGallery", event)
    this.TimeSliderComponent.UpdateSlider()
    let elem = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_timesliderObj"]
    // Due to Legacy Code we have different structures being used for different purposes, this can be improved.
    this.data_AllDays_CurrentPositionId         = elem
    this.tilsoCurrentDayData                    = elem
    this.currentPositionID_currentDay_data      = elem
    // For consistency this was needed as position Id markers have a slightly different structure.
    this.currentPositionID_currentDay_data.lat  = elem.location.lat
    this.currentPositionID_currentDay_data.lon  = elem.location.lon



    this.panorama_image_url = event.url
    this.image_lat = event.location.lat
    this.image_lng = event.location.lon
    this.camera_id = event.id
    this.timestamp = event.timestamp
    // this.userInfoService.currentlySelectedTimestamp = this.timestamp

    var f = new Intl.DateTimeFormat(this.userInfoService.currentLanguageLocale, this.options);
    this.timestamp_formated = f.format(Date.parse(this.timestamp))

    console.log(' ---- timestamp for siteview page ---- ', this.timestamp_formated)

    if (this.userInfoService.currentLanguageLocale == 'de') {
      this.timestamp_formated = this.timestamp_formated + ' Uhr'
    }

    // // As currently we have a different structure of position ids and stat cam connected graph we can use this condition to set this varible, In future maybe
    // // This will needed to be changed once the flow is more consistent.

    this.usercameFromImageGallery = true
    this.storeCurrentState360Markers()
    this.init_and_load_panorama()
  }

  currentImageUrlEventForImageGallery(event) {

        // Due to Legacy Code we have different structures being used for different purposes, this can be improved.
    // currentImageUrlEventForPanoView
    console.log("🚀 ~ file: siteview.component.ts ~ line 425 ~ SiteviewComponent ~ currentImageUrlEventForImageGallery ~ currentImageUrlEventForImageGallery", "currentImageUrlEventForImageGallery")
    this.TimeSliderComponent.UpdateSlider()
    let elem = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_timesliderObj"]

    this.data_AllDays_CurrentPositionId         = elem
    this.tilsoCurrentDayData                    = elem
    this.currentPositionID_currentDay_data      = elem
    // For consistency this was needed as position Id markers have a slightly different structure.
    this.currentPositionID_currentDay_data.lat  = elem.location.lat
    this.currentPositionID_currentDay_data.lon  = elem.location.lon

    this.panorama_image_url = event.url
    this.image_lat = event.location.lat
    this.image_lng = event.location.lon
    this.camera_id = event.id
    this.timestamp = event.timestamp

    // // As currently we have a different structure of position ids and stat cam connected graph we can use this condition to set this varible, In future maybe
    // // This will needed to be changed once the flow is more consistent.

    this.usercameFromImageGallery = true
    this.storeCurrentState360Markers()
    this.init_and_load_panorama()

  }
  //  TODO This method will be implmented
  downloadImage() {
    //console.log(this.view.parameters())
  }

  // We need to define the signature of this method aswell as its used by the calendar component.
  receiveDateFromCalendar(value) {

    console.log("SiteView Received date from calendar: " + value)
    let calculatedValue = value.replaceAll('-', '_')
    let tempArray = []
    let tempArray_360 = []

    // Save the user selected date, this will be used later on when the user switches the camera
    this.userInfoService.currentlySelectedDateInImageGallery = calculatedValue
    let currentSite = this.userInfoService.siteId
    let currentSelectedCamId = this.userInfoService.currentlySelectedMarker
    let currentSelectedDate = calculatedValue


    // Duplicate code becuase it is not decided where this should be located, Maybe when this is removed from image gallery also it can be made common somewhere
    console.log("REQUEST Backend API Call: Get List of Images for day: " + currentSelectedDate + " and cameraId: " + currentSelectedCamId)
    this.dataService.getListOfImagesForDay(currentSelectedCamId, currentSite, currentSelectedDate).subscribe(data => {
      // this.dataService.getListOfImagesForDay("abaut_C0300", "DE0007", "2022_01_13").subscribe(data => {
      console.log("RESPONSE Backend API Call: Get List of days with images for camera " + currentSelectedCamId)
      console.log(data)

       // Store the fetched response into a temporary array and then store his
       Object.values(data['result_connected_graph_360']).forEach(item => {
        // this.fetch_image_api =
        item['url'] =  environment.imageServerUrl + "/fetch_image/" + item['filename_signed']
        item['id'] =   currentSelectedCamId
        tempArray_360.push(item);
      });


      // Store the fetched response into a temporary array and then store his
      Object.values(data['result_connected_graph']).forEach(item => {
        // this.fetch_image_api =
        item['url'] =  environment.imageServerUrl + "/fetch_image/" + item['filename_signed']
        item['id'] =   currentSelectedCamId
        tempArray.push(item);
      });


      console.log("Merged Connected Graph for static camer")
      console.log(this.siteAssociatedData.mergedConnectedGraphAllCameras)

      // Store this value in global variable
      this.siteAssociatedData.mergedConnectedGraphAllCameras[ currentSelectedCamId] = tempArray
      this.siteAssociatedData.mergedConnectedGraphAllCameras[ currentSelectedCamId + "_360images"] = tempArray_360
      this.ListOfImagesForADay =  this.siteAssociatedData.mergedConnectedGraphAllCameras[ currentSelectedCamId]


      if ( this.siteAssociatedData.mergedConnectedGraphAllCameras[ currentSelectedCamId + "_360images"].length === 0){
                //Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
                this.router.routeReuseStrategy.shouldReuseRoute = function () {
                  return false;
                }
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['imagegallery'], { queryParams: {site_id: this.userInfoService.siteId , camera_id: currentSelectedCamId} });
      }

      console.log("This is your temporary array lenght")
      console.log(tempArray_360)
    },
    (error: HttpErrorResponse) => {
      console.log(error.name + ' ' + error.message);
    });



  }

  routeToSateliteView(){
    // let data_AllDays_CurrentPositionId = that.data_AllDays_AllPositionId[feature.get('associatedData')['position_id']]
     //Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
     this.router.routeReuseStrategy.shouldReuseRoute = function () {
       return false;
     }
     this.router.onSameUrlNavigation = 'reload';
     this.router.navigate(['dashboard']);
}

routeToPlanComponent(planType)
{
     // let data_AllDays_CurrentPositionId = that.data_AllDays_AllPositionId[feature.get('associatedData')['position_id']]
      //Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['imageplan'], { queryParams: {planType: planType} });
}




}
