import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInfoService } from '../../_services/user-info.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TimelapseComponent } from '../timelapse/timelapse.component';

@Component({
  selector: 'app-slidebar',
  templateUrl: './slidebar.component.html',
  styleUrls: ['./slidebar.component.scss']
})
export class SlidebarComponent implements OnInit {

  siteID = this.userInfoService.siteId;
  camID = this.userInfoService.currentlySelectedMarker;
  

  constructor(public activatedRoute: ActivatedRoute,
    private userInfoService: UserInfoService, public dialog: MatDialog) { 
      this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.camID = params['camera_id'];
        this.siteID = params['site_id'];
      })
  }

  @Input() currentScreenMode;

  timelapse_hover: boolean = false;

  ngOnInit(): void {
  }

  toggleSidebar() {
    if(this.currentScreenMode==='desktopScreen'){
      document.querySelector('.sidebar-full').classList.toggle('is-closed');
      document.querySelector('.sidebar-toggle').classList.toggle('is-closed');
      // this.sidebarDisplayed = !this.sidebarDisplayed;
    }
    if(this.currentScreenMode==='mobileScreenPortrait' || this.currentScreenMode==='mobileScreenLandscape'){
      document.querySelector('.sidebar-full-mobile').classList.toggle('is-closed-mobile');
      document.querySelector('.sidebar-toggle-mobile').classList.toggle('is-closed-mobile');
    }
  }

  goToLink(url: string){
     if(url === "/timelapse"){
      url = url + "/" + this.siteID + "/" + this.camID
      this.userInfoService.currentlySelectedMarker = this.camID
      this.openDetailDialog("1")
     }
     else{
      window.open(url, "_blank");
     }
}

mouseEnter(){
 this.timelapse_hover = true;
 document.getElementById("tab").classList.remove("fade-out");
 document.getElementById("tab").classList.remove("hidden");
 document.getElementById("tab").classList.add("fade-in");
}

mouseLeave(){
  this.timelapse_hover = false;
  document.getElementById("tab").classList.remove("fade-in");
  document.getElementById("tab").classList.add("fade-out");
}

openDetailDialog(details): void {
  const dialogRef = this.dialog.open(TimelapseComponent, {
    data: details, 
  });

  dialogRef.afterClosed().subscribe(() => {
    //this.ngOnInit()
  });
}

}
