import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-timelapse-history',
  templateUrl: './timelapse-history.component.html',
  styleUrls: ['./timelapse-history.component.scss']
})
export class TimelapseHistoryComponent implements OnInit {

  sampleData = []

  displayedColumns: string[] = ["Request ID", "Date Range", "Time Range", "User", "Date Of Creation", "Details", "Video", "Download"]
            
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.sampleData = this.data.history
    console.log(this.sampleData)
  }

  openDetailDialog(sampleData): void {
    const dialogRef = this.dialog.open(TimelapseHistoryDetailsDialogComponent, {
      data: sampleData,
      id: 'details',
      // height: '65vh',
      width: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openVideoDialog(sampleData): void {
    const dialogRef = this.dialog.open(TimelapseHistoryVideoDialogComponent, {
      data: sampleData.filename_signed,
      id: 'video',
      height: '65vh',
      width: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }

  async downloadVideo(url) {
    const a = document.createElement("a");
    let videoUrl = url


    // TODO This is temporary, will be fixed once CORS is implmented for the Image server.
    // There is a double slash before "mnt". This does not work well. We get rid of this double slash.
    videoUrl = videoUrl.replace('fetch_image//mnt', 'fetch_image/mnt')

    a.href = await this.toDataURL(videoUrl);
    let videonameWithSign = videoUrl.substring(videoUrl.lastIndexOf('/') + 1)
    let videoname = videonameWithSign.substring(0, videonameWithSign.indexOf('.mp4'));
    videoname = videoname + ".mp4"
    a.download = videoname;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

@Component({
  selector: 'app-timelapse-history-detailsdialog',
  templateUrl: 'app-timelapse-history-detailsdialog.html',
  styleUrls: ['./timelapse-history.component.scss']
})
export class TimelapseHistoryDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TimelapseHistoryDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-timelapse-history-videodialog',
  templateUrl: 'app-timelapse-history-videodialog.html',
})
export class TimelapseHistoryVideoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TimelapseHistoryVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}