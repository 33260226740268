import { Component, OnInit, Input, Output, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  host:  {
    '(document:click)': 'onClick($event)',
  },
})
export class InfoBoxComponent implements OnInit {

  @Input() text: string;
  @Input() mainIcon: string;
  @Input() sideIcon: string;
  @Input() show: boolean;
  @Input() error: boolean;
  @Input() parentRef: ElementRef;
  @Output() closeWindow = new EventEmitter<string>();
  @Output() closeMyWindow = new EventEmitter<string>();
  @Input() is_upload: boolean = false;

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  close(){
    this.closeMyWindow.emit(); 
    // this.show = false; 
  }

  onClick(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.parentRef.nativeElement.contains(event.target) && this.show){
      this.closeAll();
      // console.log((!this.eRef.nativeElement.contains(event.target) + "***" + !this.parentRef.nativeElement.contains(event.target) + "***" + (!this.show) ));
    } 
  }

  closeAll() {
    this.closeWindow.emit(); 
  }

  // onClose() {
  //   this.show = !this.show;
  // }

}
