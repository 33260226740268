export const environment = {
    production : false,
    environmentName: 'development-hub',
    apiUrl: 'https://siteview-iframe.abaut.de',
    imageServerUrl : 'https://imageserver.abaut.de',
    keyCloakServerUrl : 'https://id.abaut.de',
    keyCloakADMINServerUrl : 'https://keycloak.intra.abaut.de/',
    signUpServerUrl : 'https://signup.abaut.de/',
    subDomain: 'siteview-iframe',
    abautHub: 'https://hub.abaut.de/',
    geoServerBaseUrl: 'https://geoserver.abaut.de/geoserver/'
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
