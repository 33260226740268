import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { NavigationStart, Event as NavigationEvent } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataServiceLocal } from './_services/local_data.service';
import { DataService } from './_services/data.service';
import { CurrentUIStateService } from '../app/_services/current-UI-state.service';
import { debounceTime } from 'rxjs/operators';
// import { HTTPStatus } from './_services/HTTPStatus';
import { Meta } from '@angular/platform-browser';
import { UserInfoService } from './_services/user-info.service';
import { Location } from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  styles: ['.container-full-width { width: 100%; max-width:100% }'],
})
export class AppComponent {

  analyticsPopupShown = false

  constructor(private router: Router,
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal ,
    public currentUIState: CurrentUIStateService,
     private cdr: ChangeDetectorRef,
     private meta: Meta,
     private userInfoService: UserInfoService,
     private route: ActivatedRoute,
     private translate: TranslateService,
     private dateAdapter: DateAdapter<any>
  ) {




    console.log('Your Environment:')
    console.log(environment.environmentName)
    // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
    if (environment.environmentName ===  'local-development') {
      this.dataService =  dataServiceLocal;
    }
    else{
      this.dataService =  dataServiceRemote;
    }

    this.REST_API_SERVER = environment.apiUrl


    this.dataService.getUserSettings().subscribe(

      {
              next: (v) => {
                // alert(v);
                console.log("*** Response SETTINGS from Server ***")
                console.log(v)


                // let value = window.localStorage.getItem('lang');
                let value = v['preferred_language']
                let lang = value == null || value =='null' ? "de" : value
                this.userInfoService.currentLanguageLocale = value
                translate.setDefaultLang('siteView_'+lang);
                translate.use('siteView_'+lang);
                  // Set language of Datepicker as we are using Mat calendar
                this.dateAdapter.setLocale(value);

              },
              error: (e) => {
                console.log("*** Error while getting SETTINGS from Server ***")
                console.error(e)
                // alert(e['statusText'])
              },
              complete: () => {
              }
            })



    // Checks the current URL router value and hides the sidebar based on it.
    this.event$ = this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          // this.navigationInterceptor(event);
          if (event instanceof NavigationStart) {
            this.currentRoutePath = event.url
          }
        }
      );
    // this.httpStatus.getHttpStatus().subscribe(status => { this.HTTPActivity = status; });
    this.meta.addTag({ httpEquiv: 'Content-Security-Policy', content: 'upgrade-insecure-requests' });

    // Site ID was present in the URL
    // if (url.searchParams.get('site_id') !== null && url.searchParams.get('site_id') !== '' && url.searchParams.get('site_id') !== undefined) {
    //   this.siteIdFromUrl = url.searchParams.get('site_id');
    //   console.log('Site ID read from the URL')
    //   console.log(this.siteIdFromUrl)
    //   this.userInfoService.siteId = this.siteIdFromUrl
    //   this.siteIdFetched = true
    //
    // }
    // // Site ID wasnt present in the URL, We will fetch it from the backend.
    // else {
    //   console.log('Site ID param was empty, will fetch it from the backend for the current User')
    //   this.dataService.getSiteID().subscribe(data => {
    //     console.log('local_SITE_ID from the Backend:'); console.log(data);
    //     this.siteIdFromUrl = data;
    //     this.userInfoService.siteId = data
    //     this.siteIdFetched = true
    //   })
    //
    // }

    this.dataService.getUserData().subscribe(data => {
      console.log('UserData from the Backend:'); console.log(data);
      this.userInfoService.userLoggedin = true
      this.userInfoService.email = data.email_address
      this.userInfoService.username = data.email_address
      this.userInfoService.firstName = data.first_name
      this.userInfoService.lastName = data.last_name
      this.userIdFetched = true
    })



    // this.dataService.getUserSites().subscribe(data => {
    //   console.log('UserSites from the Backend:'); console.log(data);
    //   this.userInfoService.userSites = data.listAssociatedSitesForUser
    //   this.userInfoService.sitesWithConfig = data.sitesWithConfig
    // })

    // this.event$
    //   =this.router.events
    //       .subscribe(
    //         (event: NavigationEvent) => {
    //           if(event instanceof NavigationStart) {
    //             // console.log(event.url);
    //             if(event.url.includes('imagegallery') || event.url.includes('siteview') || event.url.includes('timelapse') || event.url.includes('iframe')){
    //               // console.log('hide toolbaar')
    //               this.toolbarHidden = true;
    //             }
    //             else{
    //               // console.log('show toolbaar')
    //               this.toolbarHidden = false;
    //             }
    //           }
    //         });

    // this.currentUIState.currentBackEvent.subscribe(back => this.goBack(back))



  }

  currentRoutePath
  event$
  REST_API_SERVER: any;
  HTTPActivity: boolean;
  toolbarHidden = false;


  // Sets initial value to true to show loading spinner on first load
  loading = true
  public siteIdFromUrl: string;
  public siteIdFetched = false;
  public userIdFetched = false;
  public dataService

  // Detect 'A' or 'a' keypress on webpage
  // @HostListener('document:keypress', ['$event'])
  // KeyboardEvent(event: KeyboardEvent) {
  //   if('a' === event.key || 'A' === event.key) {
  //     if (!(event.target instanceof HTMLInputElement)){
  //       window.open(environment.abautHub, 'ABAUT_HUB');
  //     }
  //
  //   }
  // }

  ngOnInit(): void {
    this.route.queryParams.pipe(debounceTime(500)).subscribe(params => {
      console.log('Got Site with Params....!', params)
      if (params.site_id !== null && params.site_id !== '' && params.site_id !== undefined) {
        this.siteIdFromUrl = params.site_id;
        console.log('Site ID read from the URL')
        console.log(this.siteIdFromUrl)
        this.userInfoService.siteId = this.siteIdFromUrl
        this.userInfoService.currentSiteId = this.siteIdFromUrl
        this.getSiteConfigData(params.site_id)
        this.siteIdFetched = true
      } else {
        console.log('Site ID param was empty, will fetch it from the backend for the current User')
        this.dataService.getSiteID().subscribe(data => {
          console.log('local_SITE_ID from the Backend:');
          console.log(data);
          this.siteIdFromUrl = data;
          this.userInfoService.siteId = data
          this.userInfoService.currentSiteId = this.siteIdFromUrl
          this.getSiteConfigData(data)
          this.siteIdFetched = true
        })
      }
    });




    // HACK : This will refresh the page, This is done due to the fact that the cookies are not being redirected form abaut HUB due to a different domains

    // if (!localStorage.getItem('foo')) {
    //   localStorage.setItem('foo', 'no reload')
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem('foo')
    // }



    // // 👇️ call function every 3 seconds
    // setInterval(() => {
    //   this.checkUserLoggedIn();
    // }, 3 * 1000);



  }

  // This func keeps checking if the user is logged in, In case they are logged out the HTTP intecerpter intercepts the correct Code and performs a Sigb-out
  checkUserLoggedIn() {
    this.dataService.checkUserLoggedIn().subscribe(data => {
      // console.log('checkUserLoggedIn from the Backend:');
      // console.log(data);
    })
  }


  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  getSiteConfigData(siteId): void{
    this.dataService.getSiteConfigData(siteId).subscribe((data) => {
      // console.log('--------------------config param check-------------------', data)
      // let camConfig = {
      //   SiteView_ShowHandheldCamImage: 'large_image',
      //   SiteView_ShowStatCamImage: 'large_image',
      //   SiteView_ShowMobiCamImage: 'large_image'
      // }
      // if('SiteView_ShowMobiCamImage' in  data && data.SiteView_ShowMobiCamImage !== null && data.SiteView_ShowMobiCamImage !== undefined) {
      //   camConfig.SiteView_ShowMobiCamImage = data.SiteView_ShowMobiCamImage
      // }
      // if('SiteView_ShowStatCamImage' in data && data.SiteView_ShowStatCamImage !== null && data.SiteView_ShowStatCamImage !== undefined) {
      //   camConfig.SiteView_ShowStatCamImage = data.SiteView_ShowStatCamImage
      // }
      // if('SiteView_ShowHandheldCamImage' in data && data.SiteView_ShowHandheldCamImage !== null && data.SiteView_ShowHandheldCamImage !== undefined) {
      //   camConfig.SiteView_ShowHandheldCamImage = data.SiteView_ShowHandheldCamImage
      // }
      //
      // this.userInfoService.camParams = camConfig
      // this.userInfoService.kibanaAccess = data.SiteView_ActivateAccess_toKibana
      this.userInfoService.siteTimeZone = data.timezone;});
  }

  analyticsPopupVisibility(status) {
    this.analyticsPopupShown = status
  }



  // Shows and hides the loading spinner during RouterEvent changes
  // navigationInterceptor(event: NavigationEvent): void {
  //   if (event instanceof NavigationStart) {
  //     // console.log('Navigation event started')
  //     this.loading = true
  //   }
  //   if (event instanceof NavigationEnd) {
  //     // console.log('Navigation event ended')
  //     this.loading = false
  //   }
  //
  //   // Set loading state to false in both of the below events to hide the spinner in case a request fails
  //   if (event instanceof NavigationCancel) {
  //     this.loading = false
  //   }
  //   if (event instanceof NavigationError) {
  //     this.loading = false
  //   }
  // }

  goBack(flag: boolean): void{
    if(flag){
      this.router.navigate(['..'], { relativeTo: this.route.parent, queryParams: {site_id: this.userInfoService.currentSiteId}});
      this.currentUIState.setGoBack(false);
    }
  }



  // ngOnDestroy() {
  //   this.event$.unsubscribe();
  // }


}
