import { Component } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fromLonLat } from 'ol/proj';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import {   View } from "ol";
import {TileWMS } from 'ol/source';
import OSM from "ol/source/OSM";


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})


export class ReportsComponent {
  url: string = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/visualize/edit/c2ff6030-42db-11ec-aaaa-9d0ea51ca8ef?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'2021-04-23T22%3A10%3A53.206Z'%2Cto%3A'2021-08-06T23%3A10%3A53.206Z'))"
  url_2: string = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/dashboard/e42736e0-4996-11ec-8058-1951b2f7577a?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-5w%2Cto%3Anow))"
  urlSafe: SafeResourceUrl;
  urlSafe2: SafeResourceUrl;
  dates_selection: DATES[] = [
    { value: 'dateselection1', viewValue: 'Date selection 1' },
    { value: 'dateselection2', viewValue: 'Date selection 2' },
    { value: 'dateselection3', viewValue: 'Date selection 3' },
  ];
  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer) {
  }
  map: Map;

  resizeIframe(obj) {
    // console.log("i was called")
    // console.log(obj)
    alert(obj.contentWindow.location.pathname);
  }
  ngOnInit() {


    document.querySelector("iframe").addEventListener("load", function (e) {
      // this.style.backgroundColor = "red";
      // alert(this.nodeName);
      console.log("see iframe url")
      console.log(e.target);
      console.log(e.target['src']);
    });
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_2);



    const layers = [
      new TileLayer({
        source: new OSM(),
      }),
      new TileLayer({
        // extent: [-13884991, 2870341, -7455066, 6338219],
        source: new TileWMS({
          url: 'https://geoserver.abaut.de/geoserver/OMYA/wms',
          params: {'LAYERS': '20210309_omya_uebertagetest_transparent_mosaic_group1'},

        }),
      }),
    ];




    this.map = new Map({
      target: 'ol-map',
      layers: layers,
      view: new View({
        center: fromLonLat([13.79428, 46.65624]),
        zoom: 16
      })
    });

    

    
  }
  changeClient(value) {
    if (value == "dateselection1") {
      this.url = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/visualize/edit/c2ff6030-42db-11ec-aaaa-9d0ea51ca8ef?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'2021-04-23T22%3A10%3A53.206Z'%2Cto%3A'2021-08-06T23%3A10%3A53.206Z'))"
      this.url_2 = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/dashboard/e42736e0-4996-11ec-8058-1951b2f7577a?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-5w%2Cto%3Anow))"
    }
    if (value == "dateselection2") {
      this.url = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/visualize/edit/c2ff6030-42db-11ec-aaaa-9d0ea51ca8ef?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'2021-08-06T23%3A10%3A53.207Z'%2Cto%3A'2021-11-20T00%3A10%3A53.207Z'))"
      this.url_2 = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/dashboard/e42736e0-4996-11ec-8058-1951b2f7577a?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15w%2Cto%3Anow)) "
    }
    if (value == "dateselection3") {
      this.url = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/visualize/edit/c2ff6030-42db-11ec-aaaa-9d0ea51ca8ef?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3A'2021-11-20T00%3A10%3A53.208Z'%2Cto%3A'2022-03-05T01%3A10%3A53.208Z'))"
      this.url_2 = "https://analytics.abaut.de/s/xx0001_abaut_testsite/app/kibana#/dashboard/e42736e0-4996-11ec-8058-1951b2f7577a?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15w%2Cto%3Anow))"
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_2);
    console.log("value selected in dropdown")
    console.log(value);
    console.log("URL;s right now")
    console.log(this.urlSafe)
    console.log(this.urlSafe2)
  }






}
interface DATES {
  value: string;
  viewValue: string;
}