import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as util from "../_helper/util";
import {CurrentUIStateService} from "../_services/current-UI-state.service";
import {environment} from "../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {SiteAssociatedData} from "../_services/site-associated-data.service";
import {UserInfoService} from "../_services/user-info.service";
import {DataService} from "../_services/data.service";
import {DataServiceLocal} from "../_services/local_data.service";
import * as moment from "moment-timezone";
import Static from "ol/source/ImageStatic";
import Projection from "ol/proj/Projection";
import ImageLayer from "ol/layer/Image";
import Map from "ol/Map";
import {defaults} from "ol/interaction";
import View from "ol/View";
import {Subject} from "rxjs";

@Component({
  selector: 'app-image-iframe',
  templateUrl: './image-iframe.component.html',
  styleUrls: ['./image-iframe.component.scss'],
})
export class ImageIframeComponent implements OnInit {

  camID;
  paramsCount;
  sensorID;
  siteID;
  cred;
  paramTimestamp;
  currentScreenMode;
  isLoading = true;
  infoBoxVisible = true
  isError = false;
  errorText = '';
  errorCode = '4'

  timeStamp: Subject<string> = new Subject()

  public dataService

  timestamp_formated
  image_lat
  image_lng
  currentZoomLevel = 0
  currentImageExtent
  currentSelectedDate
  currentSite
  ListOfImagesForADay = []

  listOfDatesToHighlight
  currentDisplayedImage = ""
  computedExtent
  imageLayer
  map

  date

  fetch_image_api: string;

  @ViewChild('galleryElement', { static: false }) galleryElement: ElementRef
  @ViewChild('sliderElement', { static: false }) sliderElement: ElementRef
  @ViewChild('downloadElement', { static: false }) downloadElement: ElementRef

  constructor(
    public activatedRoute: ActivatedRoute,
    public currentUIState: CurrentUIStateService,
    private siteAssociatedData: SiteAssociatedData,
    private userInfoService: UserInfoService,
    private dataServiceRemote: DataService,
    private dataServiceLocal: DataServiceLocal ,
  ) {

    if (environment.environmentName ===  'local-development') {
      this.dataService =  dataServiceLocal;
    }
    else{
      this.dataService =  dataServiceRemote;
    }

    this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.paramsCount = Object.keys(params).length
        this.sensorID = params['sensor_id'];
        this.siteID = params['site_id'];
        this.paramTimestamp = params['timestamp'];
        this.cred = params['cred'];

        this.userInfoService.siteId = this.siteID;
        this.userInfoService.currentSiteId = this.siteID;
        this.userInfoService.sensorId = this.sensorID;
        this.camID = this.getCamIdFromSensorId(this.sensorID);
        this.userInfoService.currentlySelectedMarker = this.camID;


        console.log('params =====>', params)
      })

    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);

    if(this.paramsCount === 0) {
      this.errorText = 'The URL is not equipped with any parameters.'
      this.isError = true;
      this.isLoading = false;
    } else if(this.siteID === undefined || this.sensorID === undefined || this.cred === undefined) {
      this.errorText = 'The URL is not equipped with correctly formatted parameters.'
      this.isError = true;
      this.isLoading = false;
    } else {
      this.dataService.authenticateUser(this.siteID, this.sensorID, this.cred).subscribe(res => {
        this.infoBoxVisible = this.currentUIState.isInfoBoxOpen
        this.isError = false;
        this.sendInitialRequestsToBackend(this.siteID, this.camID)
        if(this.paramTimestamp !== undefined) {
          console.log('Got timestmap from Param =====>', this.paramTimestamp)
          const requiredDateTime = new Date(this.paramTimestamp.replace(/(....)(..)(.....)(..)(.*)/, '$1-$2-$3:$4:$5'));
          console.log("Converted DateTime Object =====>", requiredDateTime, Math.floor(requiredDateTime.getTime() / 1000).toString())
          this.timeStamp.next(Math.floor(requiredDateTime.getTime() / 1000).toString())
        }
      },(error) => {
        console.log('Error=====', error)
        this.errorText = error.error.message;
        this.errorCode = error.status.toString().slice(-1);
        this.isError = true;
        this.isLoading = false;
      })
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    this.map.updateSize();

    this.currentImageExtent = this.map.getView().calculateExtent(this.map.getSize())

    this.map.getView().fit( this.currentImageExtent, { constrainResolution: false })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.imageLayer = new ImageLayer()
    this.map = new Map({
      interactions: defaults({
        doubleClickZoom: true,
        dragPan: true,
        mouseWheelZoom: true,
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      layers: [this.imageLayer],
      target: 'imageMap',
    });

    setTimeout(() => {
      if (this.map) {
        this.map.setTarget("imageMap");
      }
    }, 1000);
  }

  getCamIdFromSensorId(sensorId) {
    const result = 'abaut_C0' + sensorId.replace(/\D/g, "");
    console.log('Converted Camera ID from Sensor ID:' , sensorId , '===>', result)
    return result
  }

  showOrHideInfoBox() {
    this.currentUIState.isInfoBoxOpen = true;
  }

  sendInitialRequestsToBackend(siteID, camID) {
    this.currentSite = siteID
    this.userInfoService.currentlySelectedMarker = camID
    console.log("REQUEST Backend API Call: Get List of days with images for camera " + this.userInfoService.currentlySelectedMarker)
    this.dataService.getListOfDaysWithImages(this.userInfoService.currentlySelectedMarker, this.currentSite, this.cred).subscribe(data => {
        // this.dataService.getListOfDaysWithImages("abaut_C0300", "DE0007").subscribe(data => {
        console.log("RES: Backend API Call: Get List of days with images for camera " + this.userInfoService.currentlySelectedMarker)
        console.log(data)

        this.listOfDatesToHighlight = data['listDateFolders'].sort().reverse()
        this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_listDatesWithImages"] = this.listOfDatesToHighlight

        this.isLoading = false
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
        this.errorText = error.error.message;
        this.errorCode = error.status.toString().slice(-1);
        this.isError = true;
        this.isLoading = false;
      });
  }

  getListOfImagesForADay(siteID, camID, selectedDate) {

    let tempArray = []
    let tempArray_360 = []

    // Save the user selected date, this will be used later on when the user switches the camera
    this.userInfoService.currentlySelectedDateInImageGallery = selectedDate

    // Reset the current zoom level and current image extent when changing the camera,  as the images are entirely differnt when camera switchdd
    this.currentZoomLevel = 0
    this.currentImageExtent = ''

    // this.isLoading = true
    this.currentSite = siteID
    this.userInfoService.currentlySelectedMarker = camID
    this.currentSelectedDate = selectedDate
    console.log("REQUEST Backend API Call: Get List of Images for day: " + this.currentSelectedDate + " and cameraId: " + this.userInfoService.currentlySelectedMarker)
    this.dataService.getListOfImagesForDay(this.userInfoService.currentlySelectedMarker, this.currentSite, this.currentSelectedDate, this.cred).subscribe(data => {
        // this.dataService.getListOfImagesForDay("abaut_C0300", "DE0007", "2022_01_13").subscribe(data => {
        console.log("RESPONSE Backend API Call: Get List of days with images for camera " + this.userInfoService.currentlySelectedMarker)
        console.log(data)

        // Store the fetched response into a temporary array and then store his
        Object.values(data['result_connected_graph_360']).forEach(item => {
          // this.fetch_image_api =
          item['url'] = environment.imageServerUrl + "/fetch_image/" + item['filename_signed']
          item['id'] = this.userInfoService.currentlySelectedMarker
          tempArray_360.push(item);
        });


        // Store the fetched response into a temporary array and then store his
        Object.values(data['result_connected_graph']).forEach(item => {
          // this.fetch_image_api =
          item['url'] = environment.imageServerUrl + "/fetch_image/" + item['filename_signed']
          item['id'] = this.userInfoService.currentlySelectedMarker
          tempArray.push(item);
        });


        console.log("Merged Connected Graph for static camera")
        console.log(this.siteAssociatedData.mergedConnectedGraphAllCameras)

        // Store this value in global variable
        this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker] = tempArray
        this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker + "_360images"] = tempArray_360
        this.ListOfImagesForADay = this.siteAssociatedData.mergedConnectedGraphAllCameras[this.userInfoService.currentlySelectedMarker]
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
        this.errorText = error.error.message;
        this.errorCode = error.status.toString().slice(-1);
        this.isError = true;
        this.isLoading = false;
      });
  }

  currentImageUrlEventForImageGallery(currentImageDataObject) {


    console.log("METHOD currentImageUrlEventForImageGallery Received a image object:  ")
    console.log(currentImageDataObject)

    this.olMapUpdateSize();

    var datetime = moment(currentImageDataObject['timestamp'])

    console.log('------ Sending post message to parent -----', currentImageDataObject['timestamp'].toString())

    parent.postMessage("myevent_" + currentImageDataObject['timestamp'].toString(), "*")

    const options :Intl.DateTimeFormatOptions  = {  hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };

    var f = new Intl.DateTimeFormat(this.userInfoService.currentLanguageLocale, options);
    this.timestamp_formated = f.format(datetime.clone().tz(this.userInfoService.siteTimeZone).toDate())

    console.log('------------------kibana data', this.userInfoService.siteTimeZone, this.timestamp_formated)

    if (this.userInfoService.currentLanguageLocale == 'de') {
      this.timestamp_formated = this.timestamp_formated + ' Uhr'
    }

    if ('location' in currentImageDataObject) {
      this.image_lat = currentImageDataObject['location']['lat']
      this.image_lng = currentImageDataObject['location']['lon']
    }
    console.log(' Map view check =====>', this.image_lat, this.image_lng)

    if (this.map.getView().getZoom() !== undefined) {
      // Save the current zoom level, Extent before changing the image
      this.currentZoomLevel = this.map.getView().getZoom();
      this.currentImageExtent = this.map.getView().calculateExtent(this.map.getSize())
    }
    console.log(' Map view check =====>', this.currentImageExtent, this.currentZoomLevel)

    this.currentDisplayedImage = currentImageDataObject['complete_url']
    const img = new Image();
    let that = this
    img.src = currentImageDataObject['complete_url'];
    console.log("🚀 ~ file: image-gallery.component.ts ~ line 257 ~ ImageGalleryComponent ~ currentImageUrlEventForImageGallery ~ img.src", img.src)

    img.addEventListener("load", function () {

      that.computedExtent = [0, 0, img.naturalWidth, img.naturalHeight];

      if (that.currentImageExtent == '' || that.currentImageExtent == undefined) {
        // The view sometimes have some padding on the sides always, even if we use the same extent as image, so to avoid that we subtract some (pixels), 5,10 is arbitrary number
        that.currentImageExtent = [0, 0, img.naturalWidth - 3, img.naturalHeight - 7]
      }

      console.log('Computed Extent =====>', that.computedExtent)

      that.imageLayer.setSource(
        new Static({
          url: img.src,
          projection: new Projection({  // Map views always need a projection.  Here we just want to map image coordinates directly to map coordinates, so we create a projection that uses
            code: img.src,
            units: "pixels",
            extent: that.computedExtent,
          }),
          imageExtent: that.computedExtent,
        }),
      );

      console.log('Image Layer =====>', that.imageLayer)

      // In case the user had zoomed into a particualr area before, user should see the same area when sliding the image
      that.map.setView(
        new View({
          extent: [0, 0, img.naturalWidth, img.naturalHeight],
          showFullExtent: true
          // minZoom: 16
        })
      );

      let view = that.map.getView();

      // In case the user had zoomed into a particualr area before, user should see the same area when sliding the image
      view.fit( that.currentImageExtent, { constrainResolution: false })
      that.imageLayer.getSource().changed();

    });

    this.isLoading = false
  }

  receiveDateFromCalendar(value) {
    this.date = value
    let calculatedValue = value.replaceAll('-', '_')
    this.getListOfImagesForADay(this.userInfoService.siteId, this.userInfoService.currentlySelectedMarker, calculatedValue)
  }

  toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }

  async downloadImage() {
    const a = document.createElement("a");
    let imageUrl = this.currentDisplayedImage


    // TODO This is temporary, will be fixed once CORS is implmented for the Image server.
    // There is a double slash before "mnt". This does not work well. We get rid of this double slash.
    imageUrl = imageUrl.replace('fetch_image//mnt', 'fetch_image/mnt')

    a.href = await this.toDataURL(imageUrl);
    let imagenameWithSign = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
    let imagename = imagenameWithSign.substring(0, imagenameWithSign.indexOf('.jpg'));
    imagename = imagename + ".jpg"
    a.download = imagename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  olMapUpdateSize() {
    if (this.map !== undefined) {

      this.map.updateSize();
    }
  }

  currentImageUrlEventForPanoView(currentImageDataObject) {}

  }
