<div class="main-container">
  <img (click)="close('false')" class="close" src="../../../assets/close.png" alt="Schliessen" />
  <div>
    <h4 class="container-title">{{ 'txtSV_upload_panel_title' | translate }}</h4>
    <h4 class="container-category">{{title | translate}}-{{ 'txtSV_upload_panel_upload_lbl' | translate }}</h4>
  </div>
  <div class="upload-container" appDnd (fileDropped)="onFileDropped($event)">
  <!--<inputtype="file"#fileDropRefid="fileDropRef"multiple(change)="fileBrowseHandler($event.target.files)"/>-->
    <div *ngIf="empty" class="upload-box">
      <h3 class="upload-title">{{ 'txtSV_upload_panel_drag' | translate }}</h3>
      <p class="upload-subtitle">{{ 'txtSV_upload_panel_alternative_lbl' | translate }}</p>

      <div>
        <label class="upload-label" for="fileDropRef" >{{ 'txtSV_upload_panel_browse_file' | translate }}</label>
        <input
          class="upload-input"
          type="file"
          multiple
          #fileDropRef
          id="fileDropRef"
          (change)="fileBrowseHandler($event.target.files)"
        />
      </div>
    </div>
    <div *ngIf="!empty" class="files-list">
      <div class="single-file"  *ngFor="let _ of [].constructor(files.length > 2 ? 2 : files.length); let i=index">
        <div>
          <img [src]="getImgSrc(files[i]) || '../../../assets/plan_white.png'" class="file" alt="file"/>
          <div class="info">
            <h4 class="name">{{files[i]?.name.substring(0,20) + '...'}}</h4>
            <p class="size">{{formatBytes(files[i]?.size)}}</p>
          </div>
        </div>
        <div>
          <img
            src="../../../assets/close.png"
            class="delete"
            width="10px"
            alt="file"
            (click)="deleteFile(i)"
          />
        </div>
      </div>
      <div class="single-file"  *ngIf="files.length == 3">
        <div>
          <img [src]="getImgSrc(files[2]) || '../../../assets/plan_white.png'" class="file" alt="file"/>
          <div class="info">
            <h4 class="name">{{files[2]?.name.substring(0,20) + '...'}}</h4>
            <p class="size">{{formatBytes(files[2]?.size)}}</p>
          </div>
        </div>
        <div>
          <img
            src="../../../assets/close.png"
            class="delete"
            width="10px"
            alt="file"
            (click)="deleteFile(2)"
          />
        </div>
      </div>
      <div class="single-file"  *ngIf="files.length>3">
        <div>
          <img src="../../../assets/multiple-files-stack.png" class="file" alt="file"/>
          <div class="info">
            <h4 class="name">Other...</h4>
            <p class="size">{{getRemainingSize()}}</p>
          </div>
        </div>
        <div>
          <img
            src="../../../assets/close.png"
            class="delete"
            width="10px"
            alt="file"
            (click)="deleteFile(files.length-1)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="button-container">
    <div (click)="saveFile()" [ngClass]="empty ? 'inactive-button' : 'active-button'" class="chooseButton">
      {{ 'txtSV_upload_panel_upload' | translate }}
    </div>
    <div (click)="deleteAll()" [ngClass]="empty ? 'inactive-button' : 'active-button'" class="cancelButton">
      {{ 'txtSV_upload_panel_delete' | translate }}
    </div>
  </div>
</div>
