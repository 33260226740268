import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap,retry } from "rxjs/operators";
import { UserInfoService } from "./user-info.service";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  RETRY_NUMBER  = 2;

  constructor( private userInfoService: UserInfoService, private authService : AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!/.*\/auth\/login_user\/.*/.test(request.url)) {
      const req = request.clone({
      });

      return next.handle(req).pipe(retry(this.RETRY_NUMBER),
        tap(
          event => this.handleResponse(req, event),
          error => this.handleError(req, error)
        )
      );
    } else {
      return next.handle(request);
    }
  }

  handleResponse(req: HttpRequest<any>, event) {
    console.info(
      "Request for ",
      req.url,
      " Response Status ",
      event.status,
      " With body ",
      event.body
    );
  }

  handleError(req: HttpRequest<any>, event) {
    if (event instanceof HttpErrorResponse) {
      switch (event.status) {
          case 0:
            {
              console.log("Case 0 will hit Logout here")

               // server API connection error show
              console.error("Request for ",req.url," Response Status ",event.status,
                " With error ",event.error,"With Status Text",event.statusText
              );
              this.authService.singleSignout()
              break;
            }
          case 403:
            {

              console.log("403 ERROR intercepted, will Log you out")
              // error Token Invalid and Redirect to logout
              console.error("Request for ",req.url," Response Status ",event.status,
               " With error ",event.error,"With Status Text",event.statusText
             );

             this.authService.singleSignout()
             break;
           }
          case 401:
            {

              // console.log("401 ERROR intercepted, will Log you out")
              // error Token Invalid and Redirect to logout
              console.error("Request for ",req.url," Response Status ",event.status,
               " With error ",event.error,"With Status Text",event.statusText
             );

             // Refresh the page , Oauth2 proxy wil cause the logout when 401 error is intercepted
             // window.location.reload();



            //  this.authService.singleSignout()
             break;
           }

              // error Token Invalid and Redirect to logout
              break;
          case 502:
              // Bad gateway error
              break;
          case 500:
              // internal server error
              break;
      }
  }

    console.error("Error occurred in intercept() for URL: ", event.url, event.error);

  }
}
