import { Component, HostListener } from '@angular/core';
 import { DataServiceLocal } from '../_services/local_data.service';
import { DataService } from '../_services/data.service';
import { environment } from 'src/environments/environment';
import {  HttpErrorResponse } from '@angular/common/http';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { UserInfoService } from '../_services/user-info.service';
import { interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import * as util from '../_helper/util'

// TODO : Incase of multiple panorama cameras , merge the connected graphs together or handle them in a different way

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']

})
export class DashboardComponent {

  available_cam_ids = ["abaut_P1000"]
  tempArray = []
  elem_id = 0

  constructor(
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal ,
    private siteAssociatedData : SiteAssociatedData,
    private userInfoService : UserInfoService
  ) {

      // In development mode we use the local data instead of contacting the server as the server is behind oauth2 proxy
      if (environment.environmentName ===  'local-development') {
        this.dataService =  dataServiceLocal;
      }
      else{
        this.dataService =  dataServiceRemote;
      }


    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);

    console.log('REQUEST Backend API Call: Get all site assoicated data for user')
    this.dataService.getSiteAssociatedData(this.userInfoService.siteId ).subscribe(data => {

      console.log('*** SITE DATA ***')
      console.log(data)

      // TODO THIS WILL BE OBSELETE SOON AND WE WILL ONLY USE THE DICT OF PLANS IN THE ENTIRE CODE !!!!!!!!!
      this.siteAssociatedData.listPlans = data.LIST_PLANS

      if ('DICT_PLAN' in data ){
        if ('Map_Drone' in data.DICT_PLAN )
        {
          this.siteAssociatedData.mapDrone = data.DICT_PLAN.Map_Drone
          this.siteAssociatedData.mapDroneExists =  true
        }

        if ('Map_Shade' in data.DICT_PLAN )
        {
          this.siteAssociatedData.mapShade = data.DICT_PLAN.Map_Shade
          this.siteAssociatedData.mapShadeExists =  true
        }

        if ('Map_Survey' in data.DICT_PLAN )
        {
          this.siteAssociatedData.mapSurvey = data.DICT_PLAN.Map_Survey
          this.siteAssociatedData.mapSurveyExists =  true
        }

        if ('planMSProject1' in data.DICT_PLAN )
        {
          this.siteAssociatedData.planMSProject = data.DICT_PLAN.planMSProject1
          this.siteAssociatedData.planMSProjectExists =  true
        }

        if ('planSite1' in data.DICT_PLAN  && this.currentScreenMode == 'desktopScreen'  )
        {
          this.siteAssociatedData.planSite = data.DICT_PLAN.planSite1
          this.siteAssociatedData.planSiteExists =  true
          // console.log("Condition A went True")

        }

        if ('planSite1_smartphone' in data.DICT_PLAN  && this.currentScreenMode !== 'desktopScreen'  )
        {
          this.siteAssociatedData.planSite = data.DICT_PLAN.planSite1_smartphone
          this.siteAssociatedData.planSiteExists =  true
          // console.log("Condition B went True")

        }

        if ('planTilos1' in data.DICT_PLAN )
        {
          this.siteAssociatedData.planTilos = data.DICT_PLAN.planTilos1
          this.siteAssociatedData.planTilosExists =  true

        }
      }



      const position_ids = []

      // data.LIST_POSITION_IDS.forEach(element => {
      //   position_ids.push(element.position_id)
      // });
      // this.siteAssociatedData.listPositionIds = position_ids

      /////////////////////////////////////////
      //////////Dynamic Position IDs//////////
      ///////////////////////////////////////
      this.dataService.getPanoramicCameras(this.userInfoService.siteId, this.available_cam_ids).subscribe(dynIds => {
        if(dynIds.dyn_ids.length === 0){
             data.LIST_POSITION_IDS.forEach(element => {
            position_ids.push(element.position_id)
          });
        }
        else{
              dynIds.dyn_ids.LIST_POSITION_IDS.forEach(element => {
              position_ids.push(element["position_id:"])
          });
        }
        this.siteAssociatedData.listPositionIds = position_ids
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });

      // this.dataService.getMachinePositions(this.userInfoService.siteId).subscribe(machines => {
      //   console.log('-----------got machine positions from service----------------------', machines)
      //   this.siteAssociatedData.machinePositions = machines.machines
      // },
      //   (error: HttpErrorResponse) => {
      //   console.log(error.name + ' ' + error.message);
      // })

    console.log('Starting the constructor of the dashboard componenent')
    console.log('REQUEST Backend API Call: Get all cameras associated with this user')

    this.dataService.getAssociatedCameras(this.userInfoService.siteId ).subscribe(data => {

      // Currently side_id is also  fetch along side this request, in the future it can come from the user selection in abaut HUB
      // console.log("*** SITE ID ***")
      // console.log(data['site_id'])
      // this.userInfoService.siteId = data['site_id']

      console.log('*** LIST CAMERAS ***')
      console.log(data.listCameras)
      data.listCameras.forEach(element => {
        // console.log("Lpop starts")
        // console.log(element)

      if ((element.lat !== null && element.lat !== 0 &&  element.lon !== null &&  element.lon !== 0))
      {

        if (element.cam_type.includes('STATCAM') || element.cam_type.includes('LIVEYECAM')  ) {
          element.cameraType = 'STATCAM'
           this.listStatcams_.push(element)
        }


        else if (element.cam_type.includes('MOBICAM')) {
          element.cameraType = 'MOBICAM'
          this.listMobiCams_.push(element)
        }

        else if (element.filter_tag === 'ft_cam_handheld_pano_outdoor') {
          element.cameraType = 'PANORAMACAM'
          this.listPanoramaCams_.push(element)
        }

        else if (element.filter_tag === 'ft_cam_mobile_drone' ||  element.filter_tag === 'ft_cam_handheld_normal' ) {
          console.log("will fetch The following element for drone or handheld phone")
          console.log(element)




          if (element.cam_id == 'abaut_P1010'){
            this.image_marker_cam_1 = false

          }
          else if(element.cam_id == 'abaut_P1020'){
            this.image_marker_cam_2 = false

          }

           this.fetchConnectedGraphForImageMarkerCameras(element,[element.cam_id])
        }


        else {
          element.cameraType = 'OTHER CAMERA, type not supported yet by the frontend'
          this.otherCameraTypes_.push(element)
        }
      } else {
        console.error(' =====> Location not Exist in Camera:- ', element.cam_id, element)
      }

      });


      this.siteAssociatedData.listCameras         = data.listCameras
      this.siteAssociatedData.listStatcams        = this.listStatcams_
      this.siteAssociatedData.listPanoramaCams    = this.listPanoramaCams_
      this.siteAssociatedData.listMobiCams        = this.listMobiCams_
      // this.siteAssociatedData.imageMarkerCams     = this.imageMarkerCams_
      this.siteAssociatedData.otherCameraTypes    = this.otherCameraTypes_


      // console.log('--------------Image Marker cam check-----------', this.siteAssociatedData.listPanoramaCams.length)

      // if ( this.siteAssociatedData.imageMarkerCams.length === 0) {
      //   this.imageMarkersConnectedGraphLoadingComplete = true
      // }
      // else
      // {
      //   this.imageMarkersConnectedGraphLoadingComplete = false

      // }


      console.log('--------------pano cam check-----------', this.siteAssociatedData.listPanoramaCams.length)

      if ( this.siteAssociatedData.listPanoramaCams.length === 0) {
        this.connectedGraphLoadingComplete = true
      }
      else
      {
        // this.siteAssociatedData.listPanoramaCams.forEach(element => {
        //   // Fetch the connectedGraph for this camera ID and merge it into our Global Connected graph for pano cams managed by the frontend.
        //   this.connectedGraphLoadingComplete = false
        //   this.fetchConnectedGraphForCamera(element.cam_id)
        // });

        const panoramicCameraIds = this.siteAssociatedData.listPanoramaCams.map(cam => cam.cam_id)
        this.connectedGraphLoadingComplete = false
        console.log('-----fetching metadata for given pano cams--------------', panoramicCameraIds)
        this.fetchConnectedGraphForCamera(panoramicCameraIds)
      }
      this.initialCamerasListLoadingComplete = true
    })
    this.userInfoService.initialDataFetchingCompleted = true
      this.initialDataLoadingComplete = true
    })

    this.dataService.getProcessingFiles(this.userInfoService.siteId).subscribe( data => {
      this.siteAssociatedData.listProcessingFiles = data;
      })

    interval(60 * 1000)
    .pipe(
        mergeMap(() => this.dataService.getProcessingFiles(this.userInfoService.siteId))
    )
    .subscribe(data => {this.siteAssociatedData.listProcessingFiles = data })

  }
  currentScreenMode: string;
  public completeConnectedGraphData
  public mapMarkerPositions
  public listStatcams_        = []
  public listPanoramaCams_    = []
  public listMobiCams_        = []
  // public imageMarkerCams_     = []
  public otherCameraTypes_    = []
  public dataService
  public fetch_image_api
  initialDataLoadingComplete  = false
  initialCamerasListLoadingComplete = false
  deviceHeight
  // as not all users have a camera assosicated with them , the code below sets it to false when needed
  connectedGraphLoadingComplete = false

  image_marker_cam_1 = true
  image_marker_cam_2 = true

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    this.appHeight();
  }

ngOnInit()
{
  this.appHeight();

}


    fetchConnectedGraphForImageMarkerCameras(cam_elem, selectedCameraIDs) {

      console.log("func")
      console.log(cam_elem)
      console.log(selectedCameraIDs)
      this.dataService.get_imagemarkers_connectedgraph(selectedCameraIDs, this.userInfoService.siteId).subscribe(data => {

        console.log('*** Connected Graph for Image Markers ***')
        console.log(data)
        this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]] =  data
        for (let key in this.siteAssociatedData.listImageMarkers[selectedCameraIDs]) {



          for (let key2 in cam_elem) {
            if (key2 == 'lat' || key2 == 'lon' || key == 'filter_tag'){
              continue

            }

            // console.log("p123")
            // console.log(cam_elem[key2])
            // console.log("91231", key, this.siteAssociatedData.listImageMarkers[selectedCameraIDs][key]);
            this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]][key][key2] = cam_elem[key2]
            this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]][key]['image_filename_original'] = key
            this.elem_id = this.elem_id + 1

            // console.log("p124")
            // console.log(this.siteAssociatedData.listImageMarkers[selectedCameraIDs][key])
          }

          this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]][key]['lat'] = this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]][key]['location']['lat']
          this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]][key]['lon'] = this.siteAssociatedData.listImageMarkers[selectedCameraIDs[0]][key]['location']['lon']


        }
        if (selectedCameraIDs[0] == 'abaut_P1010'){
          this.image_marker_cam_1 = true

        }
        else if(selectedCameraIDs[0] == 'abaut_P1020'){
          this.image_marker_cam_2 = true

        }
        else{

        }



      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });
    }



    fetchConnectedGraphForCamera(selectedCameraIDs) {


      this.fetch_image_api = environment.imageServerUrl + '/fetch_image/'


      console.log('REQUEST Backend API Call: Get Image MetaData connected Graph for camera:'  + selectedCameraIDs )
      this.dataService.get_imagemetadata_connectedgraph(selectedCameraIDs, this.userInfoService.siteId).subscribe(data => {

        console.log('*** Connected Graph ***')
        console.log(data)

        if(data !== undefined) {
          const tempArray = []
          Object.values(data).forEach(item => {
            tempArray.push(item);
          });
          this.mapMarkerPositions = this.siteAssociatedData.listPositionIds
          // Group the complete_connected_json (tempArray) such that the key is position id and the value is a list of all the entries (images for different dates) for that position id
          let groupedDataByPositionId
          groupedDataByPositionId = tempArray.reduce(function (r, a) {
            r[a.position_id] = r[a.position_id] || [];
            r[a.position_id].push(a);
            return r;
          }, Object.create(null));
          // Here we merge both the json data structures we fetched, The groupedDataByPositionId is expanded with additional fields from position_id's json
          // The result is one complete groupedDataByPositionId data strucutre, we will store in in local storage and use throughout the applicatin.
          // Currently with this approach we can say that we extracted the position ids from complete_connected_json and merged it with position_ids.json.
          // additional benefit is we discard any position_ids without any image automatically.
          const that = this
          const panoCams = this.listPanoramaCams_
          this.mapMarkerPositions.forEach(element => {
            Object.keys(groupedDataByPositionId).forEach(function (groupedDataKey) {
              if (groupedDataKey == element.position_id) {

                groupedDataByPositionId[groupedDataKey].forEach(groupedDataElement => {

                  const cam = panoCams.find(data => {
                    return data.cam_id === groupedDataElement.camera_id
                  })

                  groupedDataElement.end_time  = element.end_time
                  groupedDataElement.label     = element.label
                  groupedDataElement.lat       = element.lat
                  groupedDataElement.lon       = element.lon
                  groupedDataElement.position_id = element.position_id
                  groupedDataElement.route_m     = element.route_m
                  groupedDataElement.start_time  = element.start_time
                  groupedDataElement.url         = that.fetch_image_api + groupedDataElement.image_filename
                  // SOME extra fields added, later we can decide what to do with them
                  groupedDataElement.icon_url = './assets/custom_icons/panorama_image.png'
                  if(cam !== undefined) {
                    groupedDataElement.filter_tag  = cam.filter_tag
                  }
                });
              }
            });
          });

          // Currently we only support one connected graph file
          console.log('------------------------------positionId Group-------------------------------', groupedDataByPositionId)
          this.siteAssociatedData.groupedDataByPositionId = groupedDataByPositionId
          this.connectedGraphLoadingComplete = true
        }

        // Store the fetched response into a temporary array

      },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        });
    }


  appHeight()
  {
   document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  //  console.log('Setting the inner height to window.innerHeight',window.innerHeight)
  //  console.log('Setting the inner height to app-height',window.document.documentElement.style.getPropertyValue('--app-height'));
   this.deviceHeight = window.innerHeight;
  //  console.log('Setting the this.deviceHeight',this.deviceHeight);
  }

}
