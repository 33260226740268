<body>
    <div>
        <div class="header">
            <h1 class="title" style="font: 2rem Calibri;">{{'txtSV_vg_main_title' | translate}}</h1>
            <button mat-button class="history" (click)="goToLink('/timelapse-history')">{{'txtSV_vg_main_allvideos' | translate}}</button>
        </div>
        <div class="content">
                <mat-horizontal-stepper
                  #linearVerticalStepper="matHorizontalStepper"
                  [linear]="true" 
                >
                  <mat-step [stepControl]="videoSettingGroup">
                    <form [formGroup]="videoSettingGroup">
                    <ng-template matStepLabel>{{'txtSV_vg_main_step_setting' | translate}}</ng-template>
                    <div class="detailblock">
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Site</mat-label>
                            <input matInput formControlName="siteFormCtrl" />
                            <mat-error>This field is required</mat-error>
                          </mat-form-field>
                    
                          <mat-form-field appearance="fill">
                            <mat-label>Camera ID</mat-label>
                            <input matInput formControlName="camFormCtrl" />
                            <mat-error>This field is required</mat-error>
                          </mat-form-field> -->
          
                          <mat-form-field appearance="fill">
                              <mat-label>{{'txtSV_vg_main_form_startdate' | translate}}</mat-label>
                              <input readonly matInput [matDatepicker]="start" formControlName="startdateFormCtrl" (dateChange)="addEvent('start', $event)" [min]="min_date" [max]="max_date">
                              <!-- <mat-hint>MM.DD.YYYY</mat-hint> -->
                              <mat-error>{{'txtSV_vg_input_required' | translate}}</mat-error>
                              <mat-datepicker-toggle matIconSuffix [for]="start"></mat-datepicker-toggle>
                              <mat-datepicker #start></mat-datepicker>
                          </mat-form-field>
          
                          <mat-form-field appearance="fill" class="right_side_input">
                              <mat-label>{{'txtSV_vg_main_form_enddate' | translate}}</mat-label>
                              <input readonly matInput [matDatepicker]="end" formControlName="enddateFormCtrl" (dateChange)="addEvent('end', $event)" [min]="min_date" [max]="max_date">
                              <!-- <mat-hint>MM.DD.YYYY</mat-hint> -->
                              <mat-error *ngIf="videoSettingGroup.errors">{{ videoSettingGroup.errors.message | translate}}</mat-error>
                              <mat-datepicker-toggle matIconSuffix [for]="end"></mat-datepicker-toggle>
                              <mat-datepicker #end></mat-datepicker>
                          </mat-form-field>
                          

                         
                          <mat-form-field appearance="fill">
                            <mat-label>{{'txtSV_vg_main_form_dailystarttime' | translate}}</mat-label>
                            <input matInput formControlName="starttimeFormCtrl" type="time"/>
                            <mat-error *ngIf="videoSettingGroup.get('starttimeFormCtrl').errors">{{ videoSettingGroup.get("starttimeFormCtrl").errors.images | translate}}</mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="fill" class="right_side_input">
                            <mat-label>{{'txtSV_vg_main_form_dailyendtime' | translate}}</mat-label>
                            <input matInput formControlName="endtimeFormCtrl" type="time"/>
                            <mat-error *ngIf="videoSettingGroup.errors">{{ videoSettingGroup.errors.message | translate }}</mat-error>
                            <mat-error *ngIf="videoSettingGroup.get('endtimeFormCtrl').errors">{{ videoSettingGroup.get("endtimeFormCtrl").errors.images | translate }}</mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="fill">
                            <mat-label>{{'txtSV_vg_main_form_fps' | translate}}</mat-label>
                            <input type="number" matInput formControlName="fpsFormCtrl"/>
                            <mat-error *ngIf="videoSettingGroup.errors">{{ videoSettingGroup.errors.message | translate }}</mat-error>
                          </mat-form-field>
                    </div>
                    <div style="margin-top: 10px; display: flex; justify-content: space-between;">
                      <button mat-button (click)="close()">{{'txtSV_vg_main_button_cancel' | translate}}</button> 
                      <button mat-button matStepperNext>{{'txtSV_vg_main_button_next' | translate}}</button> 
                    </div>
                  </form>
                  </mat-step>
              
                  <mat-step [stepControl]="introGroup">
                    <form [formGroup]="introGroup">
                    <ng-template matStepLabel>
                      <div (click)="getThumbnails()">{{'txtSV_vg_main_step_resources' | translate}}</div>
                    </ng-template>
                    <div class="detailblock">
                    <mat-form-field appearance="fill">
                      <mat-label>{{'txtSV_vg_main_form_headline1' | translate}}</mat-label>
                      <input matInput maxlength="50" formControlName="headline1FormCtrl"/>
                      <mat-error>This field is required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="right_side_input">
                      <mat-label>{{'txtSV_vg_main_form_headline2' | translate}}</mat-label>
                      <input matInput  maxlength="50" formControlName="headline2FormCtrl" />
                      <mat-error>This field is required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                      <mat-label>{{'txtSV_vg_main_form_footer' | translate}}</mat-label>
                      <input matInput  maxlength="50" formControlName="footerFormCtrl" />
                      <mat-error>This field is required</mat-error>
                    </mat-form-field>

                    
                    <!-- bindLabel="name" -->
                    <!-- bindValue="id" -->
                    <!-- (change)="onChange($event)" -->

                    <!-- <mat-form-field appearance="fill">
                      <mat-label>Audio</mat-label> -->
                      <!-- <input matInput formControlName="audioFormCtrl" /> -->

                    <div class="audioContainer">
                      <mat-form-field appearance="fill" style="margin-left: 0px; width: 92%;">
                        <mat-label>{{'txtSV_vg_main_form_audio' | translate}}</mat-label>
                        <mat-select formControlName="audioFormCtrl">
                          <mat-option *ngFor="let audio of audios" [value]="audio" (click)="changeAudio()">
                            {{audio == 'No Audio' ? ('txtSV_vg_main_form_audio_noaduio' | translate) : audio}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>               
                      <audio loop #ref src={{mp3url}}></audio>
                      <button type="button" [ngClass]="playAudio ? 'play-button stop' : 'play-button'" (click)="toggleAudio()" (click)="playAudio? ref.play():ref.pause()"></button>
                    </div>

                      <!-- <div class="audioContainer">
                      <div class="selectContainer">
                        <ng-select matInput formControlName="audioFormCtrl"
                          [items]="audios"
                          placeholder="No Audio"
                          [(ngModel)]="selectedAudio"
                          class="selectComponent"
                          [clearable]="false"
                          [searchable]="false"
                          bindLabel="selectedAudio"
                          bindValue="selectedAudio"
                          (click)="changeAudio()">
                          <ng-template ng-option-tmp let-item="item" class="option-temp">
                            <span class="option-text">{{item}}</span>: "",
                          </ng-template>
                        </ng-select>      
                      </div>                
                      <audio loop #ref src={{mp3url}}></audio>
                      <button type="button" [ngClass]="playAudio ? 'play-button stop' : 'play-button'" (click)="toggleAudio()" (click)="playAudio? ref.play():ref.pause()"></button>
                      </div> -->
                    <!-- </mat-form-field> -->

                    <!-- <mat-form-field appearance="fill"> -->
                      <!-- <mat-label>WaterMark Image</mat-label> -->
                      <!-- <input matInput formControlName="wmimgFormCtrl" /> -->
                      <mat-form-field appearance="fill">
                        <mat-label (click)="f_input1.click()">{{'txtSV_vg_main_form_watermark' | translate}}</mat-label>
                        <button style="border: none !important;" mat-icon-button matSuffix (click)="f_input1.click()">
                          <mat-icon>folder</mat-icon>
                        </button>
                        <input type="text" readonly matInput formControlName="wmimgFormCtrl" (click)="f_input1.click()"/>
                        <input
                          type="file"
                          accept="image/png"
                          hidden
                          #f_input1
                          (change)="handleFileInputChange(f_input1.files, '1')"
                        />
                        <mat-error>This field is required</mat-error>
                      </mat-form-field>
                    <!-- </mat-form-field> -->

                    <!-- <mat-form-field appearance="fill">
                      <mat-label>Outro Image</mat-label>
                      <input matInput formControlName="outroimgFormCtrl" />
                    </mat-form-field> -->
                    <mat-form-field appearance="fill" class="right_side_input">
                      <mat-label (click)="f_input2.click()">{{'txtSV_vg_main_form_outro' | translate}}</mat-label>
                      <button style="border: none !important;" mat-icon-button matSuffix (click)="f_input2.click()">
                        <mat-icon>folder</mat-icon>
                      </button>
                      <input type="text" readonly matInput formControlName="outroimgFormCtrl" (click)="f_input2.click()"/>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        #f_input2
                        (change)="handleFileInputChange(f_input2.files, '2')"
                      />
                      <mat-error>This field is required</mat-error>
                    </mat-form-field>
                  </div>
                  <div style="margin-top: 10px; display: flex; justify-content: space-between;">
                    <div>
                      <button mat-button (click)="close()">{{'txtSV_vg_main_button_cancel' | translate}}</button> 
                    </div>
                    <div>
                      <button mat-button matStepperPrevious>{{'txtSV_vg_main_button_back' | translate}}</button>
                      <button (click)="getThumbnails()" mat-button matStepperNext>{{'txtSV_vg_main_button_next' | translate}}</button>
                    </div>
                    </div>
                  </form>
                  </mat-step>
    
                  <mat-step [stepControl]="imagesGroup">
                    <form [formGroup]="imagesGroup">
                    <ng-template matStepLabel>
                      <div (click)="getThumbnails()">{{'txtSV_vg_main_step_images' | translate}}</div>
                    </ng-template>

                    <div class="img-content">
                      <div *ngIf="thumbnails_loading == true"><mat-spinner *ngIf="thumbnails_loading == true" class="red-progress" mode="indeterminate"></mat-spinner></div>   
                        <div *ngFor="let image of images; trackBy: imageIndex" lazy-load-images>
                          <mat-card class="mat-elevation-z4">
                            <!-- <mat-card-header>
                              <mat-card-title>Image {{num}}</mat-card-title>
                            </mat-card-header> -->
                            <img mat-card-image [attr.data-src]="image" attr.data-background-src="../../../assets/custom_icons/abaut_logo.png">
                            <mat-card-actions>
                              <button [ngClass]="start_image[0]===image ? 'action-button green' : 'action-button'" (click)="onStart(image, $event)" class="action-button">{{'txtSV_vg_main_image_start' | translate}}</button>
                              <button (click)="onRemove(image, $event)" [ngClass]="removed_images.includes(image) ? 'action-button red' : 'action-button'">X</button>
                              <button [ngClass]="end_image[0]===image ? 'action-button red' : 'action-button'" (click)="onEnd(image, $event)" class="action-button">{{'txtSV_vg_main_image_end' | translate}}</button>
                            </mat-card-actions>
                          </mat-card>
                        </div>
                    </div>
                    <p>{{number_selected_images}} {{'txtSV_vg_main_image_number' | translate}}</p>
                    <div style="margin-top: 10px; display: flex; justify-content: space-between;">
                      <div>
                        <button mat-button (click)="close()">{{'txtSV_vg_main_button_cancel' | translate}}</button> 
                      </div>
                      <div>
                        <button mat-button matStepperPrevious>{{'txtSV_vg_main_button_back' | translate}}</button>
                        <button mat-button matStepperNext>{{'txtSV_vg_main_button_next' | translate}}</button>
                      </div>
                    </div>
                  </form>
                  </mat-step>
              
                  <mat-step [stepControl]="summaryGroup">
                    <form [formGroup]="summaryGroup">
                    <ng-template matStepLabel>{{'txtSV_vg_main_step_summary' | translate}}</ng-template>
                    <div>
                      <!-- <ul>
                          <li>Site ID: {{this.videoSettingGroup.get("siteFormCtrl").value}}</li>
                          <li>Camera ID: {{this.videoSettingGroup.get("camFormCtrl").value}}</li>
                          <li>Date Range: {{this.start_date}} - {{this.end_date}}</li>
                          <li>Time Range: {{this.videoSettingGroup.get("starttimeFormCtrl").value}} - {{this.videoSettingGroup.get("endtimeFormCtrl").value}}</li>
                          <li>FPS: {{this.videoSettingGroup.get("fpsFormCtrl").value}}</li>
                          <li>Intro Headline 1: {{this.introGroup.get("headline1FormCtrl").value}}</li>
                          <li>Intro Headline 2: {{this.introGroup.get("headline2FormCtrl").value}}</li>
                          <li>Intro Footer: {{this.introGroup.get("footerFormCtrl").value}}</li>
                          <li>Audio: {{this.introGroup.get("audioFormCtrl").value}}</li>
                          <li>Watermark Image: {{this.introGroup.get("wmimgFormCtrl").value}}</li>
                          <li>Outro Image: {{this.introGroup.get("outroimgFormCtrl").value}}</li>
                        </ul> -->

                        <table style="width:100%">
                          <tr>
                            <th>{{'txtSV_vg_main_summary_sitename' | translate}}:</th>
                            <td>{{this.videoSettingGroup.get("siteFormCtrl").value}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_cameraid' | translate}}:</th>
                            <td>{{this.videoSettingGroup.get("camFormCtrl").value}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_period' | translate}}:</th>
                            <td>{{this.start_date}} - {{this.end_date}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_period' | translate}}:</th>
                            <td>{{this.videoSettingGroup.get("starttimeFormCtrl").value}} - {{this.videoSettingGroup.get("endtimeFormCtrl").value}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_fps' | translate}}:</th>
                            <td>{{this.videoSettingGroup.get("fpsFormCtrl").value}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_headline1' | translate}}:</th>
                            <td>{{this.introGroup.get("headline1FormCtrl").value || '-'}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_headline2' | translate}}:</th>
                            <td>{{this.introGroup.get("headline2FormCtrl").value || '-'}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_footer' | translate}}:</th>
                            <td>{{this.introGroup.get("footerFormCtrl").value || '-'}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_audio' | translate}}:</th>
                            <td>{{this.introGroup.get("audioFormCtrl").value == 'No Audio' ? ('txtSV_vg_main_form_audio_noaduio' | translate) : this.introGroup.get("audioFormCtrl").value}}</td> 
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_watermark' | translate}}:</th>
                            <td>{{this.introGroup.get("wmimgFormCtrl").value || '-'}}</td>
                          </tr>
                          <tr>
                            <th>{{'txtSV_vg_main_summary_outro' | translate}}:</th>
                            <td>{{this.introGroup.get("outroimgFormCtrl").value || '-'}}</td>
                          </tr>
                        </table>
                    </div>
                    <div style="margin-top: 1px; display: flex; justify-content: space-between;">
                      <div>
                        <button mat-button (click)="close()">{{'txtSV_vg_main_button_cancel' | translate}}</button> 
                      </div>
                      <div>
                        <button mat-button matStepperPrevious>{{'txtSV_vg_main_button_back' | translate}}</button>
                        <button
                        type="button"
                        mat-button
                        matStepperNext
                        (click)="onSubmit()"
                        style="border: 2px solid green !important;"
                      >
                      {{'txtSV_vg_main_button_generate' | translate}}
                      </button>
                      </div>
                      </div>
                  </form>
                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>{{'txtSV_vg_main_step_gneration' | translate}}</ng-template>
                    <div style="text-align: center">{{request_status | translate}}</div>
                    <div style="margin-top: 1px;">
                      <button
                        type="button"
                        mat-button
                        (click)="close()"
                        style="border: 2px solid white !important;"
                      >
                      {{'txtSV_vg_main_button_finish' | translate}}
                      </button>
                    </div>
                  </mat-step>
                </mat-horizontal-stepper>
        </div>
    </div>
</body>