import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class UserInfoService {

    private _siteId: any;
    private _userLoggedin: boolean;
    private _email = null;
    private _firstName = null;
    private _lastName= null;
    private _username= null;
    private _currentSiteId = null;
    private _camParams = null;
    private _sensorId = null

    private _currentLanguageLocale;
    public get currentLanguageLocale() {
      return this._currentLanguageLocale;
    }
    public set currentLanguageLocale(value) {
      this._currentLanguageLocale = value;
    }

    private _userSites = [];
    private _sitesWithConfig = [];


    private _kibanaAccess: boolean;
    private _specialKibanaAccounts: Array<string> = [
        'AT0001',
        'AT0004',
        'AT0005',
        'AT0006',
        'AT0008',
        'AT0010',
        'CZ0002',
        'DE0001',
        'DE0002',
        'DE0011',
        'DK0001',
        'ES0001',
        'FR0001',
        'FR0003',
        'IT0001',
        'PL0001',
        'PL0003',
        'SI0001',
        'XX0001',
        'XX0003',
        'ZZ0001',
    ];
    private _specialKibanaURLs: Array<string> = [
        'https://analytics-oidc.abaut.de/s/at0001_omya_gummern'           ,
        'https://analytics-oidc.abaut.de/s/at0004_omya_gummern'           ,
        'https://analytics-oidc.abaut.de/s/at0005_mul_erzberg'            ,
        'https://analytics-oidc.abaut.de/s/at0006_modre_mittertrixen'     ,
        'https://analytics-oidc.abaut.de/s/at0008_mineral_kleinsattel'    ,
        'https://analytics-oidc.abaut.de/s/at0010_mineral_bleiberg'       ,
        'https://analytics-oidc.abaut.de/s/cz0002_mineral_lhota-rapotina' ,
        'https://analytics-oidc.abaut.de/s/de0001_omya_ruegen'            ,
        'https://analytics-oidc.abaut.de/s/de0002_db_frankfurt'           ,
        'https://analytics-oidc.abaut.de/s/de0011_strabag_bebra'          ,
        'https://analytics-oidc.abaut.de/s/dk0001_omya_stevns'            ,
        'https://analytics-oidc.abaut.de/s/es0001_hanson_valdilecha'      ,
        'https://analytics-oidc.abaut.de/s/fr0001_omya_saint-beat'        ,
        'https://analytics-oidc.abaut.de/s/fr0003_omya_mareuil'           ,
        'https://analytics-oidc.abaut.de/s/it0001_sterzing'               ,
        'https://analytics-oidc.abaut.de/s/pl0001_mineral_zagorki'        ,
        'https://analytics-oidc.abaut.de/s/pl0003_pl'                     ,
        'https://analytics-oidc.abaut.de/s/si0001_quarry_lukovica'        ,
        'https://analytics-oidc.abaut.de/s/xx0001_abaut_testsite'         ,
        'https://analytics-oidc.abaut.de/s/xx0003_abaut_fieldtests'       ,
        'https://analytics-oidc.abaut.de/s/zz0001_device_monitoring'      ,
    ];
    private _currentlySelectedDateInImageGallery;
    private _currentlySelectedTimestamp;
    private _globalSelectedTimestamp;
    private _currentlySelectedMarker;
    private _currentlySelectedMarkerLat;
    private _currentlySelectedMarkerLon;

    private _currentlySelectedImageMarker = {};
  public get currentlySelectedImageMarker() {
    return this._currentlySelectedImageMarker;
  }
  public set currentlySelectedImageMarker(value) {
    this._currentlySelectedImageMarker = value;
  }
    private _siteTimezone;


    public get sitesWithConfig() {
        return this._sitesWithConfig;
    }
    public set sitesWithConfig(value) {
        this._sitesWithConfig = value;
    }
    public get userSites() {
      return this._userSites;
    }
    public set userSites(value) {
      this._userSites = value;
    }

    public get camParams() {
      return this._camParams;
    }
    public set camParams(value) {
      this._camParams = value;
    }


    private _initialDataFetchingCompleted;
    userInfoService: any;
    public get initialDataFetchingCompleted() {
        return this._initialDataFetchingCompleted;
    }
    public set initialDataFetchingCompleted(value) {
        this._initialDataFetchingCompleted = value;
    }

    public get currentlySelectedMarkerLat() {
        return this._currentlySelectedMarkerLat;
    }
    public set currentlySelectedMarkerLat(value) {
        this._currentlySelectedMarkerLat = value;
    }

    public get currentlySelectedMarkerLon() {
        return this._currentlySelectedMarkerLon;
    }
    public set currentlySelectedMarkerLon(value) {
        this._currentlySelectedMarkerLon = value;
    }

    public get currentlySelectedTimestamp() {
        return this._currentlySelectedTimestamp;
    }

    public set currentlySelectedTimestamp(value) {
        this._currentlySelectedTimestamp = value;
    }
    public get globalSelectedTimestamp() {
        return this._globalSelectedTimestamp;
    }

    public set globalSelectedTimestamp(value) {
        this._globalSelectedTimestamp = value;
    }

    public get currentlySelectedMarker() {
        return this._currentlySelectedMarker;
    }

    public set currentlySelectedMarker(value) {
        this._currentlySelectedMarker = value;
    }

    public get currentlySelectedDateInImageGallery() {
        return this._currentlySelectedDateInImageGallery;
    }
    public set currentlySelectedDateInImageGallery(value) {
        this._currentlySelectedDateInImageGallery = value;
    }
    public get siteTimeZone() {
        return this._siteTimezone;
    }
    public set siteTimeZone(value) {
        this._siteTimezone = value;
    }

    public get email() {
      return this._email;
    }
    public set email(value) {
      this._email = value;
    }

    public get firstName() {
      return this._firstName;
    }
    public set firstName(value) {
      this._firstName = value;
    }

    public get username() {
      return this._username;
    }
    public set username(value) {
      this._username = value;
    }

    public get lastName() {
      return this._lastName;
    }
    public set lastName(value) {
      this._lastName = value;
    }

    public get sensorId () {
      return this._sensorId;
    }
    public set sensorId (value) {
      this._sensorId = value;
    }


    public get siteId() {
        return this._siteId;
    }
    public set siteId(value) {
        this._siteId = value;
    }

    public get currentSiteId() {
      return this._currentSiteId;
    }
    public set currentSiteId(value) {
      this._currentSiteId = value;
    }

    public get kibanaAccess() {
        return this._kibanaAccess;
    }
    public set kibanaAccess(value) {
        this._kibanaAccess = value;
    }

    public get kibanaSpecialAccounts() {
        return this._specialKibanaAccounts;
    }

    public get kibanaSpecialURL() {
        return this._specialKibanaURLs;
    }

    public get userLoggedin(): boolean {
        return this._userLoggedin;
    }
    public set userLoggedin(value: boolean) {
        this._userLoggedin = value;
    }

}
