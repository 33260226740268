<div class="flex-controller control-div-top">
  <div class="ol-custom ol-unselectable ol-control">
    <div class="circle" (click)="onUserButtonClick()">
      <p class="circle-inner">{{profile}}</p>
    </div>
  </div>
</div>
<div *ngIf="userProfileBox" class="control-box-top">
  <a (click)="onUserButtonClick()"><img class="close" src="./assets/close.png" alt="Schliessen"/></a>
  <div class="flex-controller">
    <div class="ol-custom2 ol-unselectable">
      <div class="circle" (click)="onUserButtonClick()">
        <p class="circle-inner">{{profile}}</p>
      </div>
      <div class="user">
        <div style="font-weight: bold; letter-spacing: 1px;    overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;">{{username}}</div>
        <div style="font-size: 12px; letter-spacing: 1px;     overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;">{{email}}</div>
      </div>
      
    </div>
  </div>
  <div class="logout" (click)="onLogout()">{{ 'txtSV_contextMenuUser_item_logout' | translate }}</div>
</div>
   