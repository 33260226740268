import { CameraFilterService } from '../_services/cameraFilter.service';
import { Component, ViewChild, Input, ElementRef, ChangeDetectorRef, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { fromLonLat, transformExtent } from 'ol/proj';
import { Geometry, Point } from 'ol/geom';
import Map from 'ol/Map';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';
import TileLayer from 'ol/layer/Tile';
import { Feature, Overlay, View } from 'ol';
import VectorSource from 'ol/source/Vector';
import Vector from 'ol/layer/Vector';
import XYZ from 'ol/source/XYZ';
import { SiteAssociatedData } from '../_services/site-associated-data.service';
import { UserInfoService } from '../_services/user-info.service';
import { defaults as defaultInteractions } from 'ol/interaction.js';
import { CurrentUIStateService } from '../_services/current-UI-state.service';
import { ImagePlan1Component } from '../image-plan1/image-plan1.component';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import * as olProj from 'ol/proj';
import * as util from '../_helper/util'
import {TileWMS} from 'ol/source';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import {HelperService} from '../_services/helper.service';
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../_services/data.service";
import {DataServiceLocal} from "../_services/local_data.service";

const source = new XYZ({
  url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
});

@Component({
  selector: 'app-map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss']
})



export class MapViewerComponent {

  MAP_LAYERS_INDICES = {
    base: 0,
    lageplan: 1,
    drohne: 2,
    cameras: 3
  }

  private vectorLayer: Vector<VectorSource<Geometry>>;
  private machineVectorLayer: Vector<VectorSource<Geometry>>;
  private lagePlanLayer: TileLayer<any>;
  private dronePlanLayer: TileLayer<any>;
  private map: Map;
  private data_AllDays_AllPositionId;
  public dataService
  public selectedIconIndex = 10;
  public currentIconIndex = 5;
  public isSelectedFromCam = false;
  public isSelectedFromMachineCam = false;

  @ViewChild('galleryElement', { static: false }) galleryElement: ElementRef
  @ViewChild(ImagePlan1Component, { static: false }) imagePlan1ComponentChild;
  @ViewChild(ImageGalleryComponent , { static: false }) ImageGalleryComponentChild;
  @ViewChild('mapDiv', { static: false }) mapDiv: ElementRef

  @Input()
  inMiniMode: boolean;
  isLoading = true;
  // TODO check this initialization
  mapCenterLat = 0
  mapCenterLon = 0
  mapCenter
  url
  currDiv = 'A';
  planType = 'lageplan'
  miniMapOriginalState
  initialZoom
  planMSProjectExists
  planSiteExists
  planTilosExists
  innerWidth
  currentScreenMode

  boolMobileScreenSize  = false
  boolTabletScreenSize  = false
  boolDesktopScreenSize = false
  @Output() storeCurrentStateEvent = new EventEmitter<string>();
  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() shrink: EventEmitter<any> = new EventEmitter();
  @Output() expand: EventEmitter<any> = new EventEmitter();

  SITES = this.userInfoService.userSites
  cameraFileterSubscription : Subscription;
  cameraDefaultSelected : string[] = []
  // 0 : Google Layer
  // 1 : Lage Plan
  // 2 : Drohne Plan
  // 3 : Vector Plan (Camera Layer)



  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private siteAssociatedData: SiteAssociatedData,
    public currentUIState: CurrentUIStateService,
    private ref: ChangeDetectorRef,
    private cameraFilter : CameraFilterService,
    private helperService: HelperService,
    dataServiceRemote: DataService,
    dataServiceLocal: DataServiceLocal ,
  ) {

    if (environment.environmentName ===  'local-development') {
      this.dataService =  dataServiceLocal;
    }
    else{
      this.dataService =  dataServiceRemote;
    }

    if (this.siteAssociatedData.planMSProjectExists) this.planMSProjectExists = true;
    if (this.siteAssociatedData.planSiteExists) this.planSiteExists = true;
    if (this.siteAssociatedData.planTilosExists) this.planTilosExists = true;
    if (this.siteAssociatedData.planMSProject !== null && this.siteAssociatedData.planMSProject !== undefined) {
      this.planMSProjectExists = true
    }
    if (this.siteAssociatedData.planSite !== null && this.siteAssociatedData.planSite !== undefined) {
      this.planSiteExists = true
    }
    if (this.siteAssociatedData.planTilos !== null && this.siteAssociatedData.planTilos !== undefined) {
      this.planTilosExists = true
    }
    this.url = this.router.url;
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
    this.siteAssociatedData.listCameras
    this.setDefaultCameraFilter()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.currentScreenMode = util.default.checkScreenDimensions(window.innerWidth, window.innerHeight);
  }


  setDefaultCameraFilter(){
    const filters = this.siteAssociatedData.listCameras.map(e=> e.filter_tag)
    filters.push(null)
    // console.log('AfterFiltering', filters)
    this.cameraFilter.changeCameraTags(filters)
    this.cameraDefaultSelected = filters
  }

  ngAfterViewInit() {
    console.log('Mini map view Init')
    // Handles the initilization of the map, drawing Markers and handling OnClick events
    this.allMapRelatedHandling()


    if (this.map !== undefined) {
      this.map.updateSize();
    }
    if (this.inMiniMode){
      switch (this.currentUIState.currentMapLayer) {
        case 'satellite_plan':
          this.changetoSatelliteLayer()
          break;
        default:
          this.changetoPlanComponent(this.currentUIState.currentMapLayer)
      }
    }
    this.cameraFileterSubscription = this.cameraFilter.currentTags.subscribe(tags=> {
      this.filterCamera(tags)
    })
  }

  allMapRelatedHandling(): void {

    this.data_AllDays_AllPositionId = this.siteAssociatedData.groupedDataByPositionId
    /**
     * Elements that make up the popup.
     */
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });


    // Set the center of the map, in case of a selected camera value set the center to that camera else set it to Camera 0 in list of cameras.
    // In case we have lat,lon equal to Zero or NUll in the cameras mounting file we dont consider them for displaying and skip them

    if (this.currentUIState.currentMapViewFrame == undefined || this.currentUIState.currentMapViewFrame == '') {

      this.siteAssociatedData.listCameras.every( v => {
        {
          if (v.lat !== 0 && v.lat !== null ){
            const cameraZero = v
            this.mapCenterLat = cameraZero.lat
            this.mapCenterLon = cameraZero.lon

            // console.log('---------------center----------------', this.mapCenterLat,this.mapCenterLon)
            // With this every fucntion return false is equivalent to a break
            return false
          }
        }
        //  this.mapCenterLat = 0
        //  this.mapCenterLon = 0


       // With this every fucntion return false is equivalent to continue
      return true
      });


      this.initialZoom = 18
      this.mapCenter =  fromLonLat([this.mapCenterLon, this.mapCenterLat])

    }
    else {


      this.siteAssociatedData.listCameras.every( v => {
        {
          if (v.lat !== 0 && v.lat !== null ){
            const cameraZero = v
            this.mapCenterLat = cameraZero.lat
            this.mapCenterLon = cameraZero.lon
            // With this every fucntion return false is equivalent to a break
            return false
          }
        }
        //  this.mapCenterLat = 0
        //  this.mapCenterLon = 0


         // With this every fucntion return false is equivalent to continue
      return true
      });

      this.initialZoom = this.currentUIState.currentMapZoomLevel
      this.mapCenter =  null

    }

    const mapLayers : TileLayer<any>[] = [
      new TileLayer({
        source,
        zIndex: this.MAP_LAYERS_INDICES.base
      }),
    ]

    if(this.siteAssociatedData.mapSurveyExists === true) {
      this.lagePlanLayer = new TileLayer({
        source: new TileWMS({
          url: `${environment.geoServerBaseUrl}${this.userInfoService.currentSiteId}/wms?tiled=true`,
          params: {LAYERS: this.siteAssociatedData.mapSurvey.image_filename},
        }),
        zIndex: this.MAP_LAYERS_INDICES.lageplan
      })
    }

    /* Create Drone Layer for displaying */
    if(this.siteAssociatedData.mapDroneExists === true) {
      this.dronePlanLayer = new TileLayer({
        source: new TileWMS({
          url: `${environment.geoServerBaseUrl}${this.userInfoService.currentSiteId}/wms?tiled=true`,
          params: {LAYERS: this.siteAssociatedData.mapDrone.image_filename},
        }),
        zIndex: this.MAP_LAYERS_INDICES.drohne
      })
    }

    // console.log('----------------------- Lageplan Map Exist? -------------', this.siteAssociatedData.mapSurveyExists === true)
    if(this.siteAssociatedData.mapSurveyExists === true)
      mapLayers.push(this.lagePlanLayer)

    // console.log('----------------------- Drone Map Exist? -------------', this.siteAssociatedData.mapDroneExists === true)
    if(this.siteAssociatedData.mapDroneExists === true)
      mapLayers.push(this.dronePlanLayer)

    // In case we have no markers zoom out the Map to avoid confusion
    if (this.mapCenterLat === 0){
      const siteConfig = this.userInfoService.sitesWithConfig.filter((site) => site.site_id === this.userInfoService.siteId)
      console.log('SITE WITH NO DATA', siteConfig)

      this.map = new Map({
        // US: To only keep zoom button we initialize controls with an empty array.
        // controls: [],
        view: new View({
          center: this.mapCenter,
          zoom: this.initialZoom,
        }),
        interactions: defaultInteractions({
          doubleClickZoom: true,
          dragPan: true,
          mouseWheelZoom: true,
          altShiftDragRotate:false,
          pinchRotate:false
        }),
        layers: mapLayers,
        overlays: [overlay],
        target: 'ol-map341',
        controls: []
      });

      this.map.getView().fit(transformExtent([
        siteConfig[0].location1_lon,
        siteConfig[0].location1_lat,
        siteConfig[0].location2_lon,
        siteConfig[0].location2_lat
      ], 'EPSG:4326', this.map.getView().getProjection()), { size: this.map.getSize() });
      this.isLoading = false

      if(this.siteAssociatedData.mapSurveyExists === true)
        this.lagePlanLayer.setVisible(false);

      if(this.siteAssociatedData.mapDroneExists === true)
        this.dronePlanLayer.setVisible(false);

    } else {
      /* Create LagePlan Layer for displaying */
      // layers http://serverName.xyz.com:80/geoserver/gwc/service/wms?tiled=true)



      this.map = new Map({
        // US: To only keep zoom button we initialize controls with an empty array.
        // controls: [],
        view: new View({
          center: this.mapCenter,
          zoom: this.initialZoom,
        }),
        interactions: defaultInteractions({
          doubleClickZoom: true,
          dragPan: true,
          mouseWheelZoom: true,
          altShiftDragRotate:false,
          pinchRotate:false
        }),
        layers: mapLayers,
        overlays: [overlay],
        target: 'ol-map341',
        controls: []
      });

      if(this.siteAssociatedData.mapSurveyExists === true)
        this.lagePlanLayer.setVisible(false);

      if(this.siteAssociatedData.mapDroneExists === true)
        this.dronePlanLayer.setVisible(false);

      this.olMapUpdateSize();

      // let myzoommlevel = this.map.getView().getZoom();
      // console.log(" This zoom level was set in map")
      // console.log(myzoommlevel)


      if (this.currentUIState.currentMapViewFrame != undefined && this.currentUIState.currentMapViewFrame != '') {
        // In case the user had zoomed into a particualr area before, user should see the same area again whenever this map is redrawn
        this.map.getView().fit(transformExtent( this.currentUIState.currentMapViewFrame, 'EPSG:4326', 'EPSG:3857'));
        // Important to set the zoom level after map
        this.map.getView().setZoom(this.initialZoom);

      }

      // The map can have differnt kinds of markers
      // - Markers that represent a postion id. They have a specefic icon and open the siteview component on click
      // - Markers that represnt the last GPS postion of statcams and mobicams and open the image gallery component on click.

      const features = [];


      // Postion Id's cannot be used to draw markers on map as some postion ids dont contain any images
      // so instead use the grouped position id data we had saved earlier, Because we have a list for each key we pick the first elmeent by default as all concerned values are same.


      // console.log('---------------------------------- All Days Position ID---------------------------------', this.data_AllDays_AllPositionId)

      // This Displays Position Ids (Panorama Cams)
      if( this.data_AllDays_AllPositionId !== null ){

        Object.keys( this.data_AllDays_AllPositionId ).forEach(key => {


          let allowClick = true;

          if(this.userInfoService.camParams.SiteView_ShowHandheldCamImage === 'nothing') {
            allowClick = false;
          }

          const groupedItem =  this.data_AllDays_AllPositionId[key]

          let iconSrc = ''
          let iconZindex
          if (key === this.userInfoService.currentlySelectedMarker) {
            // In case of the large Map we dont show the selected icon as red
            iconSrc = './assets/flaticon/016-360-degrees_red_new_smooth.png';
            iconZindex  = this.selectedIconIndex
            this.toggleSelectedToCam();
          } else {
            iconSrc = './assets/flaticon/016-360-degrees_smooth.png'
            iconZindex  = 5
          }


          let item = groupedItem[0]
          // let type = item.icon_url;
          // get type
          let longitude                    // coordinates
          let latitude

          longitude = item.lon;
          latitude = item.lat;


          // Create Feature... with coordinates
          const iconFeature = new Feature({
            geometry      : new Point(olProj.transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')),
            position_id   : item.position_id,
            type          : allowClick ? 'leadsToSiteView' : 'noRedirection',
            associatedData: item,
            filter_tag    : item.filter_tag,
          });
        // console.log("*************************************key********************************************")
        // console.log(key, iconFeature, item)
        // console.log("*************************************key********************************************")

          // Create style for your feature...
          const iconStyle = [new Style({
            zIndex: iconZindex,
            image: new Icon(({
              // anchor: [0.5, 12],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              opacity     : 1,
              src         : iconSrc,
              scale       : 1.0
            }))
          }),
          ]
          iconFeature["values_"]["associatedData"]["style"] = iconStyle        //store the style
          iconFeature.setStyle(iconStyle);
          features.push(iconFeature);

        });
      }


      let listOfCameras = this.siteAssociatedData.listStatcams.concat(this.siteAssociatedData.listMobiCams)

      for (let cam_key in this.siteAssociatedData.listImageMarkers) {
        for (let key in this.siteAssociatedData.listImageMarkers[cam_key]) {
          listOfCameras.push(this.siteAssociatedData.listImageMarkers[cam_key][key])
        }
      }



      //// This Displays Other cameras (Static, Crane, Machine etc.)
      if( listOfCameras  !== 0 ) {


        listOfCameras.forEach((element, index) => {

          let iconSrc = ''
          let iconZindex
          let iconScale

          iconScale   = 1.0
          let anchorX = 0.5
          let anchorY = 0.5
          let allowClick = true

          console.log('=================== filter tag of camera ====================', element.filter_tag, index)

          // ft_cam_mobile_drone cameras
          if (element.filter_tag === 'ft_cam_mobile_drone') {
            iconScale = 0.30
            anchorX = 0.5
            anchorY = 1
            if (element.image_filename_original === this.userInfoService.currentlySelectedMarker) {
              iconSrc = './assets/SVG_Machine_Icons/ft_cam_mobile_drone/Poi_Drohne_ausgewählt.svg'
              iconZindex = this.selectedIconIndex
              this.toggleSelectedToCam();
            } else {
              iconSrc = './assets/SVG_Machine_Icons/ft_cam_mobile_drone/Poi_Drohne.svg'
              iconZindex = 5
            }

            // if (this.userInfoService.camParams.SiteView_ShowStatCamImage === 'nothing') {
            //   iconSrc = ''
            //   allowClick = false
            // } else if (this.userInfoService.camParams.SiteView_ShowStatCamImage === 'icon') {
            //   allowClick = false
            // }
          }

          // ft_cam_handheld_normal cameras
          if (element.filter_tag === 'ft_cam_handheld_normal') {
            iconScale = 0.30
            anchorX = 0.5
            anchorY = 1
            if (element.image_filename_original === this.userInfoService.currentlySelectedMarker) {
              iconSrc = './assets/SVG_Machine_Icons/ft_cam_handheld_normal/POI_Bildaufnahme_selected.svg'
              iconZindex = this.selectedIconIndex
              this.toggleSelectedToCam();
            } else {
              iconSrc = './assets/SVG_Machine_Icons/ft_cam_handheld_normal/POI_Bildaufnahme_not_selected.svg'
              iconZindex = 5
            }

            // if (this.userInfoService.camParams.SiteView_ShowStatCamImage === 'nothing') {
            //   iconSrc = ''
            //   allowClick = false
            // } else if (this.userInfoService.camParams.SiteView_ShowStatCamImage === 'icon') {
            //   allowClick = false
            // }
          }

          // ft_cam_static cameras
          if(element.filter_tag === 'ft_cam_static') {
            if (element.cam_id === this.userInfoService.currentlySelectedMarker) {
              iconSrc = element.containsPanoramaImage ? './assets/flaticon/020_dualcamera_red.png' : './assets/flaticon/017-security-camera_red_new_smooth.png'
              iconZindex = this.selectedIconIndex
              this.toggleSelectedToCam();
            } else {
              iconSrc = element.containsPanoramaImage ? './assets/flaticon/020_dualcamera.png' : './assets/flaticon/017-security-camera_smooth.png'
              iconZindex = 5
            }

            if(this.userInfoService.camParams.SiteView_ShowStatCamImage === 'nothing') {
              iconSrc = ''
              allowClick = false
            } else if(this.userInfoService.camParams.SiteView_ShowStatCamImage === 'icon') {
              allowClick = false
            }
          }


          // ft_machine cameras
          if(element.filter_tag === 'ft_machine') {
            if (element.cam_id === this.userInfoService.currentlySelectedMarker) {
              iconSrc =  './assets/flaticon/018-mobicam_red_new_smooth.png'
              iconZindex = this.selectedIconIndex
              this.toggleSelectedToCam();
            } else {
              iconSrc = './assets/flaticon/018-mobicam_smooth.png'
              iconZindex = 5
            }

            if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'nothing') {
              iconSrc = ''
              allowClick = false
            } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_without_cam') {
              allowClick = false
            } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_with_cam') {
              allowClick = false
            }
          }
          // console.log("xeee")


          // if(Object.keys(this.helperService.camMachineType).includes(element.filter_tag)) {
          //   const cam_type = this.helperService.machineType[this.helperService.camMachineType[element.filter_tag]]
          //   anchorX = 0.3
          //   anchorY = 0.8
          //   iconScale = 0.5
          //   if (element.cam_id === this.userInfoService.currentlySelectedMarker) {
          //     iconSrc =  './assets/SVG_Machine_Icons/' + cam_type + '/' + cam_type + '_camera_red.svg'
          //     iconZindex = 10
          //   } else {
          //     iconSrc = './assets/SVG_Machine_Icons/' + cam_type + '/' + cam_type + '_camera.svg'
          //     iconZindex = 5
          //   }
          //
          //   if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'nothing') {
          //     iconSrc = ''
          //     allowClick = false
          //   } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_without_cam') {
          //     allowClick = false
          //   } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_with_cam') {
          //     allowClick = false
          //   }
          // }

          // ft_machine_crane cameras
          if(element.filter_tag === 'ft_machine_crane+cam' || element.filter_tag === 'ft_cam_static_crane') {
            if (element.cam_id === this.userInfoService.currentlySelectedMarker) {
              iconSrc =  './assets/SVG_Machine_Icons/ft_machine_crane/ft_machine_crane_red.svg'
              iconZindex = this.selectedIconIndex
              this.toggleSelectedToCam();
              iconScale = 0.6
            } else {
              iconSrc = './assets/SVG_Machine_Icons/ft_machine_crane/ft_machine_crane.svg'
              iconZindex = 5
              iconScale = 0.6
            }

            if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'nothing') {
              iconSrc = ''
              allowClick = false
            } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_without_cam') {
              allowClick = false
            } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_with_cam') {
              allowClick = false
            }
          }

          const item = element                                    // get item
          const longitude = item.lon;                         // coordinates
          const latitude = item.lat;


          if(iconSrc !== '') {

            // create Feature... with coordinates
            const iconFeature = new Feature({
              geometry      : new Point(olProj.transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')),
              position_id   : item.position_id,
              type          : allowClick ? 'leadsToImageGallery' : 'noRedirection',
              associatedData: item,
              name          : item.position_id,
              filter_tag    : item.filter_tag,
            });
            // create style for your feature...
            const iconStyle = [new Style({
              zIndex: iconZindex,
              hitDetectionRenderer: null,
              image: new Icon(({
                anchor: [anchorX, anchorY],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity     : 1,
                src         : iconSrc,
                scale       : iconScale    // the scale factor responsible for icon size
              })),
            })
            ]

            // console.log('=======================icon=======================', iconFeature, iconStyle)

            iconFeature["values_"]["associatedData"]["style"] = iconStyle        //store the style
            iconFeature.setStyle(iconStyle);
            features.push(iconFeature);
          }
        });
      }



      /*
      * create vector source
      * you could set the style for all features in your vectoreSource as well
      */
      const vectorSource = new VectorSource({ features });
      this.vectorLayer = new Vector({
        source: vectorSource,
      });
      const vectorLayerZIndex = this.isSelectedFromCam===true ? (this.MAP_LAYERS_INDICES.cameras + 1) : (this.MAP_LAYERS_INDICES.cameras);
      this.vectorLayer.setZIndex(vectorLayerZIndex);


      this.map.addLayer(this.vectorLayer)


      // Only in teh Large map we zoom out so taht all markers appear on the screen
      if ((this.url.indexOf('siteview') <= 0) && (this.url.indexOf('imageplan') <= 0) && (this.url.indexOf('imagegallery') <= 0)) {

        // Add some padding otherwise the Markers reach the edge of screen.
        const extentsArray = vectorSource.getExtent()

        console.log(' ===== Vector Layer 5 ===== ', extentsArray)
        // console.log(extentsArray)
        const extentWidth = extentsArray[2] -  extentsArray[0]
        const extentHeight = extentsArray[3] -  extentsArray[1]

        console.log(' ===== Vector Layer extentWidth 6 ===== ', extentWidth)
        console.log(' ===== Vector Layer extentHeight 7 ===== ', extentHeight)


        // We add some additional padding around the markers, based on the differnce in extent x and y values.
        extentsArray[0] = extentsArray[0]  - extentWidth * 0.1
        extentsArray[1] = extentsArray[1]  - extentHeight * 0.1
        extentsArray[2] = extentsArray[2]  + extentWidth * 0.1
        extentsArray[3] = extentsArray[3]  + extentHeight * 0.1

        console.log(' ===== Vector Layer extentsArray 8 ===== ', extentsArray)

        this.map.getView().fit(extentsArray, {size: this.map.getSize(), maxZoom:19});

        console.log(' ===== Vector Layer here 9 ===== ', this.map)


      }


      // const popUpElement = document.getElementById('popup');
      // const popUpContent = document.getElementById('popup-content');
      // const popUpCloser = document.getElementById('popup-closer');

      // const popup = new Overlay({
      //   element: popUpElement,
      //   positioning: 'bottom-center',
      //   stopEvent: true,
      //   autoPan: true,
      //   autoPanAnimation: {
      //     duration: 250,
      //   },

      // });
      // this.map.addOverlay(popup);

      this.isLoading = false

      //   this.map.on("pointermove", function (evt) {
      //     var hit = this.map.hasFeatureAtPixel(evt.pixel);
      //     console.log('hit', hit)
      // });

      // this.map.on("pointermove", function (evt) {
      //   var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
      //     return true;
      //   });
      //   if (hit) {
      //     this.getTargetElement().style.cursor = 'pointer';
      //   } else {
      //     this.getTargetElement().style.cursor = '';
      //   }
      // });

      // Add on click event on map, only act on clicks where we have markers else, do nothing
    }

    const that = this

    this.map.on('click', function (evt) {

      // console.log('clicked icon ................................')




      if (that.map.getView().getZoom()  !== undefined){
        // Save the current zoom level, Extent before changing the image
        that.currentUIState.currentMapZoomLevel  = that.map.getView().getZoom();
        const currentMapExtent = that.map.getView().calculateExtent(that.map.getSize())
        const box = transformExtent(currentMapExtent,'EPSG:3857','EPSG:4326'); // we need to convert the extent back to the projection so that we get the lat,lng values instead of pixels
        that.currentUIState.currentMapViewFrame = box
      }



      const feature = that.map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
          return feat;
        }
      );
      if (feature && feature.get('type') == 'leadsToImageGallery') {
        console.log('it was clicked on leadsToImageGallery')
        console.log(feature)


          if (feature.get('associatedData').cam_id == 'abaut_P1010' || feature.get('associatedData').cam_id == 'abaut_P1020'){
            that.userInfoService.currentlySelectedMarker = feature.get('associatedData').image_filename_original
            that.userInfoService.currentlySelectedMarkerLat = feature.get('associatedData').lat
            that.userInfoService.currentlySelectedMarkerLon = feature.get('associatedData').lon
            that.userInfoService.currentlySelectedImageMarker = feature.get('associatedData')

          }

          else{
            that.userInfoService.currentlySelectedMarker = feature.get('associatedData').cam_id
            that.userInfoService.currentlySelectedMarkerLat = feature.get('associatedData').lat
            that.userInfoService.currentlySelectedMarkerLon = feature.get('associatedData').lon

          }


        // Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
        that.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        that.router.onSameUrlNavigation = 'reload';
        that.router.navigate(['imagegallery'], { queryParams: {site_id: that.userInfoService.siteId , camera_id: feature.get('associatedData').cam_id } });
      } else if(feature && feature.get('type') == 'machineIcon') {
        console.log('================Machine Icon Clicked================', feature.get('associatedData').id)
        console.log(feature)
        that.userInfoService.currentlySelectedMarker    = feature.get('associatedData').id
        that.userInfoService.currentlySelectedMarkerLat = feature.get('associatedData').lat
        that.userInfoService.currentlySelectedMarkerLon = feature.get('associatedData').lon
      }
      else if (feature && feature.get('type') == 'leadsToSiteView') {
        console.log('it was clicked on leadsToSiteView')
        console.log(feature)

        that.storeCurrentStateEvent.emit(feature.get('associatedData'));


        that.userInfoService.currentlySelectedMarker    = feature.get('associatedData').position_id
        that.userInfoService.currentlySelectedMarkerLat = feature.get('associatedData').lat
        that.userInfoService.currentlySelectedMarkerLon = feature.get('associatedData').lon

        // let data_AllDays_CurrentPositionId = that.data_AllDays_AllPositionId[feature.get('associatedData')['position_id']]
        // Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
        that.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        that.router.onSameUrlNavigation = 'reload';
        that.router.navigate(['siteview'], { queryParams: {id: feature.get('associatedData').position_id, site_id: that.userInfoService.siteId} ,  state: { position_id_: feature.get('associatedData').position_id } });
        // this.router.navigate(['my-route', 37], { queryParams: { username: "jimmy"}});

      }
      else {
        // console.log('it was not clicked on point')
        // console.log(feature)
        // let lonlat  = olProj.toLonLat(evt.coordinate);
        // console.log("🚀 ~ file: map-viewer.component.ts ~ line 565 ~ MapViewerComponent ~ lonlat", lonlat)
        // console.log("latitude : " + lonlat[1] + ", longitude : " + lonlat[0]);

      }
      // this.updateSizeAfterData()
    });

    this.map.on('pointermove', function (evt) {

      // // Disabling popup menu in mini map for now as it disturbs user expeirience and makes it hard to click
      // if (that.inMiniMode) {
      //   return;
      // }

      if (evt.dragging) {
        return;
      }
      const feature = that.map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
          return feat;
        }
      );
      if (!evt.dragging) {
        that.map.getTargetElement().style.cursor = that.map.hasFeatureAtPixel(that.map.getEventPixel(evt.originalEvent)) ? 'pointer' : 'grab';
      }
      // if (!that.inMiniMode && (feature && (feature.get('type') == 'leadsToImageGallery' || feature.get('type') == 'leadsToSiteView'))) {

      //   // const element = popup.getElement();
      //   // popup.setPosition(evt.coordinate);
      //   // popup.setMap(that.map);
      //   // let HTML = "<table border=1><tr>";
      //   // HTML += "<td align=center>Camera ID:</td>" + "<td align=center>" + feature.get('associatedData')['cam_id'] + "</td>";
      //   // HTML += "</tr><tr>";
      //   // HTML += "<td align=center>Camera Type:</td>" + "<td align=center>" + feature.get('associatedData')['cam_type'] + "</td>";
      //   // HTML += "</tr><tr>";
      //   // HTML += "<td align=center>Postion ID:</td>" + "<td align=center>" + feature.get('associatedData')['position_id'] + "</td>";
      //   // HTML += "</tr><tr>";
      //   // HTML += "<td align=center>Lat:</td>" + "<td align=center>" + feature.get('associatedData')['lat'] + "</td>";
      //   // HTML += "</tr><tr>";
      //   // HTML += "<td align=center>Lon:</td>" + "<td align=center>" + feature.get('associatedData')['lon'] + "</td>";
      //   // HTML += "</tr><tr>";
      //   // HTML += "</tr></table>";
      //   // popUpContent.innerHTML = HTML;
      //   isOpen = true;

      // }
      // if ((!that.map.hasFeatureAtPixel(that.map.getEventPixel(evt.originalEvent))) && isOpen) {
      //   isOpen = false;
      //   // console.log('is NOT feature')
      //   popup.setMap(null);
      // }
    });

    this.map.on("pointermove", function (evt) {
      const feature = that.map.forEachFeatureAtPixel(evt.pixel, function (feat, layer) {
          return feat;
        }
      );



      if(feature && (feature.get('type') == 'machineIcon' || feature.get('type') == 'noRedirection') ) {
        // console.log('=========================here====================')
        this.getViewport().style.cursor = 'grab'
      } else if (feature) {
        this.getViewport().style.cursor = 'pointer';
      } else {
        this.getViewport().style.cursor = '';
      }
    });


    // This Displays Machine Cameras
    this.dataService.getMachinePositions(this.userInfoService.siteId).subscribe(machines => {
        // console.log('-----------got machine positions from service----------------------', machines)
        const machinePos = machines.machines
        const machineFeatures : any[] = []

        if(machinePos !== undefined && machinePos !== null && machinePos.length !== 0) {
          this.selectedIconIndex = (this.currentIconIndex * 2) + machinePos.length
          machinePos.map((machinePosition) => {
            if(machinePosition.type !== null) {
              const iconFeature = this.createMachineIconFeature(machinePosition);
              machineFeatures.push(iconFeature);
            }
          })
        }

        const machineVectorSource = new VectorSource({ features: machineFeatures });
        this.machineVectorLayer = new Vector({
          source: machineVectorSource,
          zIndex: this.MAP_LAYERS_INDICES.cameras,
          // declutter: true,
        });

        this.map.addLayer(this.machineVectorLayer)

      if(this.mapCenterLat === 0) {
        if ((this.url.indexOf('siteview') <= 0) && (this.url.indexOf('imageplan') <= 0) && (this.url.indexOf('imagegallery') <= 0)) {

          // Add some padding otherwise the Markers reach the edge of screen.
          const extentsArray = machineVectorSource.getExtent()
          // console.log(extentsArray)
          const extentWidth = extentsArray[2] -  extentsArray[0]
          const extentHeight = extentsArray[3] -  extentsArray[1]


          // We add some additional padding around the markers, based on the differnce in extent x and y values.
          extentsArray[0] = extentsArray[0]  - extentWidth * 0.1
          extentsArray[1] = extentsArray[1]  - extentHeight * 0.1
          extentsArray[2] = extentsArray[2]  + extentWidth * 0.1
          extentsArray[3] = extentsArray[3]  + extentHeight * 0.1
          this.map.getView().fit(extentsArray, {size: this.map.getSize(), maxZoom:19});
        }
      }

      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      })

  }

  toggleSelectedToCam() {
    this.isSelectedFromCam = true;
    this.isSelectedFromMachineCam = false;
  }

  toggleSelectedToMachineCam() {
    this.isSelectedFromCam = false;
    this.isSelectedFromMachineCam = true;
  }

  routeToPlanComponent(planType)
  {
       // let data_AllDays_CurrentPositionId = that.data_AllDays_AllPositionId[feature.get('associatedData')['position_id']]
        // Extra stuff needed, as ngoninit is not triggered if we re-route to the same URL.
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['imageplan'], { queryParams: {planType} });
  }



  miniMapResizeButtonClicked() {
    // console.log('Mini Map Resize Button was called')

    if (this.currentUIState.splitState$.value == true)
    {
      this.currentUIState.splitState$.next(false);
    }
    else{
      // Important to set the state first before emitting
      this.currentUIState.splitState$.next(true);
    }
    this.shrink.emit(null)

    this.olMapUpdateSize()
  };

  resizeMapinChildComponents(){
    if (this.imagePlan1ComponentChild) {
      this.imagePlan1ComponentChild.olMapImageViewerUpdateSize()
    }
    if (this.ImageGalleryComponentChild) {
      this.ImageGalleryComponentChild.olMapUpdateSize()
    }

  }

  changetoSatelliteLayer() {

    // console.log('changetoSatelliteLayer Method called')
    this.currentUIState.currentMapLayer = 'satellite_plan'
    this.mapDiv.nativeElement.classList.remove('hide')
    this.mapDiv.nativeElement.classList.add('show')
    this.ShowDiv('A');
    // Important: This will re-draw the map to adjust the size
    if (this.map !== undefined) {
      this.map.updateSize();
    }
  }

  ShowDiv(divVal: string) {
    this.currDiv = divVal;
    this.ref.detectChanges();
    // Important: This will re-draw the map to adjust the size
    if (this.map !== undefined) {
      this.map.updateSize();
    }
  }


  changetoPlanComponent(planTypeParam) {
    // console.log("changetoPlanComponent is called")
    // console.log(planTypeParam)
    this.planType = planTypeParam

    // Improve this code!!! it shoudnt  hide and show layers in future.
    this.currentUIState.currentMapLayer = planTypeParam
    this.mapDiv.nativeElement.classList.remove('hide')
    this.mapDiv.nativeElement.classList.add('show')
    this.ShowDiv('A');
    // Important: This will re-draw the map to adjust the size
    if (this.map !== undefined) {
      this.map.updateSize();
    }
    this.currentUIState.currentMapLayer = planTypeParam
    this.mapDiv.nativeElement.classList.remove('show')
    this.mapDiv.nativeElement.classList.add('hide')
    this.ShowDiv('B');
    this.resizeMapinChildComponents()


  }

    // This can be called by other components too to adjust map size, keep in mind this is only responsible for the sattellite map
    olMapUpdateSize() {
      // In case of having a imagePlan Opened in the MAP div this function call is imp, as it will trigger the map resize call in that imagePlan
      this.resizeMapinChildComponents()
      if (this.map !== undefined) {
        this.map.updateSize();
      }
    }

    toggleLagePlanLayer(status:string) {
      // console.log('----event to toggle lageplan layer------', status)
      if(status === 'show') {
        this.lagePlanLayer.setVisible(true);
      } else {
        this.lagePlanLayer.setVisible(false);
      }
    }

    toggleDroneLayer(status:string) {
      // console.log('----event to toggle drone layer------', status)
      if(status === 'show') {
        this.dronePlanLayer.setVisible(true);
      } else {
        this.dronePlanLayer.setVisible(false);
      }
    }

    filterCamera(tags){
      try {
        //get all Cameras from the map
        let cameras = this.vectorLayer.getSource().getFeatures()

        cameras.forEach(camera => {
          if (camera["values_"]["filter_tag"] == undefined){
            camera.setStyle(camera["values_"]["associatedData"]["style"]); //always show the camera that dosent have filter_tag
          }else if (tags.includes(camera["values_"]["filter_tag"])){
            //show Style  / Show Camera
            camera.setStyle(camera["values_"]["associatedData"]["style"]);
          }else{
            //Hide Style / Hide Camera
            camera.setStyle(new Style({}));
          }
        });

      } catch (error) {
        console.error(error);
      }
    }

    toggleMachineLayer(hide: boolean) {
        this.machineVectorLayer.setVisible(!hide)
    }

    createMachineIconFeature(machinePosition){
      //Dummy Icons Generation//
      // Create Feature... with coordinates
      // leadsToImageGallery

      const anchorX = 0.5
      const anchorY = 1
      let iconZindex = ++this.currentIconIndex

      let clickType = 'machineIcon'

      let icon = ''

      const machineType = machinePosition.camera_type === null ? machinePosition.filter_tag : machinePosition.filter_tag.replace('+cam', '')
      const machineName = machinePosition.machine_bezeichnung_kurz.length > 16 ? machinePosition.machine_bezeichnung_kurz.substring(0,16) + '..'
        : machinePosition.machine_bezeichnung_kurz
      // console.log('-----------------------machine icon 1----------------------', machinePosition, machineType)
      // console.log('-----------------------machine params ----------------------', this.userInfoService.camParams)
      // const machineType = machinePosition.filter_tag

      const camStr = machinePosition.camera_type === null || machinePosition.camera_type === undefined ? '' : '_camera'

      // console.log(' --- type check ---', machinePosition.camera_type === null, camStr, machinePosition.camera_type)
      //
      console.log(' --- MACHINE --- ',machinePosition.filter_tag, machinePosition)

      if (machinePosition.cam_id === this.userInfoService.currentlySelectedMarker) {
        icon = './assets/SVG_Machine_Icons/' + machineType + '/' + machineType + camStr + '_red.svg'
        iconZindex = this.selectedIconIndex
        this.toggleSelectedToMachineCam()
      } else {
        icon = './assets/SVG_Machine_Icons/' + machineType + '/' + machineType + camStr + '.svg'
      }

      if(machinePosition.camera_type !== null && machinePosition.camera_type !== undefined) {
        if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'nothing') {
          icon = ''
          clickType = 'noRedirection'
        } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_without_cam') {
          clickType = 'noRedirection'
        } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'icon_with_cam') {
          clickType = 'noRedirection'
        } else if(this.userInfoService.camParams.SiteView_ShowMobiCamImage === 'large_image') {
          clickType = 'leadsToImageGallery'
        }
      }

      // console.log('-----------------------machine icon 2----------------------', machineType, machinePosition.id, machinePosition.cam_id, icon, iconZindex)

      // console.log("*************************************key********************************************")
      // console.log(dummyItemObj)
      // console.log("*************************************key********************************************")


      const iconFeature = new Feature({
        geometry      : new Point(olProj.transform([machinePosition.lon, machinePosition.lat], 'EPSG:4326', 'EPSG:3857')),
        position_id   : machinePosition.id,
        type          : clickType,
        associatedData: machinePosition,
        filter_tag    : machinePosition.filter_tag,
      });

      const imageStyle = new Style({
        image: new Icon(({
          anchor: [anchorX, anchorY],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          opacity     : 1,
          src         : icon,
          scale       : 0.5
        })),
        zIndex: iconZindex,
      })

      const textStyle = new Style({
        text: new Text({
          text: machineName,
          font: 'bold 15px Roboto',
          fill: new Fill({
            color:'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
          textBaseline: 'bottom',
          offsetY: -77,
        }),
        zIndex: iconZindex,
      })

      // Create style for your feature...
      const iconStyle = [imageStyle, textStyle]
      iconFeature["values_"]["associatedData"]["style"] = iconStyle        //store the style
      iconFeature.setStyle(iconStyle);

      return iconFeature;
    }
}

