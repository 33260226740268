import { Component, EventEmitter, Input, Output } from '@angular/core';
import { now } from 'moment';
import { UserInfoService } from '../_services/user-info.service';
import {Subject} from "rxjs";

@Component({
  selector: 'app-date-calendar',
  templateUrl: './date-calendar.component.html',
  styleUrls: ['./date-calendar.component.scss']
})


// Calendar component, should highlight a list of dates and onclick a date should emit the selected date.
export class DateCalendarComponent{

  @Input() listOfDatesToHighlight: any = []
  @Output() selectedCurrentCalenderDate = new EventEmitter<string>();
  @Input() timeStamp: Subject<string>;

  currentSelectedDate: Date = new Date(now());
  receivedTimeStamp: string = ''
  downloadImage
  HTTPActivity: boolean;
  currentSelectedDateOLD: any;
  // date
  constructor( public userInfoService : UserInfoService) {
    this.currentSelectedDate = new Date(now());
    // this.httpStatus.getHttpStatus().subscribe(status => { this.HTTPActivity = status; });
  }

  ngOnInit(){
    this.timeStamp.subscribe(v => {
      console.log('========= timestamp is changing =========>', v);
      this.receivedTimeStamp = v
    });
  }

  // Will be called whenever the parent component propagates the new value to child, which in our case is when a differnt camera is selected and we get a new listOfDatesToHighlight
  ngOnChanges() {

        // If the format is always dd/mm/yyyy, then this is trivial:
    // '2021_11_09

    // console.log("This was the list of dates")
    // console.log(this.listOfDatesToHighlight)

    if (this.listOfDatesToHighlight == undefined || this.listOfDatesToHighlight.length == 0) {
      // console.log("Empty List: ListOfImagesForADay ")
      return
    }


      //If we have already saved the currentlySelectedDateInImageGallery we try to show that as the default selected that on page load
      //This is done so that the user see's the same
      if (this.userInfoService.currentlySelectedDateInImageGallery !== "" && this.userInfoService.currentlySelectedDateInImageGallery !== undefined){
        // console.log("FOUND A SAVED DATE")
        // console.log(this.userInfoService.currentlySelectedDateInImageGallery)

        //Case 1a : We find the saved currently selected date in the list of dates for the current camera
        if (this.listOfDatesToHighlight.indexOf(this.userInfoService.currentlySelectedDateInImageGallery) > -1){
          // console.log("case 1a ")
          this.currentSelectedDateOLD = this.userInfoService.currentlySelectedDateInImageGallery;
          // console.log(this.userInfoService.currentlySelectedDateInImageGallery)
          // console.log(this.currentSelectedDateOLD )
        }
        //Case 1b : We Could Not find the saved currently selected date in the list of dates for the current camera, we try to search for the nearest

        else{
          // console.log("case 1b ")
          let myvalue = this.findClosestDate(this.userInfoService.currentlySelectedDateInImageGallery)
          // console.log(myvalue)
          this.currentSelectedDateOLD = myvalue
        }

      } else if(this.receivedTimeStamp !== undefined && this.receivedTimeStamp !== null && this.receivedTimeStamp !== "") {

        let dateFormat = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];

        let timeStampDate = new Date(Number(this.receivedTimeStamp) * 1000);

        let timeStampFormatted = this.join(timeStampDate, dateFormat, '_');

        console.log('===== Initialize date to this date =====> ', timeStampDate, timeStampFormatted)

        this.currentSelectedDateOLD = timeStampFormatted
      }
      //Case 1c : No date was saved as currently selected date , we use the 0th date
      else{
        // console.log("case 1c ")
        // console.log("COULD NOT FIND A SAVED DATE")
        // console.log(this.userInfoService.currentlySelectedDateInImageGallery)
        this.currentSelectedDateOLD = this.listOfDatesToHighlight[0]

      }

      // We need to format the string value of the date into a proper Date object so the calendar can accept it
      var parts = this.currentSelectedDateOLD.split("_");
      var dt = new Date(parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10));


      // var timestamp = Date.parse(dt);
      var dateObject = new Date(dt);
      this.currentSelectedDate = dateObject

    this.handleDateChange(this.currentSelectedDate)
  }

  join(t, a, s) {
    let locale = this.userInfoService.currentLanguageLocale
    function format(m) {
      let f = new Intl.DateTimeFormat(locale, m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }


  // Could be made more efficient
  findClosestDate(v) {


    // Date format is sth like this 2022_01_20 , One replace call only replaces the first instant apparently so we chain two together
    v = v.replace("_","").replace("_","")
    let customListOfDates = []

    this.listOfDatesToHighlight.forEach(element => {
      let customElement = element.replace("_","").replace("_","")
      customListOfDates.push(customElement)
    });

    // console.log("THIS IS MY CUSTOM LIST")
    // console.log(customListOfDates)
    // console.log("This is the input value")
    // console.log(v)

    var value,
        lastDelta;

        customListOfDates.some(function (a) {
        var delta = Math.abs(v - a);
        if (delta >= lastDelta) {
            return true;
        }
        value = a;
        lastDelta = delta;
    });

    // We had removed the underscores from the date, changing them back to the same value
    value = value.slice(0, 4) + "_" + value.slice(4,6)  + "_" + value.slice(6,8);
    return value;
}





  // Apply CSS class on the calendar dates based on their value.
  dateClass = (d: Date) => {
    let special_date = false;
    for (var i = 0, len = this.listOfDatesToHighlight.length; i < len; i++) {
      let selected_date = d.getDate()
      let selected_month = (d.getMonth() + 1).toString().padStart(2, "0")
      let selected_year = d.getFullYear()
      let iteration_date = this.listOfDatesToHighlight[i].substring(8, 10)
      let iteration_month = this.listOfDatesToHighlight[i].substring(5, 7)
      let iteration_year = this.listOfDatesToHighlight[i].substring(0, 4)


      if (selected_date == iteration_date && selected_month == iteration_month && selected_year == iteration_year) {
        special_date = true
          break;
        }
      }
          return (special_date === true) ? 'calendar-custom-date-class' : 'calendar-custom-date-class_two';

  }



  handleDateChange(event) {
    // console.log("🚀 ~ file: date-calendar.component.ts ~ line 156 ~ DateCalendarComponent ~ handleDateChange ~ event", event)
    // console.log("handleDateChange Event Triggered")

    try {

      console.log('Date Changed ====>', event)

      let dateString = event
      let dateString_iso = new Date(dateString)
      // console.log(dateString_iso)

      dateString_iso.setDate(dateString_iso.getDate() + 1);  // JS month incremenet by 1
      let dateString_iso_substring = dateString_iso.toISOString().substring(0, 10);

      this.currentSelectedDate = new Date(dateString_iso_substring)
      this.selectedCurrentCalenderDate.emit(dateString_iso_substring)

    } catch (error) {
      console.log("Some Exception occured after the date was changed.")
      return
    }

  }


}
