<section class="container_main" style="background-color: #fafbfd;">
  <div id="filters">
    <h4>Select a time interval</h4>
    <mat-form-field appearance="fill">
      <mat-label>Select an Interval</mat-label>
      <mat-select (selectionChange)="changeClient($event.value)">
        <mat-option *ngFor="let dt of dates_selection" [value]="dt.value">
          {{dt.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="one" style="background-color: #fafbfd;">
    <div id="Second" class="row">
      <iframe scrolling="no" width="700" height="500" frameBorder="3px" [src]="urlSafe"
        style="border-top-width: 0px;border-top-style: solid;border-right-width: 0px;border-right-style: solid;border-bottom-width: 0px;border-bottom-style: solid;border-left-width: 0px;border-left-style: solid;"></iframe>
    </div>
  </div>
  <div class="two" style="background-color: #fafbfd;">
    <div id="Second" class="row">
      <iframe scrolling="no" width="700" height="500" frameBorder="3px" [src]="urlSafe2"
        style="border-top-width: 0px;border-top-style: solid;border-right-width: 0px;border-right-style: solid;border-bottom-width: 0px;border-bottom-style: solid;border-left-width: 0px;border-left-style: solid;"></iframe>
    </div>
    <!-- <iframe src="https://analytics.abaut.de/s/at0004_omya_gummern/app/kibana#/visualize/edit/ee6517d0-3894-11eb-9c9a-659a2b5b8708?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))" height="600" width="800"></iframe> -->
    <!-- <iframe src="https://analytics.abaut.de/s/at0004_omya_gummern/app/kibana#/dashboard/a852fef0-1c47-11ec-81a6-4719156b55a1?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cto%3Anow))" height="600" width="800"></iframe> -->
  </div>







</section>



  
<h2>Sample Geo server Map</h2>

<div id="ol-map" class="map-container-large">
</div>
